import { ArrowLeftOutlined } from "@ant-design/icons";
import { Drawer, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import PopupConfirm from "../common/PopupConfirm";
import { useDispatch } from "react-redux";
import InputValidation from "../common/InputValidation";
import editIcn from '../../../assets/images/settings/edit.svg';
import { getNavMenuItems, getNavMenusById, onCreateMenuItem, onDeleteMenuItems, onOrderMenuItems, onUpdateMenuItems } from "../../../actions/navigationAction";
import SaveMenu from "./SaveMenu";
import { SortableTree } from "./SortableTree";
import { getCollections } from "../../../actions/collectionActions";
import { getProducts } from "../../../actions/productActions";
import { getBlogs, getPages } from "../../../actions/blogActions";
import UploadIcon from "../../../assets/images/gallery-add.svg";
import { MediaAssets } from "../common/mediaAssets";
import { uuidv4 } from "../../../helpers/util";
import { onUploadMedia } from "../../../actions/commonAction";

const { Option } = Select;

const LinkOptions = [
    {
        label: 'Home',
        value: '/',
    },
    {
        label: 'Url',
        value: 'url'
    },
    {
        label: 'Product',
        value: 'product'
    },
    {
        label: 'Collection',
        value: 'collection',
    },
    {
        label: 'Page',
        value: 'page',
    },
    {
        label: 'Blog',
        value: 'blog',
    },
    {
        label: 'Do Nothing',
        value: '#',
    }
];

export const Save = (props: any) => {
    const { menuId, isVisible } = props;
    let errorInfo = {
        title: {
            type: null,
            message: null
        },
        slug: {
            type: null,
            message: null
        },
        type: {
            type: null,
            message: null
        },
        name: {
            type: null,
            message: null
        },
        link: {
            type: null,
            message: null
        }
    };
    const dispatch = useDispatch();
    const WindowHeight = window.innerHeight;
    const [icons, setIcons] = useState([]);
    const [selectedLink, setSelectedLink] = useState('');
    const [selectedLinkOpt, setSelectedLinkOpt] = useState(null);
    const [url, setUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [linkDDOptions, setLinkDDOptions] = useState(null);
    const [menu, setMenu] = useState(null);
    const [menuItems, setMenuItems] = useState([]);
    const [treeItems, setTreeItems] = useState(null);
    const [selectedMenuItem, setSelectedMenuItem] = useState(null);
    const [error, setError] = useState(errorInfo);
    const [editMenu, setEditMenu] = useState(null);
    const [popupConfirm, setPopupConfirm] = useState(null);
    const [title, setTitle] = useState("");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");

    const onClose = () => {
        props.onClose();
    }
    useEffect(() => {
        if (menuId) {
            fetchMenuItems();
        }
        //eslint-disable-next-line
    }, [menuId]);

    const loadLinkType = async () => {
        setSelectedLinkOpt(null);
        setLinkDDOptions(null);
        errorInfo.type.type = null;
        errorInfo.type.message = null;
        errorInfo.link.type = null;
        errorInfo.link.message = null;
        setError(errorInfo);
        if (selectedMenuItem && selectedMenuItem.id) {
            const item = menuItems.find((i) => i.id === selectedMenuItem.id);
            if (item.linkType !== 'url' && (item.link !== '/' && item.link !== '#')) {
                setSelectedLinkOpt(item.link);
            }
        }
        if (selectedLink === "collection") {
            const resp = await getCollections()(dispatch);
            if (resp && resp.collections) {
                setLinkDDOptions(resp.collections);
            }
        } else if (selectedLink === "product") {
            const resp = await getProducts()(dispatch);
            if (resp) {
                setLinkDDOptions(resp);
            }
        } else if (selectedLink === "page") {
            const resp = await getPages()(dispatch);
            if (resp && resp.posts) {
                setLinkDDOptions(resp.posts);
            }
        } else if (selectedLink === "blog") {
            const resp = await getBlogs()(dispatch);
            if (resp && resp.blogs) {
                setLinkDDOptions(resp.blogs);
            }
        }
    }

    useEffect(() => {
        if (selectedLink) {
            loadLinkType();
        }
        //eslint-disable-next-line
    }, [selectedLink]);
    const fetchMenuItems = async () => {
        const resp = await getNavMenusById(menuId)(dispatch);
        if (!resp.error) {
            setMenu(resp);
        }
    }
    useEffect(() => {
        if (menu) {
            setTitle(menu.title);
            loadNavMenuItems(menu.id);
        }
        //eslint-disable-next-line
    }, [menu]);

    const loadNavMenuItems = async (mId) => {
        const resp = await getNavMenuItems(mId)(dispatch);
        if (resp?.items) {
            setMenuItems(resp.items);
        }
    }

    useEffect(() => {
        setTreeItems(convertTreeFormat(menuItems));
    }, [menuItems]);

    // const onValidate = (e) => {
    //     e.preventDefault();
    //     let isError = false;
    //     if (!title || title.length === 0) {
    //         errorInfo.title.type = "error";
    //         errorInfo.title.message = "Title is required";
    //         isError = true;
    //     } else {
    //         errorInfo.title.type = null;
    //         errorInfo.title.message = null;
    //     }
    //     setError(errorInfo);
    // }

    const convertMenuFormat = (items) => {
        let convertedFormat = {
            items: []
        };
        const buildItems = (node, parentId) => {
            node.forEach((item) => {
                const menuItem = menuItems.find((i) => i.id === item.id);
                let newItem = {
                    id: menuItem.id
                };
                if (parentId) {
                    newItem['parentId'] = parentId;
                }
                convertedFormat.items.push(newItem);
                if (item.children.length > 0) {
                    buildItems(item.children, newItem.id);
                }
            });
        }
        buildItems(items, null);
        return convertedFormat;
    }

    const convertTreeFormat = (items) => {
        let convertedFormat = [];
        let groupedItems = {};
        items.forEach(item => {
            if (!groupedItems[item.parentId]) {
                groupedItems[item.parentId] = [];
            }
            groupedItems[item.parentId].push(item);
        });
        const buildTree = (parentId) => {
            let tree = [];
            if (groupedItems[parentId]) {
                groupedItems[parentId].forEach(item => {
                    let node = {
                        id: item.id,
                        title: item.title,
                        children: buildTree(item.id)
                    };
                    tree.push(node);
                });
            }
            return tree;
        }
        items.forEach(item => {
            if (!item.parentId) {
                convertedFormat.push({
                    id: item.id,
                    title: item.title,
                    children: buildTree(item.id)
                });
            }
        });
        return convertedFormat;
    }

    const onSortMenuItems = async (items) => {
        const isChanged = JSON.stringify(items) !== JSON.stringify(treeItems);
        if (isChanged) {
            setIsLoading(true);
            const data = convertMenuFormat(items);
            const resp = await onOrderMenuItems({ items: data.items }, menuId)(dispatch);
            if (!resp || (resp && resp.error)) {
                setTreeItems(convertTreeFormat(menuItems));
            } else {
                setTreeItems(items);
            }
            setIsLoading(false);
        }
    }

    const onSaveMenuItems = async () => {
        let isError = false;
        errorInfo.name.type = null;
        errorInfo.name.message = null;
        errorInfo.type.type = null;
        errorInfo.type.message = null;
        errorInfo.link.type = null;
        errorInfo.link.message = null;
        let link = null;
        let linkType = null;
        if (!name) {
            errorInfo.name.type = "error";
            errorInfo.name.message = "Name is required";
            isError = true;
        }
        if (selectedLink === '' || selectedLink === null) {
            errorInfo.type.type = "error";
            errorInfo.type.message = "Link is required";
            isError = true;
        }
        if (selectedLink === 'url' && !url) {
            errorInfo.link.type = "error";
            errorInfo.link.message = "Link is required";
            isError = true;
        }
        if ((selectedLink === 'collection' || selectedLink === 'page' || selectedLink === 'blog' || selectedLink === 'product') && !selectedLinkOpt) {
            linkType = selectedLink;
            errorInfo.link.type = "error";
            errorInfo.link.message = "Link is required";
            isError = true;
        }
        setError(errorInfo);
        if (selectedLink === 'url') {
            link = url;
            linkType = 'url';
        }
        if (selectedLink === '/' || selectedLink === '#') {
            link = selectedLink;
            linkType = 'url';
        }
        if ((selectedLink === 'collection' || selectedLink === 'page' || selectedLink === 'blog' || selectedLink === 'product') && selectedLinkOpt) {
            link = selectedLinkOpt;
            linkType = selectedLink;
        }
        if (isError) {
            return;
        }
        let payload = {
            title: name,
            linkType,
            icon: icons.length > 0 ? icons[0] : '',
            link,
            description
        }
        if (selectedMenuItem.position !== undefined) {
            payload['position'] = selectedMenuItem.position;
        }
        if (selectedMenuItem.parentId) {
            payload['parentId'] = selectedMenuItem.parentId;
        }
        if (errorInfo.name.type === null) {
            let resp = null;
            if (selectedMenuItem?.id) {
                resp = await onUpdateMenuItems(payload, menuId, selectedMenuItem.id)(dispatch);
            } else {
                resp = await onCreateMenuItem(payload, menuId)(dispatch);
            }
            if (resp?.menuItemId || resp?.isUpdated) {
                loadNavMenuItems(menu.id);
                // setMenuItems([...menuItems, { title: name, id: resp.menuItemId, parentId: selectedMenuItem.parentId, description }]);
                onCloseMenuItemModal();
            } else if (resp?.isUpdated) {
                // setMenuItems([...menuItems, { title: name, id: selectedMenuItem.id, parentId: selectedMenuItem.parentId, description }]);
                onCloseMenuItemModal();
            }
        }
    }

    const onCloseMenuItemModal = () => {
        setDescription("");
        setSelectedLink("");
        setSelectedLinkOpt(null);
        // setTreeItems(null);
        // setMenuItems([]);
        setUrl(null);
        setSelectedMenuItem(null);
    }

    const onDeleteMenuItem = (id) => {
        const item = menuItems.find((i) => i.id === id);
        setPopupConfirm({
            title: "Delete Menu Item",
            renderContent: () => { return <div><span style={{ color: "#000" }}>Do you like to delete <span style={{ fontWeight: "bold" }}>{item.title}</span> menu item?</span></div > },
            yes: {
                label: "Delete",
                callback: async () => {
                    setPopupConfirm(null);
                    const resp = await onDeleteMenuItems(menuId, item.id)(dispatch);
                    if (resp && resp.isDeleted) {
                        setMenuItems(menuItems.filter((i) => i.id !== item.id));

                    }
                }
            },
            no: {
                label: "Cancel",
                callback: () => { setPopupConfirm(null); }
            }
        });
    }

    useEffect(() => {
        if (selectedMenuItem) {
            if (selectedMenuItem.title) {
                setName(selectedMenuItem.title);
            }
            if (selectedMenuItem.description) {
                setDescription(selectedMenuItem.description);
            }
        }
    }, [selectedMenuItem]);

    const onEditMenuItem = (id) => {
        const item = menuItems.find((i) => i.id === id);
        setName(item.title);
        setDescription(item.description);
        if (item.linkType === 'url' && (item.link !== '/' && item.link !== '#')) {
            setSelectedLink('url');
            setUrl(item.link);
        }
        if (item.linkType === 'collection' || item.linkType === 'product' || item.linkType === 'page' || item.linkType === 'blog') {
            setSelectedLink(item.linkType);
            setSelectedLinkOpt(item.link);
        }
        if (item.linkType === 'url' && (item.link === '/' || item.link === '#')) {
            setSelectedLink(item.link);
            setSelectedLinkOpt(item.link);
        }
        if (item.icon) {
            setIcons([item.icon]);
        } else {
            setIcons([]);
        }
        setSelectedMenuItem({ id: item.id, parentId: item.parentId, title: item.title, position: item.position });
    }

    const onAddMenuItem = (id) => {
        const item = menuItems.find((i) => i.id === id);
        setSelectedMenuItem({ parentId: item.id, id: null, parentTitle: item.title });
    }
    const loadIcons = () => {
        let imageList = [];
        for (let url of icons) {
            imageList.push({
                url,
                id: uuidv4()
            })
        }
        return imageList;
    }
    const onUploadFiles = (fileList, type, disableImageTransform) => {
        const uploadPromises = fileList.map(async (item) => {
            const formData = new FormData();
            formData.append('files', item.originFileObj);
            formData.append('forUse', 'mediaFiles');
            if (!disableImageTransform) {
                formData.append('imageTransform', 'w:500');
            }
            const resp = await onUploadMedia(formData)(dispatch);
            return resp;
        });
        Promise.all(uploadPromises)
            .then((resp: any) => {
                let urls = [];
                if (resp && resp.length > 0) {
                    urls = resp.map((r) => {
                        return r.files && r.files.length > 0 && r.files[0]
                    });
                    if (type === 'icons') {
                        setIcons([...icons, ...urls]);
                    }
                }
            })
            .catch(error => {
                // Handle errors if any
                console.error("Error uploading files:", error);
                return { error: true }
            });
    }
    return <Drawer
        closable={false}
        onClose={onClose}
        width={"100%"}
        className="menu-save"
        visible={isVisible}
        footer={<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div className="sp_btn" onClick={onClose} style={{ boxShadow: 'none', width: 120, fontSize: 14, marginRight: 10, border: '1px solid #121212', color: "#000", background: "#fff" }}>Close</div>
            {/* {!isEdit && <div className="sp_btn" onClick={() => { setIsEdit(true) }} style={{ width: 120, fontSize: 14 }}>Edit</div>} */}
            {/* {isEdit && <div className="sp_btn" onClick={onValidate} style={{ width: 120, fontSize: 14 }}>{menu && menu.id ? "Update" : "Add"}</div>} */}

        </div>}
    >
        <div className="header">
            <ArrowLeftOutlined className="arrow" onClick={onClose}></ArrowLeftOutlined>
            <div className="title">{menu && menu.id ? "Update Menu" : "Add Menu"}</div>
        </div>
        <div className="content" style={{ height: WindowHeight - 120 + "px", overflow: 'auto' }}>
            <div className="detail">
                <div className="title">Menu Items</div>
                <div className="desc">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatu Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur ma</div>
                <div className="menu-title">Title *</div>
                <div className="menu-tile-value">
                    {title}
                    <img alt='Edit Icon' src={editIcn} style={{ cursor: "pointer", marginLeft: 11, width: 16 }} onClick={(e) => { e.stopPropagation(); setEditMenu(menu) }} />
                </div>
                <div className="divider"></div>
                <div className="items">
                    {treeItems && <SortableTree collapsible indicator removable
                        defaultItems={treeItems}
                        onChange={onSortMenuItems}
                        onEdit={(id) => onEditMenuItem(id)}
                        onAdd={(id) => onAddMenuItem(id)}
                        onRemove={(id) => onDeleteMenuItem(id)} />}
                    {isLoading && <div className="overlay"></div>}
                </div>
                <div className="add-menu" onClick={() => setSelectedMenuItem({ id: null })}>+ Add menu</div>
            </div>
        </div>
        {popupConfirm && <PopupConfirm config={popupConfirm}></PopupConfirm>}
        {/* {isLoading && <Loading background="#00000070"></Loading>} */}
        <SaveMenu menu={editMenu} onClose={(data = null) => {
            if (data && data.title) {
                setMenu(data);
                setTitle(data.title);
            }
            setEditMenu(null);
        }} isVisible={editMenu ? true : false} />
        <Modal
            closable={false}
            width={"30%"}
            className="add-menu-modal"
            open={selectedMenuItem ? true : false}
            footer={null}
        >
            <div className="content">
                <div className="header">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ArrowLeftOutlined className="arrow" onClick={onCloseMenuItemModal}></ArrowLeftOutlined>
                        <div className="title">{`${selectedMenuItem?.id ? 'Update ' : selectedMenuItem?.parentTitle ? 'Add & Link ' : 'Add '} Menu Item`}</div>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div className="sp_btn" onClick={onCloseMenuItemModal} style={{ boxShadow: 'none', width: 120, fontSize: 14, marginRight: 10, border: '1px solid #121212', color: "#000", background: "#fff" }}>Cancel</div>
                        <div className="sp_btn" onClick={onSaveMenuItems} style={{ width: 134, fontSize: 14 }}>Submit</div>
                    </div>
                </div>
                <div className="body">
                    {selectedMenuItem?.parentTitle && <div style={{ marginBottom: 20, width: '100%' }}>
                        <div className="label">Parent Name</div>
                        <div>{selectedMenuItem.parentTitle}</div>
                    </div>}
                    <div style={{ marginBottom: 20, width: '100%' }}>
                        <div className="label">Name *</div>
                        <InputValidation type={error.name.type} message={error.name.message}>
                            <div className="input">
                                <input type="text" placeholder="Enter name" value={name} onChange={(e) => setName(e.target.value)}></input>
                            </div>
                        </InputValidation>
                    </div>
                    <div style={{ marginBottom: 20, width: '100%' }}>
                        <div className="label">Description</div>
                        <textarea placeholder="Enter description" rows={4} value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                    </div>
                    <div style={{ marginBottom: 20, width: '100%' }}>
                        <div className="label">Link Type</div>
                        <InputValidation type={error.type.type} message={error.type.message}>
                            <Select
                                showSearch
                                value={selectedLink}
                                filterOption={(inputValue, option) =>
                                    option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                                }
                                onChange={(value) => setSelectedLink(value)}  >
                                <Option value=''>Select</Option>
                                {LinkOptions && LinkOptions.map((b) => {
                                    return <Option key={b.value} value={b.value} style={{ textTransform: 'capitalize' }}>{b.label}</Option>
                                })}
                            </Select>
                        </InputValidation>
                        {selectedLink === 'url' && selectedLinkOpt === null && <div style={{ marginBottom: 20, width: '100%' }}>
                            <InputValidation type={error.link.type} message={error.link.message}>
                                <div className="input">
                                    <input type="text" placeholder="Enter link" value={url} onChange={(e) => setUrl(e.target.value)}></input>
                                </div>
                            </InputValidation>
                        </div>}
                        {(selectedLink === 'collection' || selectedLink === 'page' || selectedLink === 'blog' || selectedLink === 'product') && <div style={{ marginBottom: 20, width: '100%' }}>
                            <InputValidation type={error.link.type} message={error.link.message}>
                                {linkDDOptions && <Select
                                    key={selectedLink}
                                    showSearch
                                    value={selectedLinkOpt}
                                    filterOption={(inputValue, option) =>
                                        option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                                    }
                                    onChange={(value) => setSelectedLinkOpt(value)}  >
                                    {linkDDOptions && linkDDOptions.map((b) => {
                                        return <Option key={b.id} value={b.id} style={{ textTransform: 'capitalize' }}>{b.title}</Option>
                                    })}
                                </Select>}
                            </InputValidation>
                        </div>}
                    </div>
                    <div style={{ marginBottom: 20, width: '100%' }}>
                        <div className="label" style={{ marginBottom: 0 }}>Icon</div>
                        <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                            <MediaAssets isEdit={true} multiple={false} defaultValue={loadIcons()}
                                key="collection-icons"
                                uploadIcon={UploadIcon}
                                onSubmit={async ({ fileList, selectedPreIcon, disableImageTransform }) => {
                                    let urls = [];
                                    if (selectedPreIcon && selectedPreIcon.length > 0) {
                                        urls = selectedPreIcon.map((item) => {
                                            return item.url
                                        });
                                    }
                                    if (fileList && fileList.length > 0) {
                                        onUploadFiles(fileList, "icons", disableImageTransform)
                                    } else {
                                        setIcons([...icons, ...urls]);
                                    }
                                }}
                                onRemove={(item) => {
                                    let newIcons = icons.filter((icon) => icon !== item.url);
                                    setIcons(newIcons);
                                }} />
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    </Drawer>
};