import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ArrowLeftOutlined, CaretDownOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { getProducts } from "../../../actions/productActions";
import { updateProductOutlets, addProductOutlets } from "../../../actions/outletAction";
// import { getLocale } from "../../../actions/uiAction";
import { getL10N } from "../../../helpers/middleware";
import { Row, Col, Drawer, Select, Input, Switch } from "antd";
import Loading from "../common/Loading";
// import View from './View';
// import Save from './Save';
// import Delete from './Delete';
import "../../../assets/sass/dsk/outlet.sass";
import styled from "styled-components";
import InputValidation from "../common/InputValidation";
import { isBoolean } from "../../../helpers/util";

const { Option } = Select;

const Tax = styled.div`
    display: flex;
    .alignRight {
        input {
            text-align: right;
        }
    }
`;

// class ProductToOutlet extends React.Component<any, any> {
const ProductToOutlet = (props: any) => {
    const windowHeight = window.innerHeight;
    let { selectedOutlet, isVisible, defaultValue } = props;
    // const outlets = useSelector((state: any) => state.outlet.outlets);
    // const selectedLang = useSelector((state: any) => state.ui.locale);
    const [isEdit, setIsEdit] = useState(defaultValue ? false : true);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedProductTax, setSelectedProductTax] = useState(0);
    const [selectedProductCatalog, setSelectedProductCatalog] = useState(null);
    const [unassignedProducts, setUnassignedProducts] = useState([]);
    const [unassignedProductList, setUnassignedProductList] = useState([]);
    const [searchProduct, setSearchProduct] = useState(null);

    //         isEdit: defaultValue ? false : true,
    //         defaultValue,
    //         error: {
    //             selectedProduct: {
    //                 message: "",
    //                 type: ""
    //             },
    //             stock: {
    //                 message: "",
    //                 type: ""
    //             },
    //             isAvailable: {
    //                 message: "",
    //                 type: ""
    //             },
    //             outOfStock: {
    //                 message: "",
    //                 type: ""
    //             },
    //             price: {
    //                 message: "",
    //                 type: ""
    //             }
    //         },
    //         isVisible,
    //         selectedOutlets,
    //         // selectedOutlets: [outlet.id],
    //         selectedLang,
    //         products: [],
    //         selectedProduct: "",
    //         updatePrice,
    //         windowHeight: window.innerHeight

    let priceDetails = {
        mrp: null,
        price: 0,
        isAvailable: null,
        salePrice: 0,
        stock: 0,
        outOfStock: null,
        salePricePercentage: 0
    };

    if (defaultValue && defaultValue.outlets) {
        for (let item of defaultValue.outlets) {
            if (selectedOutlet === item.ouId) {
                priceDetails.mrp = item.mrp ? item.mrp : null;
                priceDetails.price = item.price ? item.price : 0;
                priceDetails.isAvailable = item.isAvailable;
                priceDetails.stock = item.stock ? item.stock : 0;
                priceDetails.outOfStock = item.outOfStock;
                priceDetails.salePrice = item.salePrice ? item.salePrice : 0;
                priceDetails.salePricePercentage = item.salePrice ? parseFloat((((item.price - item.salePrice) * 100) / item.price).toFixed(2)) : 0;
            }
        }
    }

    const [updatePrice, setUpdatePrice] = useState(priceDetails);
    let errorInfo = {
        selectedProduct: {
            message: "",
            type: "",
        },
        stock: {
            message: "",
            type: "",
        },
        isAvailable: {
            message: "",
            type: "",
        },
        outOfStock: {
            message: "",
            type: "",
        },
        price: {
            message: "",
            type: "",
        },
        salePrice: {
            message: "",
            type: "",
        },
    };

    const [error, setError] = useState(errorInfo);

    useEffect(() => {
        setSelectedProduct(defaultValue);
        if (defaultValue?.tax) {
            setSelectedProductTax(defaultValue?.tax);
        }
    }, [defaultValue]);

    useEffect(() => {
        if (selectedProductCatalog?.tax) {
            setSelectedProductTax(selectedProductCatalog?.tax);
        }
    }, [selectedProductCatalog])

    useEffect(() => {
        if (isVisible === false) {
            let priceDetails = {
                mrp: null,
                salePrice: 0,
                price: 0,
                isAvailable: null,
                stock: 0,
                outOfStock: null,
                salePricePercentage: 0
            };
            setSelectedProduct(null);
            setSelectedProductCatalog(null)
            setUnassignedProducts([]);
            setUnassignedProductList([]);
            setSelectedProductTax(0);
            setUpdatePrice({ ...updatePrice, ...priceDetails });
            // setUpdatePrice((preState) => ({ ...preState, ...priceDetails }));
        } else {
            setIsEdit(defaultValue ? false : true);
            loadProducts();
            setUpdatePrice({ ...updatePrice, ...priceDetails });
        }
        //eslint-disable-next-line
    }, [defaultValue, isVisible]);

    // constructor(props: any) {
    //     super(props);
    //     let { selectedOutlets, isVisible, defaultValue } = props;
    //     let updatePrice = {
    //         mrp: null,
    //         price: null,
    //         isAvailable: null,
    //         stock: null,
    //         outOfStock: null
    //     };
    //     if (defaultValue && defaultValue.outlets) {
    //         defaultValue.outlets.map((item) => {
    //             if (selectedOutlets.indexOf(item.ouId) > -1) {
    //                 updatePrice.mrp = item.mrp ? item.mrp : null;
    //                 updatePrice.price = item.price ? item.price : null;
    //                 updatePrice.isAvailable = item.isAvailable ? item.isAvailable : null;
    //                 updatePrice.stock = item.stock ? item.stock : null;
    //                 updatePrice.outOfStock = item.outOfStock ? item.outOfStock : null;
    //             }
    //         })
    //     }
    //     let selectedLang = getLocale();
    //     this.state = {
    //         isEdit: defaultValue ? false : true,
    //         defaultValue,
    //         error: {
    //             selectedProduct: {
    //                 message: "",
    //                 type: ""
    //             },
    //             stock: {
    //                 message: "",
    //                 type: ""
    //             },
    //             isAvailable: {
    //                 message: "",
    //                 type: ""
    //             },
    //             outOfStock: {
    //                 message: "",
    //                 type: ""
    //             },
    //             price: {
    //                 message: "",
    //                 type: ""
    //             }
    //         },
    //         isVisible,
    //         selectedOutlets,
    //         // selectedOutlets: [outlet.id],
    //         selectedLang,
    //         products: [],
    //         selectedProduct: "",
    //         updatePrice,
    //         windowHeight: window.innerHeight
    //     }
    // }

    // componentDidMount() {
    //     this.loadProducts();
    // }

    const loadProducts = async () => {
        let resp = await props.getProducts();
        // setProducts(resp);
        let unProductList = [];
        let isOutlet = false;
        if (resp) {
            for (let p of resp) {
                isOutlet = false;
                if (p.outlets && p.outlets.length > 0 && selectedOutlet) {
                    for (let i in p.outlets) {
                        if (selectedOutlet === p.outlets[i].ouId) {
                            isOutlet = true;
                        }
                    }
                }
                if (!isOutlet) {
                    unProductList.push(p);
                }
            }
        }
        setUnassignedProducts(unProductList);
        setUnassignedProductList(unProductList);
    };

    // static getDerivedStateFromProps(nextProps, prevState) {
    //     let updateProps: any = {};
    //     if (nextProps.outlet !== prevState.outlet && nextProps.outlet.outlets) {
    //         updateProps.outlets = nextProps.outlet.outlets;
    //     }
    //     if (nextProps.ui.locale !== prevState.selectedLang) {
    //         updateProps.selectedLang = nextProps.ui.locale;
    //     }
    //     return updateProps;
    // }

    // const onUpdatePrice = (e) => {
    //     if (e.target.name) {
    //         setUpdatePrice({ ...updatePrice, [e.target.name]: parseInt(e.target.value) });
    //     }
    // }

    // const loadUnassignedProducts = () => {

    // }

    const onSubmitHandler = async (event) => {
        event.preventDefault();
        // let { error, updatePrice, selectedProduct } = this.state;
        let isError = false;
        if (!selectedProduct) {
            error.selectedProduct.message = "Selected the product";
            error.selectedProduct.type = "error";
            isError = true;
        } else {
            error.selectedProduct.message = "";
            error.selectedProduct.type = "";
        }
        // if (isNaN(updatePrice.price) || updatePrice.price <= 0) {
        //     error.price.message = "Fill the product price";
        //     error.price.type = "error";
        //     isError = true;
        // } else {
        //     error.price.message = "";
        //     error.price.type = "";
        // }

        if (parseInt(updatePrice.price.toString()) > 0 && parseInt(updatePrice.salePrice.toString()) > parseInt(updatePrice.price.toString())) {
            isError = true;
            error.salePrice.message = "Sale price must be lesser than product price";
            error.salePrice.type = "warning";
        } else {
            error.salePrice.message = "";
            error.salePrice.type = "";
        }

        if (isNaN(updatePrice.stock)) {
            error.stock.message = "Fill the product stock";
            error.stock.type = "error";
            isError = true;
        } else {
            error.stock.message = "";
            error.stock.type = "";
        }
        if (!isBoolean(updatePrice.isAvailable)) {
            error.isAvailable.message = "Fill the product Available";
            error.isAvailable.type = "error";
            isError = true;
        } else {
            error.isAvailable.message = "";
            error.isAvailable.type = "";
        }
        // if (!isBoolean(updatePrice.outOfStock)) {
        //     error.outOfStock.message = "Fill the out of stock";
        //     error.outOfStock.type = "error";
        //     isError = true;
        // } else {
        //     error.outOfStock.message = "";
        //     error.outOfStock.type = "";
        // }
        if (isError !== true) {
            setIsLoading(true);
            let payloadOutlet = {
                ouId: selectedOutlet,
                stock: parseInt(updatePrice.stock.toString()),
                isAvailable: updatePrice.isAvailable === "true" || updatePrice.isAvailable ? true : false,
                outOfStock: updatePrice.outOfStock === "true" || updatePrice.outOfStock ? true : false,
                price: parseFloat(updatePrice.price.toString()),
            };
            if (updatePrice.salePrice) {
                payloadOutlet['salePrice'] = parseFloat(updatePrice.salePrice.toString())
            }
            // if (productDetails && !productDetails.outlets) {
            //     productDetails.outlets = []
            // }
            // productDetails.outlets.push(payloadOutlet);
            let resp = null;
            if (defaultValue) {
                resp = await props.updateProductOutlets(selectedProduct.id, payloadOutlet);
            } else {
                resp = await props.addProductOutlets(selectedProduct, payloadOutlet);
            }
            setIsLoading(false);
            if (resp && resp.isUpdated) {
                setError(errorInfo);
                props.onClose();
            }
        } else {
            setError((preState) => ({ ...preState, ...error }));
        }
    };

    // const onUpdateStock = (e) => {
    //     if (/^[0-9\b]+$/.test(e.target.value)) {
    //         setUpdatePrice({ ...updatePrice, [e.target.name]: parseInt(e.target.value) })
    //     }
    //      ?  : setUpdatePrice({ ...updatePrice, [e.target.name]: updatePrice }) }
    // }

    const onSearchProduct = () => {
        let newList = [];
        for (let unProduct of unassignedProducts) {
            const title = getL10N(unProduct, "title", null).i10n;
            if (
                searchProduct === null ||
                searchProduct.length === 0 ||
                title.toLowerCase().indexOf(searchProduct.toLowerCase()) > -1
            ) {
                newList.push(unProduct);
            }
        }
        setUnassignedProductList(newList);
    };

    useEffect(() => {
        onSearchProduct();
        //eslint-disable-next-line
    }, [searchProduct]);

    const onClose = () => {
        // setError(errorInfo);
        props.onClose();
    };
    return (
        <Drawer
            width={470}
            closable={false}
            onClose={onClose}
            className="outlet-update"
            visible={isVisible}
            footer={
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div
                        className="sp_btn"
                        onClick={() => {
                            setError(errorInfo);
                            props.onClose();
                        }}
                        style={{
                            boxShadow: "none",
                            width: 120,
                            fontSize: 14,
                            marginRight: 10,
                            border: "1px solid #121212",
                            color: "#000",
                            background: "#fff",
                        }}
                    >
                        Cancel
                    </div>
                    {!isEdit && (
                        <div
                            className="sp_btn"
                            onClick={() => {
                                setIsEdit(true);
                            }}
                            style={{ width: 120, fontSize: 14 }}
                        >
                            Edit
                        </div>
                    )}
                    {isEdit && (
                        <div className="sp_btn" onClick={onSubmitHandler} style={{ width: 155, fontSize: 14 }}>
                            {defaultValue ? "Update " : "Add "}Price
                        </div>
                    )}
                </div>
            }
        >
            {isLoading && <Loading background="#00000070"></Loading>}
            <div className="header">
                <ArrowLeftOutlined
                    className="arrow"
                    onClick={() => {
                        setError(errorInfo);
                        props.onClose();
                    }}
                ></ArrowLeftOutlined>
                <div className="title">Product Price Details</div>
            </div>
            <Row>
                <Col span={24} className="content">
                    {/* <ArrowLeftOutlined className="back" onClick={() => props.onClose()}></ArrowLeftOutlined>
                    <div className="group" style={{ marginBottom: 0 }}>
                        <div className="title">product price details</div>
                    </div> */}
                    <div style={{ overflow: "hidden", height: windowHeight - 140 + "px", width: "100%" }}>
                        <div className="group">
                            <div className="label">{defaultValue ? "Selected product" : "Select to add product *"}</div>
                            {!defaultValue && (
                                <InputValidation
                                    type={error.selectedProduct.type}
                                    message={error.selectedProduct.message}
                                >
                                    <Select
                                        showSearch={selectedProduct ? false : true}
                                        suffixIcon={
                                            selectedProduct ? (
                                                <CloseCircleOutlined onClick={() => { setSelectedProduct(null); setSelectedProductCatalog(null); }} />
                                            ) : (
                                                <CaretDownOutlined />
                                            )
                                        }
                                        disabled={!isEdit}
                                        className="add-product"
                                        placeholder="Select to add product"
                                        filterOption={false}
                                        // mode="tags" maxTagCount={1}
                                        value={selectedProduct}
                                        onSearch={(value: string) => {
                                            setSearchProduct(value);
                                        }}
                                        onChange={(id: string) => {
                                            setSelectedProduct(id);
                                            const selectedItem = unassignedProductList.filter((item: any) => item.id === id);
                                            if (selectedItem?.length > 0) {
                                                setSelectedProductCatalog(selectedItem[0]);
                                            }
                                        }}
                                    >
                                        {unassignedProductList.map((unProduct) => {
                                            return (
                                                <Option key={unProduct.id} value={unProduct.id}>
                                                    <div
                                                        style={{
                                                            height: 40,
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            justifyContent: "space-around",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                fontSize: 14,
                                                                color: "#121212",
                                                                fontWeight: 900,
                                                                textTransform: "uppercase",
                                                            }}
                                                        >
                                                            {getL10N(unProduct, "title", null).i10n}
                                                        </div>
                                                        <div
                                                            style={{
                                                                color: "#ADB7C3",
                                                                fontSize: 12,
                                                            }}
                                                        >
                                                            {getL10N(unProduct, "subtitle", null).i10n}
                                                        </div>
                                                    </div>
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </InputValidation>
                            )}
                            {defaultValue && (
                                <div
                                    style={{
                                        color: "#fff",
                                        border: "1px solid #ccc",
                                        padding: "8px 9px",
                                        borderRadius: 5,
                                    }}
                                >
                                    <div
                                        style={{
                                            fontSize: 14,
                                            color: "#121212",
                                            fontWeight: 900,
                                            marginBottom: 4,
                                        }}
                                    >
                                        {getL10N(defaultValue, "title", null).i10n}
                                    </div>
                                    <div
                                        style={{
                                            color: "#ADB7C3",
                                            fontSize: 12,
                                        }}
                                    >
                                        {getL10N(defaultValue, "subtitle", null).i10n}
                                    </div>
                                </div>
                            )}
                        </div>
                        {/* <div className="group">
                                <div className="label">MRP</div>
                                <Input type="text" name="mrp" value={updatePrice.mrp} defaultValue={this.state.updatePrice.mrp} placeholder="₹0"
                                    onChange={this.onUpdatePrice}
                                />
                                <InputValidation type={this.state.error.mrp.type} message={this.state.error.address1.message}>
                                </InputValidation>
                            </div> */}
                        <div className="group">
                            <div className="label">Price *</div>
                            <InputValidation type={error.price.type} message={error.price.message}>
                                <Tax>
                                    <Input
                                        style={{ borderLeftWidth: 1, borderRightWidth: 1, borderTopLeftRadius: 5, borderBottomLeftRadius: 5, borderBottomRightRadius: 0, borderTopRightRadius: 0 }}
                                        disabled={!isEdit}
                                        name="price"
                                        pattern="[0-9]*"
                                        value={updatePrice.price}
                                        placeholder="₹0"
                                        onChange={(e) => {
                                            const { value }: any = e.target;
                                            const reg = /^-?\d*(\.\d*)?$/;
                                            if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
                                                setUpdatePrice({ ...updatePrice, [e.target.name]: value });
                                            }
                                        }}
                                    />
                                    <Input key={selectedProduct?.id || selectedProduct} readOnly className="alignRight" value={(updatePrice.price || 0) * (selectedProductTax ? selectedProductTax : 0) / 100} prefix={<div style={{ marginTop: 4 }}>
                                        <div style={{ color: "#A7A7A7", fontSize: 10 }}>Tax</div>
                                        <div style={{ color: "#777777", fontSize: 13 }}>{selectedProductTax || 0}%</div>
                                    </div>} placeholder="0" style={{ borderLeftWidth: 0, borderRightWidth: 1, borderBottomRightRadius: 5, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 5 }}>
                                    </Input>
                                </Tax>
                            </InputValidation>
                        </div>
                        <div className="group">
                            <div className="label">Sale Price</div>
                            <InputValidation type={error.salePrice.type} message={error.salePrice.message}>
                                <div style={{ display: 'flex' }}>
                                    <Input
                                        style={{ borderRightWidth: 1, borderBottomRightRadius: 0, borderTopRightRadius: 0 }}
                                        prefix="₹"
                                        disabled={!isEdit}
                                        name="salePrice"
                                        value={updatePrice.salePrice}
                                        placeholder="₹0"
                                        onChange={(e) => {
                                            const { value }: any = e.target;
                                            const reg = /^-?\d*(\.\d*)?$/;
                                            if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
                                                const calPer: any = ((updatePrice.price - value) * 100) / updatePrice.price;
                                                setUpdatePrice({ ...updatePrice, ...{ [e.target.name]: value, salePricePercentage: calPer.toFixed(2) } });
                                            }
                                        }}
                                    />
                                    <Input
                                        style={{ borderLeftWidth: 0, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                        prefix="%"
                                        disabled={!isEdit}
                                        name="salePricePercentage"
                                        value={updatePrice.salePricePercentage}
                                        placeholder="0%"
                                        // maxLength={2}
                                        onChange={(e) => {
                                            const { value }: any = e.target;
                                            const reg = /^-?\d*(\.\d*)?$/;
                                            const calPer: any = updatePrice.price - ((updatePrice.price * value) / 100);
                                            if ((value !== 0 && value <= 100 && updatePrice.price && !isNaN(value) && reg.test(value)) || value === "" || value === "-") {
                                                setUpdatePrice({ ...updatePrice, ...{ [e.target.name]: value, salePrice: calPer.toFixed(2) } });
                                            }
                                        }}
                                    />
                                </div>
                            </InputValidation>
                        </div>
                        <div className="group">
                            <div className="label">Stock *</div>
                            <InputValidation type={error.stock.type} message={error.stock.message}>
                                <Input
                                    disabled={!isEdit}
                                    name="stock"
                                    value={updatePrice.stock}
                                    placeholder="₹0"
                                    onChange={(e) => {
                                        const { value }: any = e.target;
                                        const reg = /^-?\d*(\.\d*)?$/;
                                        if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
                                            setUpdatePrice({ ...updatePrice, [e.target.name]: value });
                                        }
                                    }}
                                />
                            </InputValidation>
                        </div>
                        <div className="group">
                            <div className="label">Visible *</div>
                            <InputValidation type={error.isAvailable.type} message={error.isAvailable.message}>
                                <Switch
                                    className="toggle-opt"
                                    disabled={!isEdit}
                                    checked={updatePrice.isAvailable}
                                    onChange={(value) => setUpdatePrice({ ...updatePrice, isAvailable: value })}
                                />
                            </InputValidation>
                        </div>
                        <div className="group">
                            <div className="label">Out Of Stock *</div>
                            <InputValidation type={error.outOfStock.type} message={error.outOfStock.message}>
                                <Switch
                                    className="toggle-opt"
                                    disabled={!isEdit}
                                    checked={updatePrice.outOfStock}
                                    onChange={(value) => setUpdatePrice({ ...updatePrice, outOfStock: value })}
                                />
                            </InputValidation>
                        </div>
                    </div>
                    {/* <div className="footer">
                        <Row>
                            <Col span={11} style={{ paddingRight: "15px" }}>
                                <button onClick={() => props.onClose()} className="sp_btn cancel">Cancel</button>
                            </Col>
                            <Col span={2}></Col>
                            <Col span={11} style={{ paddingLeft: "15px" }}>
                                {isEdit && <button onClick={onSubmitHandler} className="sp_btn save">{defaultValue ? "Update " : "Add "}Price</button>}
                                {!isEdit && <button onClick={() => setIsEdit(true)} className="sp_btn edit">Edit</button>}
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} >
                                {isEdit && <button onClick={onSubmitHandler} className="add-price">{defaultValue ? "Update " : "Add "}Price</button>}
                                {!isEdit && <button onClick={() => setIsEdit(true)} className="add-price">Edit</button>}
                            </Col>
                        </Row>
                    </div> */}
                </Col>
            </Row>
        </Drawer>
    );
};

function mapStateToProps(state: any) {
    // console.log("Product mapStateToProps: ", state);
    return {
        product: state.product,
        auth: state.auth,
        common: state.common,
        ui: state.ui,
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getProducts,
            updateProductOutlets,
            addProductOutlets,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductToOutlet);
