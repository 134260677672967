import React, { useEffect, useState, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
// import { getL10N, loadOutletBasedProduct } from "../../../helpers/middleware";
import { Row, Col, Select } from 'antd';
import '../../../assets/sass/dsk/order.sass';
import ExportSvg from "../../../assets/images/export.svg";
// import ArrowSvg from "../../../assets/images/downArrow.svg";
import DownloadInvoiceSvg from "../../../assets/images/download-invoice.svg";
import DownloadAllInvoiceSvg from "../../../assets/images/bulkDownload.svg";
import {
    getOrders, updateAllOrderStatus, updateOrderStatus,
    getDeliveryExecs, assignDelivery, getIncompleteOrders,
    getIncompleteOrderDates, downloadOrderInvoice, downloadIncompleteOrder,
    downloadCurrentOrderInvoices
} from "../../../actions/orderActions";
import { selectedOrderDates, currentOrderFilterOptions as onOrderFilterOptions } from "../../../actions/uiAction";
import { getOutletSlot } from "../../../actions/outletAction";
import { selectedOutlets } from '../../../actions/commonAction';
import dayjs from "dayjs";
// import Loading from '../common/Loading';
import DeliveryService from './DeliveryService';
import { CONSTANTS } from '../../../constants';
import QueryFilter from '../common/QueryFilter';
import Procurement from './Procurement';
import ProcurementSvg from '../../../assets/images/note.svg';
import FilterPanelSvg from '../../../assets/images/filter.svg';
import Loading from '../common/Loading';
import PopupConfirm from '../common/PopupConfirm';
import { useNav } from '../../../helpers/customHook';

const { Option } = Select;
// const { RangePicker } = DatePicker;
const conditions = [
    { key: "orderNo", value: "Order No" },
    // { key: "phoneNo", value: "Phone No" },
    { key: "any", value: "Any" },
    // { key: "invoice", value: "Invoice" },
    // { key: "orderstatus", value: "Order Status" },
    // { key: "date", value: "Date" },
    // { key: "totalamount", value: "Total Amount" },
    // { key: "item", value: "Item" },
];
const OrderStatusCode = [
    { key: 'all', value: 'All' },
    { key: 'awaitingConfirmation', value: 'Awaiting Confirmation' },
    { key: 'awaitingFulfilment', value: 'Awaiting Fulfilment' },
    { key: 'shipped', value: 'Shipped' },
    { key: 'awaitingPickup', value: 'Awaiting Pickup' }
];

const ReportTypes = [
    { key: 'orderSummary', value: 'Order Summary' },
    { key: 'orderItems', value: 'Order Items' },
];

const CurrentOrders = (props: any) => {
    const navigateTo = useNav();
    const { outlets } = useSelector((state: any) => state.outlet);
    const [showProcurement, setShowProcurement] = useState(false);
    const [selectedOrderStatus, setSelectedOrderStatus] = useState(OrderStatusCode[0].key);
    const { incompleteOrders } = useSelector((state: any) => state.order);
    const [orderList, setOrderList] = useState([]);
    const { currentOrderFilterOptions: orderFilterOptions } = useSelector((state: any) => state.ui);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [showDeliveryExecs, setShowDeliveryExecs] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedOuId, setSelectedOuId] = useState(null);
    const [selectedSlotId, setSelectedSlotId] = useState(null);
    const [selectedSlotDate, setSelectedSlotDate] = useState(null);
    const [slotDates, setSlotDates]: any = useState(null);
    const [listedSlot, setListedSlot] = useState(null);
    const [slots, setSlots] = useState([]);
    const [queries, setQueries] = useState(null);
    const [filterPanel, setFilterPanel] = useState(false);
    const [popupConfirm, setPopupConfirm] = useState(null);
    const reportType = useRef(ReportTypes[0].key);
    // const [orders, setOrders] = useState([]);

    useEffect(() => {
        if (filterPanel) {
            const filterSlots = slots.filter((s) => {
                return selectedSlotDate === 'all' || (s.orderDates && s.orderDates.indexOf(selectedSlotDate) > -1)
            });
            setListedSlot(filterSlots)
        }
        // setSelectedSlotId('all');
        // props.onOrderFilterOptions({ ...orderFilterOptions, slotId: "all" })
        //eslint-disable-next-line
    }, [selectedSlotDate, filterPanel, slots]);

    // useEffect(() => {
    //     if (!filterPanel) {
    //         props.onOrderFilterOptions({ ...orderFilterOptions, slotId: "all" })
    //     }
    //     //eslint-disable-next-line
    // }, [filterPanel]);

    const getIncompleteOrderDates = async () => {
        let queryParams = {};
        if (selectedOuId !== 'all') {
            queryParams['ouId'] = selectedOuId;
        }
        const resp = await props.getIncompleteOrderDates(queryParams);
        if (resp && resp.slots) {
            setSlots(resp.slots);
        }
    }

    useEffect(() => {
        let slotDatesOptions = []
        if (slots) {
            let dates = []
            for (let slot of slots) {
                if (slot.orderDates) {
                    dates = [...dates, ...slot.orderDates];
                }
            }
            dates = dates.filter((item, index) => {
                return (dates.indexOf(item) === index)
            })
            dates.sort(function (a, b) {
                return new Date(b).getTime() - new Date(a).getTime();
            });
            for (let d of dates) {
                slotDatesOptions.push({
                    key: d,
                    value: dayjs(d).calendar()
                })
            }
        }
        setSlotDates(slotDatesOptions);
    }, [slots])

    useEffect(() => {
        if (incompleteOrders) {
            setOrderList(incompleteOrders)
        }
    }, [incompleteOrders])

    useEffect(() => {
        if (selectedOuId) {
            getIncompleteOrderDates();
        }
        //eslint-disable-next-line
    }, [selectedOuId]);

    const generateQueryParams = () => {
        let queryFilters: any = {};
        if (orderFilterOptions.query) {
            for (let q of orderFilterOptions.query) {
                if (!queryFilters[q.fId]) {
                    queryFilters[q.fId] = []
                }
                queryFilters[q.fId].push(q.fValue);
            }
        }
        let queryParams = {};
        if (queryFilters.orderNo && queryFilters.orderNo.length > 0) {
            queryParams['orderNo'] = queryFilters.orderNo[0];
        }
        if (queryFilters.phoneNo && queryFilters.phoneNo.length > 0) {
            queryParams['phoneNo'] = queryFilters.phoneNo[0];
        }
        if (queryFilters.any && queryFilters.any.length > 0) {
            queryParams['query'] = queryFilters.any[0];
        }
        if (orderFilterOptions.ouId && orderFilterOptions.ouId !== 'all') {
            queryParams['ouId'] = orderFilterOptions.ouId;
        }
        if (orderFilterOptions.slotId && orderFilterOptions.slotId !== 'all') {
            queryParams['deliverySlotId'] = orderFilterOptions.slotId;
        }
        if (orderFilterOptions.slotDate && orderFilterOptions.slotDate !== 'all') {
            queryParams['date'] = orderFilterOptions.slotDate;
        }
        if (orderFilterOptions.status && orderFilterOptions.status !== 'all') {
            queryParams['status'] = orderFilterOptions.status;
        }
        return queryParams;
    }

    const getIncompleteOrders = async () => {
        setIsLoading(true);
        const queryParams = generateQueryParams();
        await props.getIncompleteOrders(queryParams);
        setIsLoading(false);
    }

    const downloadIncompleteOrder = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setPopupConfirm({
            title: "Select report type",
            renderContent: () => {
                return <Select defaultValue={reportType.current} placeholder="Report type" onChange={(val: string) => { reportType.current = val }}  >
                    {ReportTypes.map((opt) => {
                        return <Option key={opt.key} value={opt.key}>{opt.value}</Option>
                    })}
                </Select>
            },
            yes: {
                label: "Download",
                callback: async () => {
                    const queryParams = generateQueryParams();
                    queryParams['reportType'] = reportType.current;
                    const blob = await props.downloadIncompleteOrder(queryParams);
                    if (blob) {
                        const link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = `incomplete_${reportType.current}_${new Date().toLocaleDateString()}.xlsx`;
                        document.body.append(link);
                        link.click();
                        link.remove();
                        setTimeout(() => URL.revokeObjectURL(link.href), 7000);
                    }
                    setPopupConfirm(null);
                }
            },
            no: {
                label: "Cancel",
                callback: () => { setPopupConfirm(null); }
            }
        });
    }

    const getDownloadOrderInvoices = async (e) => {
        const queryParams = generateQueryParams();
        queryParams['downloadInvoice'] = true;
        e.preventDefault();
        e.stopPropagation();
        const blob = await props.downloadCurrentOrderInvoices(queryParams);
        if (blob) {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${new Date().getTime()}_order_invoice.pdf`;
            document.body.append(link);
            link.click();
            link.remove();
            setTimeout(() => URL.revokeObjectURL(link.href), 7000);
        }
    }

    useEffect(() => {
        if (orderFilterOptions) {
            setQueries(orderFilterOptions.query);
            setSelectedOuId(orderFilterOptions.ouId);
            setSelectedSlotId(orderFilterOptions.slotId);
            setSelectedOrderStatus(orderFilterOptions.status);
            setSelectedSlotDate(orderFilterOptions.slotDate);
            getIncompleteOrders();
        }
        // setQueries(orderFilterOptions);
        //eslint-disable-next-line
    }, [orderFilterOptions])

    useEffect(() => {
        if (orderFilterOptions) {
            setQueries(orderFilterOptions.query);
            setSelectedOuId(orderFilterOptions.ouId);
            setSelectedSlotId(orderFilterOptions.slotId);
            setSelectedOrderStatus(orderFilterOptions.status);
            setSelectedSlotDate(orderFilterOptions.slotDate);
            if (orderFilterOptions.slotId === "all" && orderFilterOptions.slotDate === "all") {
                setFilterPanel(false);
            }
            getIncompleteOrders();
        }
        //eslint-disable-next-line
    }, []);

    // useEffect(() => {
    //     if (!filterPanel) {
    //         // setSelectedSlotId('all');
    //         props.onOrderFilterOptions({ ...orderFilterOptions, slotId: "all" })
    //     }
    // }, [filterPanel]);

    // useEffect(() => {
    //     if (selectedSlotDate !== "all") {
    //         setFilterPanel(true);
    //     }
    // }, [selectedSlotDate])

    // useEffect(() => {
    //     if (selectedOuId !== null && selectedSlotId !== null && queries !== null && selectedOrderStatus !== null && selectedSlotDate !== null) {
    //         getIncompleteOrders();
    //     }
    //     console.log({ selectedOuId, selectedSlotId, queries, selectedOrderStatus, selectedSlotDate })
    //     //eslint-disable-next-line
    // }, [selectedOuId, selectedSlotId, queries, selectedOrderStatus, selectedSlotDate])

    // const updateOrderStatus = async (status, o) => {
    //     if (status && o.id) {
    //         setIsLoading(true);
    //         if (status === "ship") {
    //             await props.getDeliveryExecs(o.ouId);
    //             setSelectedOrder(o);
    //             setShowDeliveryExecs(true);
    //             // setDeliveryExecs(respDeliveryExecs);
    //         } else {
    //             await props.updateOrderStatus(o.id, status);
    //         }
    //         setIsLoading(false);
    //     }
    // }

    const downloadInvoice = async (e, orderId) => {
        e.preventDefault();
        e.stopPropagation();
        const blob = await props.downloadOrderInvoice(orderId);
        if (blob) {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${orderId}_invoice.pdf`;
            document.body.append(link);
            link.click();
            link.remove();
            setTimeout(() => URL.revokeObjectURL(link.href), 7000);
        }
    }

    return (
        <Row className="order">
            {isLoading && <Loading background="#00000070"></Loading>}
            <Col className="list" span={24}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: '#f5f5f5', padding: '0px 16px' }}>
                    <div className="action-bar">
                        {/* <div className="title">
                        You've {orders && orders.length} orders listed
                    </div> */}
                        <QueryFilter onSelectedFilters={(val) => props.onOrderFilterOptions({ ...orderFilterOptions, query: val })}
                            defaultValue={queries} properties={conditions} queries={queries}></QueryFilter>
                    </div>
                    <div style={{ width: 240, display: 'flex', alignItems: 'center', }}>
                        <Select
                            className="select"
                            size="middle"
                            value={selectedOuId}
                            defaultValue={selectedOuId}
                            onChange={(value) => { props.onOrderFilterOptions({ ...orderFilterOptions, ouId: value, slotDate: 'all', slotId: 'all' }) }}
                        >
                            <Option key="all_outlets" style={{ paddingLeft: '16px' }} value="all">
                                <label htmlFor={"all_outlets"} style={{ paddingLeft: "16px" }}>{"All"}</label>
                            </Option>
                            {outlets && outlets.map((item) => {
                                return <Option key={item.id} style={{ paddingLeft: '16px' }} value={item.id}>
                                    <label htmlFor={item.id} style={{ paddingLeft: "16px" }}>{item.name}</label>
                                </Option>
                            })}
                        </Select>
                    </div>
                    <div style={{ width: 240, display: 'flex', alignItems: 'center', marginLeft: 4 }}>
                        <Select
                            className={`select status`}
                            size="middle"
                            value={selectedOrderStatus}
                            defaultValue={selectedOrderStatus}
                            onChange={(value) => { props.onOrderFilterOptions({ ...orderFilterOptions, status: value }) }}
                        >
                            {OrderStatusCode && OrderStatusCode.map((item: any) => {
                                return <Option key={item.key} style={{ paddingLeft: '16px' }} value={item.key}>
                                    <label className={`${selectedOrderStatus}`} htmlFor={item.key} style={{ paddingLeft: "16px" }}>{item.value}</label>
                                </Option>
                            })}
                        </Select>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', width: 120 }}>
                        <div onClick={() => setShowProcurement(true)} style={{ cursor: 'pointer' }}><img alt='Export' src={ProcurementSvg} style={{ marginRight: 4 }} title={"Procurement"} /></div>
                        <div style={{ cursor: 'pointer' }}><img alt='Export' src={ExportSvg} onClick={downloadIncompleteOrder} style={{ marginRight: 4 }} title={"Export"} /> </div>
                        <div style={{ cursor: 'pointer' }}><img alt='All Invoice' src={DownloadAllInvoiceSvg} onClick={getDownloadOrderInvoices} style={{ marginRight: 4, width: 20 }} title={"All Invoice"} /> </div>
                        <div style={{ cursor: 'pointer' }}>
                            <img alt="Filter Icon" src={FilterPanelSvg} onClick={() => {
                                setFilterPanel(!filterPanel)
                            }} />
                        </div>
                    </div>
                </div>
                {filterPanel && <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '0px 16px' }}>
                    <div className="delivery-slots">
                        {/* <div onClick={() => setSelectedSlot('all')} className={selectedSlot === 'all' ? 'slot selected' : 'slot'}>{'All'}</div> */}
                        <div key={'slots_all'} onClick={() => props.onOrderFilterOptions({ ...orderFilterOptions, slotId: "all" })} className={selectedSlotId === 'all' ? 'slot selected' : 'slot'}>{'All'}</div>
                        {listedSlot && listedSlot.map((slot) => {
                            return slot.name && <div key={slot.id} onClick={() => props.onOrderFilterOptions({ ...orderFilterOptions, slotId: slot.id })} className={selectedSlotId === slot.id ? 'slot selected' : 'slot'}>{slot.name}</div>
                        })}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Select className='select' style={{ width: 350, marginRight: 10 }} size='middle' value={selectedSlotDate} onChange={(val) => {
                            // setSelectedSlotId(null);
                            // setSelectedSlotDate(val);
                            props.onOrderFilterOptions({ ...orderFilterOptions, slotId: null, slotDate: val })
                        }}>
                            <Option key="all_dates" style={{ paddingLeft: '16px' }} value="all">
                                <label htmlFor={"all_dates"} style={{ paddingLeft: "16px" }}>{"All"}</label>
                            </Option>
                            {slotDates && slotDates.map((od) => {
                                return <Option key={new Date(od.key).getTime().toString()} style={{ paddingLeft: '16px' }} value={`${od.key}`}>
                                    <label htmlFor={new Date(od.key).getTime().toString()} style={{ paddingLeft: "16px" }}>
                                        {/* {dayjs(od.key).calendar(null, {
                                            sameDay: "[Today]",
                                            nextDay: "[Tomorrow]",
                                            nextWeek: "dddd",
                                            lastDay: "[Yesterday]",
                                            lastWeek: "[Last] ddd",
                                            sameElse: "MMM D YYYY [at] h:mm A",
                                        })} */}
                                        {dayjs(od.key).calendar(null, {
                                            sameDay: "MMM-DD-YYYY  dddd",
                                            nextDay: "MMM-DD-YYYY  dddd",
                                            nextWeek: "MMM-DD-YYYY  dddd",
                                            lastDay: "MMM-DD-YYYY  dddd",
                                            lastWeek: "MMM-DD-YYYY  dddd",
                                            sameElse: "MMM-DD-YYYY  dddd",
                                        })}
                                    </label>
                                </Option>
                            })}
                        </Select>
                    </div>
                </div>}
            </Col>

            <Col span={24}>
                <div className='orders'>
                    <Row className='header'>
                        <Col span={3}>
                            Order ID
                        </Col>
                        <Col span={3}>
                            Customer
                        </Col>
                        {/* <Col span={2}>
                            No of order items
                        </Col> */}
                        <Col span={3}>
                            Delivery Area
                        </Col>
                        <Col span={3}>
                            Total Amount
                        </Col>
                        <Col span={4}>
                            Delivery Slot
                        </Col>
                        <Col span={4}>
                            Order Date
                        </Col>
                        <Col span={3}>
                            Order Status
                        </Col>
                        <Col span={1} style={{ textAlign: 'center' }}>
                            Action
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div style={{ height: 1, background: '#D8DEE6', margin: "0px 15px" }}></div>
                        </Col>
                    </Row>
                    <div className='details' style={{ height: filterPanel ? "72vh" : "76vh" }}>
                        {/* <Col span={24}> */}
                        {(!orderList || orderList.length === 0) && <div className='no-data-found'>No orders found</div>}
                        {orderList.map((order) => {
                            return <Row onClick={() => navigateTo(`details/${order.id}`)} key={order.id} style={{
                                background: selectedOrder && selectedOrder.id === order.id ? '#CBCBCB33' : '#FFF', borderRadius: 4
                            }}>
                                <Col span={24}>
                                    <Row style={{ cursor: 'pointer', height: 50, display: 'flex', alignItems: 'center' }} onClick={() => { !selectedOrder || selectedOrder.id !== order.id ? setSelectedOrder(order) : setSelectedOrder(null) }} >
                                        <Col span={3}>
                                            <div style={{ display: 'flex' }}>
                                                {/* <img
                                                    alt='Arrow'
                                                    style={{ marginTop: -4, marginLeft: -24, cursor: 'pointer', transition: 'transform 1s', transform: `rotate(${selectedOrder && selectedOrder.id === order.id ? '0' : '-90'}deg)` }}
                                                    src={ArrowSvg} /> */}
                                                <div>#{order.orderNo}</div>
                                            </div>
                                        </Col>
                                        {/* style={{ paddingLeft: 14 }} */}
                                        <Col span={3}>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div style={{ position: "relative" }}>
                                                        {order.customer?.ordersReceived === 1 && <div className='label-new'>New</div>}
                                                        {order.customer?.fullName}</div>
                                                </div>
                                            </div>
                                        </Col>
                                        {/* style={{ paddingLeft: 6 }} */}
                                        {/* <Col span={2}>
                                            {order.items.length}
                                        </Col> */}
                                        <Col span={3}>
                                            <div style={{ display: 'flex' }}>
                                                {order.pickup ? <div style={{ color: "#ff4800" }}>Outlet Pickup</div> : <div>
                                                    {order.shippingAddress?.area}
                                                </div>}
                                            </div>
                                        </Col>
                                        <Col span={3}>
                                            <div style={{ display: 'flex' }}>
                                                <div>₹{order?.billing?.toPay?.toLocaleString('en-IN')} </div> <div style={{ fontWeight: 500, marginLeft: 4 }}>[{order.payment && order.payment.mode.toUpperCase()}]</div>
                                            </div>
                                        </Col>
                                        <Col span={4}>
                                            {order.deliverySlot?.date && <div>
                                                {dayjs(order.deliverySlot?.date).format('MMM')} {dayjs(order.deliverySlot?.date).format('DD')},{dayjs(order.deliverySlot?.date).format('YYYY')}
                                            </div>}
                                            <div title={order.deliverySlot?.name} style={{ color: "#404040", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", paddingRight: 10 }}>
                                                {order.deliverySlot?.name}
                                            </div>
                                        </Col>
                                        <Col span={4}>
                                            <Row>
                                                <Col style={{ marginRight: 4 }}>
                                                    {dayjs(order.createdAt).format('ddd')}, {dayjs(order.createdAt).format('MMM')} {dayjs(order.createdAt).format('DD')},{dayjs(order.createdAt).format('YYYY')}
                                                </Col>
                                                <Col>
                                                    {dayjs(order.createdAt).format('hh:mm A')}
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={3}>
                                            <div style={{ color: CONSTANTS.OrderStatus.colors[order.status] }}>
                                                {CONSTANTS.OrderStatus.labels[order.status] || order.status}
                                            </div>
                                        </Col>
                                        <Col span={1} style={{ textAlign: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                            <img src={DownloadInvoiceSvg} title={'Download Invoice'} alt={'Download Invoice'} style={{ cursor: 'pointer', width: 16 }} onClick={(e) => downloadInvoice(e, order.id)} />
                                        </Col>
                                    </Row>
                                    {/* {selectedOrder && selectedOrder.id === order.id && <Row style={{
                                        background: '#FFFFFF',
                                        borderRadius: 4,
                                        margin: '0px 13px 13px 13px',
                                        padding: 14
                                    }}>
                                        <Col span={6} style={{ padding: '0px 0px 0px 20px' }}>
                                            <div style={{ color: '#1C3A66', fontWeight: 'bold', marginBottom: 10 }}>Customer Details</div>
                                            <Row style={{ marginBottom: 10 }}>
                                                <Col span={7} style={{ color: '#5E6774', fontSize: 14 }}>
                                                    Name:
                                                </Col>
                                                <Col span={17}>
                                                    {order.customer.fullName}
                                                </Col>
                                            </Row>
                                            <Row style={{ marginBottom: 10 }}>
                                                <Col span={7} style={{ color: '#5E6774', fontSize: 14 }}>
                                                    Email ID:
                                                </Col>
                                                <Col span={17}>
                                                </Col>
                                            </Row>
                                            <Row style={{ marginBottom: 10 }}>
                                                <Col span={7} style={{ color: '#5E6774', fontSize: 14 }}>
                                                    Phone Number:
                                                </Col>
                                                <Col span={17}>
                                                    {order.shippingAddress.phoneNo}
                                                </Col>
                                            </Row>
                                            <Row style={{ marginBottom: 10 }}>
                                                <Col span={7} style={{ color: '#5E6774', fontSize: 14 }}>
                                                    Pincode:
                                                </Col>
                                                <Col span={17}>
                                                    {order.shippingAddress.zipcode}
                                                </Col>
                                            </Row>
                                            <Row style={{ marginBottom: 10 }}>
                                                <Col span={7} style={{ color: '#5E6774', fontSize: 14 }}>
                                                    City:
                                                </Col>
                                                <Col span={17}>
                                                    {order.shippingAddress.city}
                                                </Col>
                                            </Row>
                                            <Row style={{ marginBottom: 10 }}>
                                                <Col span={7} style={{ color: '#5E6774', fontSize: 14 }}>
                                                    State:
                                                </Col>
                                                <Col span={17}>
                                                    {order.shippingAddress.state}
                                                </Col>
                                            </Row>
                                            <Row style={{ marginBottom: 10 }}>
                                                <Col span={7} style={{ color: '#5E6774', fontSize: 14 }}>
                                                    Address:
                                                </Col>
                                                <Col span={17}>
                                                    <div>{order.shippingAddress.houseName}, {order.shippingAddress.addressLine}, {order.shippingAddress.area},</div>
                                                    <div>{order.shippingAddress.city} - {order.shippingAddress.zipcode}</div>
                                                    <div>{order.shippingAddress.state}</div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={18} style={{ padding: '0px 0px 0px 20px', borderLeft: '1px solid #B5BFCC' }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 14 }}>
                                                <div style={{ color: '#1C3A66', fontWeight: 'bold' }}>Order Items</div>
                                                {order.status === "awaitingFulfilment" && <div className="action-bar">
                                                    <div className="action-bar">
                                                        <div className="reject" onClick={() => { updateOrderStatus("cancel", order) }}>Cancel</div>
                                                        <div className="accept" onClick={() => { updateOrderStatus("ship", order) }}>Ship</div>
                                                    </div>
                                                </div>}
                                                {order.status === "awaitingConfirmation" && <div className="action-bar">
                                                    <div className="reject" onClick={() => { updateOrderStatus("decline", order) }}>Reject</div>
                                                    <div className="accept" onClick={() => { updateOrderStatus("confirm", order) }}>Accept</div>
                                                </div>}
                                                {order.status === "shipped" && <div className="action-bar">
                                                    <div className="reject" onClick={() => { updateOrderStatus("cancel", order) }}>Cancel</div>
                                                    <div className="accept" onClick={() => { updateOrderStatus("complete", order) }}>Completed</div>
                                                </div>}
                                            </div>
                                            <Row style={{ marginBottom: 10, borderBottom: '1px solid #E7EEF7', height: 40, alignItems: 'center', display: 'flex' }}>
                                                <Col span={16} style={{ color: '#5E6774', fontSize: 14 }}>
                                                    Products
                                                </Col>
                                                <Col span={4}>
                                                    Quantity
                                                </Col>
                                                <Col span={4}>
                                                    Price
                                                </Col>
                                            </Row>
                                            <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <Col span={24} style={{ borderBottom: '1px solid #E7EEF7' }}>
                                                    {order?.items.map((i) => {
                                                        return <Row style={{ marginBottom: 12 }} key={i.id}>
                                                            <Col span={16}>{i.title}</Col>
                                                            <Col span={4}><div>{i.quantity} X {i.price.toLocaleString('en-IN')}</div></Col>
                                                            <Col span={4}><div>₹{(i.quantity * i.price).toLocaleString('en-IN')}</div></Col>
                                                        </Row>
                                                    })}
                                                </Col>
                                                <Col span={24} >
                                                    <Row style={{ marginBottom: 12, fontSize: 15, color: '#188FFC' }}>
                                                        <Col span={16}></Col>
                                                        <Col span={4}>Grand Total</Col>
                                                        <Col span={4}>₹{order.billing.toPay.toLocaleString('en-IN')}</Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>} */}
                                </Col>
                            </Row>
                        })}
                        {/* </Col> */}
                    </div>
                </div>
                {orderList.length > 0 && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 800, fontSize: 16, marginTop: 8 }}>Total Orders {orderList.length}</div>}
            </Col>
            {popupConfirm && <PopupConfirm config={popupConfirm}></PopupConfirm>}
            <Procurement ouId={selectedOuId} date={selectedSlotDate} slotId={selectedSlotId} isVisible={showProcurement} onClose={() => setShowProcurement(false)}></Procurement>
            <DeliveryService onClose={() => { setShowDeliveryExecs(false); setSelectedOrder(null); }} selectedOrder={selectedOrder} selectedOuId={selectedOuId} isVisible={showDeliveryExecs} />
        </Row >
    )
}



function mapStateToProps(state: any) {
    // console.log("Categories mapStateToProps: ", state);
    return {
        order: state.order,
        ui: state.ui
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getOrders,
        updateAllOrderStatus,
        selectedOrderDates,
        updateOrderStatus,
        getDeliveryExecs,
        getIncompleteOrders,
        downloadIncompleteOrder,
        downloadCurrentOrderInvoices,
        getOutletSlot,
        selectedOutlets,
        assignDelivery,
        getIncompleteOrderDates,
        onOrderFilterOptions,
        downloadOrderInvoice
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CurrentOrders);