import React, { useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import {
  // Announcements,
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
  DragStartEvent,
  DragOverlay,
  DragMoveEvent,
  DragEndEvent,
  DragOverEvent,
  MeasuringStrategy,
  DropAnimation,
  defaultDropAnimation,
  Modifier
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";

import {
  buildTree,
  flattenTree,
  getProjection,
  getChildCount,
  // removeItem,
  removeChildrenOf,
  setProperty
} from "./utilities";
import type { FlattenedItem, SensorContext, TreeItems } from "./types";
import { SortableTreeItem } from "./treeView";

const measuring = {
  droppable: {
    strategy: MeasuringStrategy.Always
  }
};

const dropAnimation: DropAnimation = {
  ...defaultDropAnimation,
  // dragSourceOpacity: 0.5
};

interface Props {
  collapsible?: boolean;
  defaultItems?: TreeItems;
  onEdit?: (id: string) => void;
  onAdd?: (id: string) => void;
  onRemove?: (id: string) => void;
  onChange?: (items: TreeItems) => void;
  indentationWidth?: number;
  indicator?: boolean;
  removable?: boolean;
}

export function SortableTree({
  collapsible,
  defaultItems,
  indicator,
  indentationWidth = 20,
  onEdit,
  onAdd,
  onRemove,
  onChange,
  removable
}: Props) {
  const [items, setItems] = useState([]);
  const [activeId, setActiveId] = useState<string | null>(null);
  const [overId, setOverId] = useState<string | null>(null);
  const [offsetLeft, setOffsetLeft] = useState(0);
  // const [currentPosition, setCurrentPosition] = useState<{
  //   parentId: string | null;
  //   overId: string;
  // } | null>(null);

  useEffect(() => {
    if (defaultItems) {
      setItems(defaultItems);
    }
  }, [defaultItems]);

  const flattenedItems = useMemo(() => {
    const flattenedTree = flattenTree(items);
    const collapsedItems = flattenedTree.reduce<string[]>(
      (acc, { children, collapsed, id }) =>
        collapsed && children.length ? [...acc, id] : acc,
      []
    );

    return removeChildrenOf(
      flattenedTree,
      activeId ? [activeId, ...collapsedItems] : collapsedItems
    );
  }, [activeId, items]);
  const projected =
    activeId && overId
      ? getProjection(
        flattenedItems,
        activeId,
        overId,
        offsetLeft,
        indentationWidth
      )
      : null;
  const sensorContext: SensorContext = useRef({
    items: flattenedItems,
    offset: offsetLeft
  });
  const sensors = useSensors(
    useSensor(PointerSensor)
    // useSensor(KeyboardSensor, {
    //   coordinateGetter,
    // })
  );

  const sortedIds = useMemo(() => flattenedItems.map(({ id }) => id), [
    flattenedItems
  ]);
  const activeItem = activeId
    ? flattenedItems.find(({ id }) => id === activeId)
    : null;

  useEffect(() => {
    sensorContext.current = {
      items: flattenedItems,
      offset: offsetLeft
    };
  }, [flattenedItems, offsetLeft]);

  // const announcements: Announcements = {
  // onDragStart(id) {
  //   return `Picked up ${id}.`;
  // },
  // onDragMove(id, overId) {
  //   return getMovementAnnouncement("onDragMove", id, overId);
  // },
  // onDragOver(id, overId) {
  //   return getMovementAnnouncement("onDragOver", id, overId);
  // },
  // onDragEnd(id, overId) {
  //   return getMovementAnnouncement("onDragEnd", id, overId);
  // },
  // onDragCancel(id) {
  //   return `Moving was cancelled. ${id} was dropped in its original position.`;
  // }
  // };

  useEffect(() => {
    onChange(items);
    // eslint-disable-next-line
  }, [items]);

  const getTitle = (id: string) => {
    const item: any = flattenedItems.find(({ id: itemId }) => itemId === id);
    return item ? item.title : "";
  }

  return (
    <DndContext
      // announcements={announcements}
      sensors={sensors}
      collisionDetection={closestCenter}
      measuring={measuring}
      onDragStart={handleDragStart}
      onDragMove={handleDragMove}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext items={sortedIds} strategy={verticalListSortingStrategy}>
        {flattenedItems.map((item: any) => {
          const { id, children, depth, collapsed, title } = item;
          return <SortableTreeItem
            key={id}
            id={id}
            value={title}
            depth={id === activeId && projected ? projected.depth : depth}
            indentationWidth={indentationWidth}
            indicator={indicator}
            collapsed={Boolean(collapsed && children.length)}
            onCollapse={
              collapsible && children.length
                ? () => handleCollapse(id)
                : undefined
            }
            onEdit={() => onEdit(id)}
            onRemove={() => onRemove(id)}
            // onRemove={removable ? () => handleRemove(id) : undefined}
            onAdd={() => onAdd(id)}
          />
        })}
        {createPortal(
          <DragOverlay
            zIndex={10000}
            dropAnimation={dropAnimation}
            modifiers={indicator ? [adjustTranslate] : undefined}
          >
            {activeId && activeItem ? (
              <SortableTreeItem
                id={activeId}
                depth={activeItem.depth}
                clone={true}
                childCount={getChildCount(items, activeId) + 1}
                value={getTitle(activeId)}
                indentationWidth={indentationWidth}
              />
            ) : null}
          </DragOverlay>,
          document.body
        )}
      </SortableContext>
    </DndContext>
  );

  function handleDragStart(event: DragStartEvent) {
    const { active } = event;
    const activeId = active.id.toString();
    setActiveId(activeId);
    setOverId(activeId);

    // const activeItem = flattenedItems.find(({ id }) => id === activeId);

    // if (activeItem) {
    //   setCurrentPosition({
    //     parentId: activeItem.parentId,
    //     overId: activeId
    //   });
    // }

    document.body.style.setProperty("cursor", "grabbing");
  }

  function handleDragMove({ delta }: DragMoveEvent) {
    setOffsetLeft(delta.x);
  }

  function handleDragOver(event: DragOverEvent) {
    const { over } = event;
    setOverId(over?.id.toString() || null);
  }

  function handleDragEnd({ active, over }: DragEndEvent) {
    resetState();

    if (projected && over) {
      const { depth, parentId } = projected;
      const clonedItems: FlattenedItem[] = JSON.parse(
        JSON.stringify(flattenTree(items))
      );
      const overIndex = clonedItems.findIndex(({ id }) => id === over.id);
      const activeIndex = clonedItems.findIndex(({ id }) => id === active.id);
      const activeTreeItem = clonedItems[activeIndex];

      clonedItems[activeIndex] = { ...activeTreeItem, depth, parentId };

      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);
      const newItems = buildTree(sortedItems);

      setItems(newItems);
    }
  }

  function handleDragCancel() {
    resetState();
  }

  function resetState() {
    setOverId(null);
    setActiveId(null);
    setOffsetLeft(0);
    // setCurrentPosition(null);
    document.body.style.setProperty("cursor", "");
  }

  // function handleRemove(id: string) {
  //   setItems((items) => removeItem(items, id));
  // }

  function handleCollapse(id: string) {
    setItems((items) =>
      setProperty(items, id, "collapsed", (value) => {
        return !value;
      })
    );
  }

  // function getMovementAnnouncement(
  //   eventName: string,
  //   activeId: string,
  //   overId?: string
  // ) {
  //   if (overId && projected) {
  //     if (eventName !== "onDragEnd") {
  //       if (
  //         currentPosition &&
  //         projected.parentId === currentPosition.parentId &&
  //         overId === currentPosition.overId
  //       ) {
  //         return;
  //       } else {
  //         setCurrentPosition({
  //           parentId: projected.parentId,
  //           overId
  //         });
  //       }
  //     }

  //     const clonedItems: FlattenedItem[] = JSON.parse(
  //       JSON.stringify(flattenTree(items))
  //     );
  //     const overIndex = clonedItems.findIndex(({ id }) => id === overId);
  //     const activeIndex = clonedItems.findIndex(({ id }) => id === activeId);
  //     const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);

  //     const previousItem = sortedItems[overIndex - 1];

  //     let announcement;
  //     const movedVerb = eventName === "onDragEnd" ? "dropped" : "moved";
  //     const nestedVerb = eventName === "onDragEnd" ? "dropped" : "nested";

  //     if (!previousItem) {
  //       const nextItem = sortedItems[overIndex + 1];
  //       announcement = `${activeId} was ${movedVerb} before ${nextItem.id}.`;
  //     } else {
  //       if (projected.depth > previousItem.depth) {
  //         announcement = `${activeId} was ${nestedVerb} under ${previousItem.id}.`;
  //       } else {
  //         let previousSibling: FlattenedItem | undefined = previousItem;
  //         while (previousSibling && projected.depth < previousSibling.depth) {
  //           const parentId: string | null = previousSibling.parentId;
  //           previousSibling = sortedItems.find(({ id }) => id === parentId);
  //         }

  //         if (previousSibling) {
  //           announcement = `${activeId} was ${movedVerb} after ${previousSibling.id}.`;
  //         }
  //       }
  //     }

  //     return announcement;
  //   }

  //   return;
  // }
}

const adjustTranslate: Modifier = ({ transform }) => {
  return {
    ...transform,
    y: transform.y - 25
  };
};
