import type from "../types/actionTypes";
import config from "../apiConfig";
import { store } from '../store';
import axiosWrapper from "./axiosWrapper";

export const getSubscriptionPlans = (storeId = null) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = storeId || store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.getSubscriptionPlansUrl.replace('{sId}', sId)}`;
            let resp = await axiosWrapper(url, options, { dispatch, skipOption: { skipSubscriptionStatus: true } });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Subscription Plans Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
}

export const getSubscriptions = (storeId = null) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = storeId || store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.getSubscriptionsUrl.replace('{sId}', sId)}`;
            let resp = await axiosWrapper(url, options, { dispatch, skipOption: { skipSubscriptionStatus: true } });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Subscriptions Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
}

export const getBillAccount = (storeId = null) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = storeId || store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.billingAccountUrl.replace('{sId}', sId)}`;
            let resp = await axiosWrapper(url, options, { dispatch, skipOption: { skipSubscriptionStatus: true } });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error?.response?.data?.code === 'BL_1010') {
                return null;
            } else if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Billing Account Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
}

export const getInvoices = (storeId = null) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = storeId || store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.getInvoiceUrl.replace('{sId}', sId)}`;
            let resp = await axiosWrapper(url, options, { dispatch, skipOption: { skipSubscriptionStatus: true } });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error?.response?.data?.code === 'BL_1010') {
                return null;
            } else if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Invoice Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
}

export const getInvoiceByServiceId = (storeId = null, serviceId) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = storeId || store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.getInvoiceByServiceIdUrl.replace('{sId}', sId).replace('{serviceId}', serviceId)}`;
            let resp = await axiosWrapper(url, options, { dispatch, skipOption: { skipSubscriptionStatus: true } });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error?.response?.data?.code === 'BL_1010') {
                return null;
            } else if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Invoice Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
}

export const getInvoiceById = (storeId = null, invoiceId) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = storeId || store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.getInvoiceByIdUrl.replace('{sId}', sId).replace('{invoiceId}', invoiceId)}`;
            let resp = await axiosWrapper(url, options, { dispatch, skipOption: { skipSubscriptionStatus: true } });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error?.response?.data?.code === 'BL_1010') {
                return null;
            } else if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Invoice By Id Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
}


export const makePayment = (storeId = null) => {
    const options = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = storeId || store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.billMakePaymentUrl.replace('{sId}', sId)}`;
            let resp = await axiosWrapper(url, options, { dispatch, skipOption: { skipSubscriptionStatus: true } });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error?.response?.data?.code === 'BL_1010') {
                return null;
            } else if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Make Payment Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
}

export const downloadInvoice = (storeId = null, invoiceId) => {
    const options = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = storeId || store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.downloadInvoiceUrl.replace('{sId}', sId).replace('{invoiceId}', invoiceId)}`;
            let resp = await axiosWrapper(url, options, { dispatch, skipOption: { skipSubscriptionStatus: true } });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error?.response?.data?.code === 'BL_1010') {
                return null;
            } else if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Invoice By Id Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
}

// export const createSubscription = (rId) => {
//     const options = {
//         method: 'PUT',
//         headers: {
//             'Content-Type': 'application/json',
//             'Authorization': `Bearer ${store.getState().auth.info.token}`
//         }
//     };
//     return async (dispatch) => {
//         try {
//             let url = `${config.baseUrl}${config.createStoreSubscriptionUrl.replace('{rId}', rId)}`;
//             let resp = await axiosWrapper(url, options, { dispatch});
//             return resp && resp.data ? resp.data : null;
//         } catch (error) {
//             if (error.response && error.response.data && error.response.data.message) {
//                 dispatch({
//                     type: type.ERROR,
//                     data: {
//                         title: "Create Subscription Failed",
//                         message: error.response.data.message,
//                         code: error.response.data.code
//                     }
//                 });
//             }
//         }
//     };
// }

export const paymentSuccess = (data) => {
    const options = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        },
        data
    };
    const sId = store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.invoiceMakePaymentSuccessUrl.replace('{sId}', sId)}`;
            let resp = await axiosWrapper(url, options, { dispatch, skipOption: { skipSubscriptionStatus: true } });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Payment Success Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
}

export const paymentFailure = (storeId = null) => {
    const options = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = storeId || store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.invoiceMakePaymentFailureUrl.replace('{sId}', sId)}`;
            let resp = await axiosWrapper(url, options, { dispatch, skipOption: { skipSubscriptionStatus: true } });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Payment Failure Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
}

export const updateBillingAccount = (data) => {
    const options = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        },
        data
    };
    const sId = store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.billingAccountUrl.replace('{sId}', sId)}`;
            let resp = await axiosWrapper(url, options, { dispatch, skipOption: { skipSubscriptionStatus: true } });
            return resp && resp.status === 200;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Create Subscription Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
}


