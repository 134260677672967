import type from "../types/actionTypes";
import config from "../apiConfig";
import { store } from "../store";
import axiosWrapper from "./axiosWrapper";

export const getUsers = () => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.userUrl.replace('{sId}', sId)}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data.users : [];
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Users Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
};

export const getRoles = () => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.rolesUrl.replace('{sId}', sId)}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data.roles : [];
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Search Users Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
};

export const searchUsers = (q) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.searchUserUrl.replace('{sId}', sId).replace('{q}', q)}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data.users : [];
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Search Users Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
};

export const grantAccess = (data) => {
    const options = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        },
        data
    };
    const sId = store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.grantAccessUrl.replace('{sId}', sId)}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Grant Access Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
            return null;
        }
    };
};


// export const createEmployee = (payload) => {
//     const options = {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//             'Authorization': `Bearer ${store.getState().auth.info.token}`
//         },
//         data: payload
//     };
//     const sId = store.getState().ui.selectedStore.id;
//     return async (dispatch) => {
//         try {
//             let url = `${config.baseUrl}${config.employeesUrl.replace('{sId}', sId)}`;
//             let resp = await axiosWrapper(url, options, { dispatch});
//             return resp && resp.data ? resp.data : null;
//         } catch (error) {
//             if (error.response && error.response.data && error.response.data.message) {
//                 dispatch({
//                     type: type.ERROR,
//                     data: {
//                         title: "Create Employee Failed",
//                         message: error.response.data.message
//                     }
//                 });
//             }
//         }
//     };
// };