import type from "../types/actionTypes";
import config from "../apiConfig";
import { store } from '../store';
import { encodeQueryData } from '../helpers/util';
// import { notification } from 'antd';
import dayjs from "dayjs";
import axiosWrapper from "./axiosWrapper";

// const actionNotification = (type, title, message) => {
//     notification[type]({
//         message: title,
//         description: message,
//     });
//     setTimeout(function () {
//         notification.close()
//     }, 1000);
// };

export const exportCustomers = (data) => {
    const options = {
        method: 'POST',
        data,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.exportCustomerUrl.replace('{sId}', sId)}`
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp?.data;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Export Customer Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
            return null;
        }
    };
}

export const getCustomerSearch = (data) => {
    const options = {
        method: 'POST',
        data,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.searchCustomerUrl.replace('{sId}', sId)}`
            let resp = await axiosWrapper(url, options, { dispatch });
            dispatch({
                type: type.SEARCH_CUSTOMERS_SUCCESS,
                data: resp.data
            });
            return resp;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Customer Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
            return null;
        }
    };
}

export const getOrdersByCusomter = (customerId) => {

    let data = {
        orders: [{
            key: 1,
            id: "590ac80f69b6fdd7a45c7696",
            orderNo: "149-38788-01130",
            createdAt: "2017-05-04T06:20:01.130000+00:00",
            netPrice: 400
        }, {
            key: 2,
            id: "57beec2169b6fdff8bd611ed",
            orderNo: "147-21300-85188",
            createdAt: "2016-08-25T13:01:25.188000+00:00",
            netPrice: 1400
        }, {
            key: 3,
            id: "57b09c9569b6fd7c315cfe83",
            orderNo: "147-11923-25849",
            createdAt: "2016-08-25T13:01:25.188000+00:00",
            netPrice: 1200
        }]
    }
    return async (dispatch) => {
        dispatch({
            type: type.GET_ORDERS_BY_CUSTOMER_SUCCESS,
            data
        });
    }

    // let response;
    // const options = {
    //     method: 'GET',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${store.getState().auth.info.token}`
    //     }
    // };
    // return async (dispatch) => {
    //     let url = `${config.baseUrl}${config.getDeliveryexecutives}`;
    //     axios(url, options)
    //         .then(function (resp) {
    //             response = resp;
    //             dispatch({
    //                 type: type.GET_DELIVERY_EXECS_SUCCESS,
    //                 data: response.data
    //             });
    //         })
    //         .catch(function (error) {
    //             if (error.response && error.response.status == 401) {
    //                 dispatch({
    //                     type: type.ERROR,
    //                     data: {
    //                         code: "access_denied",
    //                     }
    //                 });
    //             } else if (error.message) {
    //                 actionNotification('error', 'Get Delivery Execs', error.message);
    //                 dispatch({
    //                     type: type.GET_DELIVERY_EXECS_ERROR,
    //                     error: error.message
    //                 });
    //             }
    //         });
    // }

};

export const getCustomerOrders = (customerId, isFreshList = false) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = store.getState().ui.selectedStore.id;
    const orderLastPageItemTime = isFreshList === false ? (store.getState().customer.orderLastPageItemTime || dayjs().toISOString()) : dayjs().toISOString();
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.customerOrdersUrl.replace('{cusId}', customerId).replace('{lastPageItemTime}', orderLastPageItemTime).replace('{sId}', sId)}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            dispatch({
                type: type.GET_CUSTOMER_ORDERS_SUCCESS,
                data: { customerOrders: resp.data.orders, isFreshList }
            });
            return resp?.data
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Customer Orders Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
            return null;
        }
    };
}

export const getCustomers = (isFreshList = false, queryParams) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    // const customerLastPageItemTime = isFreshList === false ? (store.getState().customer.customerLastPageItemTime || dayjs().toISOString()) : dayjs().toISOString();
    // for (let key in queryParams) {
    //     if (queryParams[key].lastPageItemTime === null) {
    //         queryParams[key].lastPageItemTime = customerLastPageItemTime;
    //     }
    // }
    let queryString = encodeQueryData(queryParams);
    const sId = store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.getCustomersUrl.replace('{sId}', sId)}${queryString}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            dispatch({
                type: type.GET_CUSTOMERS_SUCCESS,
                data: { customers: resp.data.customers, totalCount: resp.data.totalCount, isFreshList }
            });
            return resp?.data;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Customer Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
            return null;
        }
    };
};