import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
// import { EllipsisOutlined } from "@ant-design/icons";
import { getCategories, deleteCategory } from '../../../actions/categoryActions';
// import { getL10N, loadOutletBasedProduct } from "../../../helpers/middleware";
import { Row, Col, Input, Badge } from 'antd';
import '../../../assets/sass/dsk/category.sass';
import SearchIcn from "../../../assets/images/search.svg"
import NoImageAvailableIcn from "../../../assets/images/No-image-available.png"
// import ImageNotLoaded from "../../../assets/images/not-found.png";
// import viewIcn from '../../../assets/images/view.svg';
import deleteIcn from '../../../assets/images/delete.svg';
import MoveTopIcn from '../../../assets/images/sort_up.svg';
// import editIcn from '../../../assets/images/edit.png';
import Save from './Save';
import config from '../../../apiConfig';
import PopupConfirm from '../common/PopupConfirm';
import Loading from '../common/Loading';

// const { Option } = Select;
// const menus = [{
//     icon: editIcn,
//     key: "edit",
//     value: "Edit"
// }, {
//     icon: deleteIcn,
//     key: "remove",
//     value: "Remove"
// }];

type ICategoryProps = {
    id: any,
    mrId: string,
    parent: string,
    category: string,
    description: string,
    title: string
}

interface IProps {
    history?: any;
    getCategories: any;
    deleteCategory: any;
    category: ICategoryProps
}

const ListedCategories = (props: IProps) => {

    const windowHeight = window.innerHeight;
    const [filterCategories, setFilterCategories] = useState([]);
    const [categories, setCategories] = useState([]);
    const [parentTitleSearch, setParentTitleSearch] = useState("");
    const [titleSearch, setTitleSearch] = useState("");
    const [descriptionSearch, setDescriptionSearch] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    // const [categorySearch, setCategorySearch] = useState("");
    const [popupConfirm, setPopupConfirm] = useState(null);
    // const [action, setAction] = useState(null);
    const [isSaveCategory, setIsSaveCategory] = useState(null);
    const { categories: categoryList } = useSelector((state: any) => state.category);
    const { selectedStore } = useSelector((state: any) => state.ui);
    const [category, setCategory] = useState<IProps["category"]>({
        id: null,
        mrId: "",
        parent: "",
        category: "",
        description: "",
        title: ""
    });

    useEffect(() => {
        if (categoryList) {
            setCategories(categoryList);
            setFilterCategories(categoryList)
        }
    }, [categoryList])

    useEffect(() => {
        getCategories();
        //eslint-disable-next-line
    }, [selectedStore])

    const onAction = (categoryInfo, action) => {
        // const { history } = props;
        // if (action == "view") {
        //     history.push({ pathname: "/product/save", state: { product, action } });
        // }
        if (action === "new") {
            setCategory({
                id: null,
                mrId: "",
                parent: "",
                category: "",
                description: "",
                title: ""
            })
        }
        if (action === "edit") {
            setCategory(categoryInfo)
        }
        setIsSaveCategory(true);
    }

    const loadCategory = (data) => {
        // let searchRegExp = /\//g;
        // let replaceWith = ' / ';
        let categoryName = "Main";
        const list = categories.filter((item) => item.category?.toLowerCase() === data.toLowerCase());
        if (list && list.length > 0) {
            categoryName = list[0].title;
        }
        return <Badge key={data} count={categoryName} />;
        // let content = data.replace("/", "").replace(searchRegExp, replaceWith).replace(/\b\w/g, l => l.toUpperCase());
        // return content.length === 0 ? "Main" : <Badge key={data} count={content} />;
    }

    const getCategoryName = (data) => {
        let searchRegExp = /\//g;
        let replaceWith = ' / ';
        let content = data.replace("/", "").replace(searchRegExp, replaceWith).replace(/\b\w/g, l => l.toUpperCase());
        return content.length === 0 ? "/" : content;
    }

    const getCategories = async () => {
        let resp = await props.getCategories();
        if (resp && resp.categories) {
            setCategories(resp.categories);
            setFilterCategories(resp.categories)
        }
    }

    // componentWillReceiveProps(props) {
    //     if (props.product && props.product.categories) {
    //         this.setState({
    //             categories: props.product.categories
    //         });
    //     }
    // if (props.common && props.common.selectedOutlets) {
    //     this.setState({
    //         selectedOutlets: props.common.selectedOutlets
    //     }, () => { this.onFilterCategories() });
    // }
    // }

    //     static getDerivedStateFromProps(nextProps, prevState) {
    //     let updateProps: any = {};
    //     if (nextProps.category.categories) {
    //         updateProps.categories = nextProps.category.categories;
    //         updateProps.filterCategories = nextProps.category.categories;
    //     }
    //     return updateProps;
    // }

    // const onChangeFilter = (evt) => {
    //     const value = evt.target.value;
    //     this.setState({
    //         [evt.target.name]: !value ? "" : value
    //     }, () => this.onFilterCategories());
    // }

    useEffect(() => {
        onFilterCategories();
        //eslint-disable-next-line
    }, [parentTitleSearch, titleSearch, descriptionSearch])

    const onFilterCategories = () => {
        // const { titleSearch, parentTitleSearch, categorySearch, descriptionSearch, categories } = this.state;
        let filterCategoryList = [];
        let isTitle = false;
        let isParentTitle = false;
        let isCategory = false;
        let isDescription = false;
        for (let c of categories) {
            // categories.map((c) => {
            isTitle = false;
            isParentTitle = false;
            isCategory = true;
            isDescription = false;
            if (titleSearch) {
                if (c.title && c.title.toLowerCase().indexOf(titleSearch.toLowerCase()) > -1) {
                    isTitle = true
                }
            } else {
                isTitle = true;
            }
            if (parentTitleSearch) {
                const parentCategory = getCategoryName(c.parent === "/" ? "Main" : c.parent);
                if (parentCategory && parentCategory.toLowerCase().indexOf(parentTitleSearch.toLowerCase()) > -1) {
                    isParentTitle = true
                }
            } else {
                isParentTitle = true
            }
            // if (categorySearch) {
            //     const categoryName = getCategoryName(c.category);
            //     if (categoryName && categoryName.toLowerCase().indexOf(categorySearch.toLowerCase()) > -1) {
            //         isCategory = true
            //     }
            // } else {
            //     isCategory = true
            // }
            if (descriptionSearch) {
                if (c.description && c.description.toLowerCase().indexOf(descriptionSearch.toLowerCase()) > -1) {
                    isDescription = true
                }
            } else {
                isDescription = true;
            }
            if (isTitle && isParentTitle && isCategory && isDescription) {
                filterCategoryList.push(c);
            }
            // });
        }
        setFilterCategories(filterCategoryList)
    }

    const onDeleteCategory = (c) => {
        const title = `Category remove from outlet`;
        setPopupConfirm({
            title,
            renderContent: () => { return <div><span style={{ color: "#000" }}>Do you like to remove <span style={{ fontWeight: "bold" }}>{c.title}</span> category from <span style={{ fontWeight: "bold", textTransform: "uppercase" }}>{selectedStore.name}</span> store.</span></div > },
            yes: {
                label: "Remove Product",
                callback: async () => {
                    setIsLoading(true);
                    const resp = await props.deleteCategory(c.id);
                    if (resp && resp.isDeleted) {
                        getCategories();
                    }
                    setIsLoading(false);
                    setPopupConfirm(null);
                }
            },
            no: {
                label: "May be next time",
                callback: () => { setPopupConfirm(null); }
            }
        });
    }

    // const renderMenu = (data) => {
    //     const content = [];
    //     for (let i in menus) {
    //         content.push(
    //             <div key={menus[i].key}>
    //                 <div style={{ display: "flex", color: this.state.selectMenu == menus[i].key ? "#37a2fd" : "#000000", fontWeight: this.state.selectMenu == menus[i].key ? 800 : 100 }}>
    //                     <div>
    //                         <img src={menus[i].icon} style={{
    //                             padding: "7px",
    //                             width: "22px"
    //                         }} />
    //                     </div>
    //                     <div>
    //                         <p onClick={() => this.onMenuChange(menus[i], data)}>{menus[i].value}</p>
    //                     </div>
    //                 </div>
    //                 <div style={{ textAlign: "center", padding: "0px 8px" }}>
    //                     <div style={{ height: "1px", background: "#CAD1DB", width: "100%" }}></div>
    //                 </div>
    //             </div>
    //         );
    //     }
    //     return content;
    // }
    // onMenuChange = (selectMenu, data) => {
    //     console.log(selectMenu, data)
    //     this.onAction(data, selectMenu.key)
    // }

    const onMoveTop = (index) => {
        let topElement = filterCategories.splice(index, 1)
        setFilterCategories([...topElement, ...filterCategories]);
    }

    return (
        <Row className="category">
            {/* <Col className="list" style={{ height: this.state.windowHeight - 120 }}>
                    <div className="action-bar">
                        <div className="title">
                            Category List
                        </div>
                        <button className="sp_btn" onClick={() => this.onAction(null, "new")} >+ Add Category</button>
                        <button className="sp_btn">Export</button>
                    </div>
                    <div className="items">
                        <Row className="header"> */}
            <Col className="list" style={{ height: windowHeight - 120 }}>
                <div className="action-bar">
                    <div className="title">
                        You've {categories && categories.length} categories listed
                    </div>
                    <div className="actions">
                        <button className="sp_btn" onClick={() => onAction(null, "new")} >+ Add Category</button>
                        {/* <button className="sp_btn">Export</button> */}
                    </div>
                </div>
                <div className="content">
                    <Row className="header">
                        <Col span={5}>
                            Title
                        </Col>
                        <Col span={5}>
                            Parent Title
                        </Col>
                        {/* <Col span={5}>
                            Category
                        </Col> */}
                        <Col span={12}>
                            Description
                        </Col>
                        <Col style={{ textAlign: "center" }} span={2}>
                            Actions
                        </Col>
                    </Row>
                    <Row className="filters">
                        <Col span={5}>
                            <Input className="filter" name="titleSearch" onChange={(e) => setTitleSearch(e.target.value)} placeholder="Title" suffix={<img alt='Search Icon' src={SearchIcn} />} />
                        </Col>
                        <Col span={5}>
                            <Input className="filter" placeholder="Parent Title" name="parentTitleSearch" onChange={(e) => setParentTitleSearch(e.target.value)} suffix={<img alt='Search Icon' src={SearchIcn} />} />
                        </Col>
                        {/* <Col span={5}>
                            <Input className="filter" placeholder="Cateogry" value={categorySearch} name="categorySearch" onChange={(e) => setCategorySearch(e.target.value)} suffix={<img alt='Search Icon' src={SearchIcn} />} />
                        </Col> */}
                        <Col span={12}>
                            <Input className="filter" placeholder="Description" value={descriptionSearch} name="descriptionSearch" onChange={(e) => setDescriptionSearch(e.target.value)} suffix={<img alt='Search Icon' src={SearchIcn} />} />
                        </Col>
                        <Col span={2}>
                        </Col>
                    </Row>
                    <Row className="contains" style={{ height: windowHeight - 284 }}>
                        <Col span={24}>
                            {filterCategories && filterCategories.map((record, index) =>
                                <Row className="item" onClick={() => onAction(record, "edit")} key={record.id} style={{ border: categories.length - 1 <= index ? "borderTop: 1px solid #f1f1f1" : "" }}>
                                    <Col span={5}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {(!record.image) && <img alt='Not Available' src={NoImageAvailableIcn} style={{
                                                marginRight: "10px",
                                                width: "50px",
                                                borderRadius: "3px",
                                                objectFit: 'contain'
                                            }} />}
                                            {record.image &&
                                                <img alt='Category Icon' style={{
                                                    width: "50px",
                                                    height: "50px",
                                                    borderRadius: "4px",
                                                    objectFit: 'fill',
                                                    marginRight: "10px"
                                                }}
                                                    src={`${config.baseImageUrl}${record.image}`} />
                                            }
                                            <div>{record.title}</div>
                                        </div>
                                    </Col>
                                    <Col span={5}>
                                        <div className="categories">{loadCategory(record.parent)}</div>
                                    </Col>
                                    {/* <Col span={5}>
                                        <div className="categories">{loadCategory(record.category)}</div>
                                    </Col> */}
                                    <Col span={12}>
                                        <div className="description">{record.description}</div>
                                    </Col>
                                    <Col span={2}>
                                        <div style={{ fontSize: "14px" }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                                <img alt='Delete Icon' src={deleteIcn} style={{ cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); onDeleteCategory(record) }} />
                                                <img alt='Move Top' src={MoveTopIcn} style={{ display: "none", height: 28, cursor: "pointer", opacity: index !== 0 ? 1 : 0 }} onClick={(e) => { index !== 0 && e.stopPropagation(); onMoveTop(index) }} />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            )}
                        </Col>
                    </Row>
                </div>
            </Col>
            {popupConfirm && <PopupConfirm config={popupConfirm}></PopupConfirm>}
            {isLoading && <Loading background="#00000070"></Loading>}
            <Save onClose={(category) => { setIsSaveCategory(false); setCategory(category); props.getCategories(); }} categoryDetails={category} isVisible={isSaveCategory} />
        </Row >
    )
}

function mapStateToProps(state: any) {
    // console.log("Categories mapStateToProps: ", state);
    return {
        category: state.category,
        auth: state.auth,
        common: state.common,
        ui: state.ui
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getCategories,
        deleteCategory
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ListedCategories);
