import { ArrowLeftOutlined, CaretDownOutlined } from "@ant-design/icons";
import { Input, Select } from "antd";
import React, { useEffect, useState, MouseEvent, TouchEvent } from "react";
import "../../../../assets/sass/dsk/ruleBuilder.sass";
import DeleteSvg from '../../../../assets/images/settings/trash.svg';
import DragSvg from '../../../../assets/images/drag-icon.svg';
import ErrorSvg from '../../../../assets/images/error.svg';
import { getL10N } from "../../../../helpers/middleware";
import {
    DndContext,
    useSensor,
    useSensors,
    DragEndEvent,
    closestCenter,
    MouseSensor as LibMouseSensor, TouchSensor as LibTouchSensor
} from "@dnd-kit/core"
import {
    SortableContext,
    verticalListSortingStrategy
} from "@dnd-kit/sortable"
import SortableItem from "./SortableItem";
import { uuidv4 } from "../../../../helpers/util";

const { Option } = Select;
const DefaultConditionList = [{
    id: "product",
    label: "Product"
}, {
    id: "customerOrderCount",
    label: "Customer Order Count"
}, {
    id: "deviceType",
    label: "Device Type"
}, {
    id: "minOrderValue",
    label: "Minimum Order Value"
}, {
    id: "daysSinceLastOrder",
    label: "Days Since Last Order"
}, {
    id: "price",
    label: "Price"
}, {
    id: "title",
    label: "Title"
}, {
    id: "subtitle",
    label: "Subtitle"
}, {
    id: "stock",
    label: "Stock"
}, {
    id: "salePrice",
    label: "Sale Price"
}, {
    id: "productSku",
    label: "Product SKU"
}];
export const RuleBuilder = (props: any) => {

    const {
        rule: selectedRule,
        products,
        title = null,
        hideGroup = false,
        hideHeader = false,
        errorMessage,
        hideAction = false,
        isNewCondition = false,
        onSave,
        readonly = false,
        onResetNewCondition,
        isValidate = null,
        onResetValidate,
        customConditions = null,
    } = props;
    const [rule, setRule] = useState(null);
    const CombinatorOp = [
        {
            key: "or",
            value: "OR",
        },
        {
            key: "and",
            value: "AND",
        },
        // {
        //     key: "not",
        //     value: "NOT",
        // },
    ];
    const [conditionList, setConditionList] = useState([]);
    useEffect(() => {
        if (customConditions) {
            setConditionList(customConditions);
        } else {
            setConditionList(DefaultConditionList);
        }
        //eslint-disable-next-line
    }, [customConditions]);
    const ConditionTypes = {
        product: [
            {
                field: "id",
                label: "Name",
                type: "array",
                required: true,
                operators: [
                    {
                        label: "=",
                        value: "eq",
                    },
                    // {
                    //     label: "not equal",
                    //     value: "neq",
                    // },
                ],
            },
            {
                field: "quantity",
                label: "Quantity",
                type: "number",
                operators: [
                    {
                        label: ">",
                        value: "gt",
                    },
                    {
                        label: "<",
                        value: "lt",
                    },
                    {
                        label: "<=",
                        value: "lte",
                    },
                    {
                        label: ">=",
                        value: "gte",
                    },
                    // {
                    //     label: "not equal",
                    //     value: "neq",
                    // },
                ],
            },
        ],
        price: {
            field: "price",
            label: "Price",
            type: "number",
            operators: [
                {
                    label: ">",
                    value: "gt",
                },
                {
                    label: "<",
                    value: "lt",
                },
                {
                    label: "<=",
                    value: "lte",
                },
                {
                    label: ">=",
                    value: "gte",
                }
            ],
        },
        salePrice: {
            field: "salePrice",
            label: "Sale Price",
            type: "number",
            operators: [
                {
                    label: ">",
                    value: "gt",
                },
                {
                    label: "<",
                    value: "lt",
                },
                {
                    label: "<=",
                    value: "lte",
                },
                {
                    label: ">=",
                    value: "gte",
                }
            ],
        },
        stock: {
            field: "stock",
            label: "Stock",
            type: "array",
            options: [
                { key: "available", label: "Available" },
                { key: "notAvailable", label: "Not Available" },
            ]
        },
        title: {
            field: "title",
            type: "string",
            label: "Title",
            operators: [
                { value: "contains", label: "Contains" },
                { value: "startsWith", label: "Starts With" },
                { value: "endsWith", label: "Ends With" },
                { value: "eq", label: "Equal" }
            ],
        },
        subtitle: {
            field: "subtitle",
            label: "Subtitle",
            type: "string",
            operators: [
                { value: "contains", label: "Contains" },
                { value: "startsWith", label: "Starts With" },
                { value: "endsWith", label: "Ends With" },
                { value: "eq", label: "Equal" }
            ],
        },
        productSku: {
            field: "productSku",
            label: "Product Sku",
            type: "string",
            operators: [
                { value: "contains", label: "Contains" },
                { value: "startsWith", label: "Starts With" },
                { value: "endsWith", label: "Ends With" },
                { value: "eq", label: "Equal" }
            ],
        },
        customerOrderCount: {
            field: "count",
            label: "Customer Order Count",
            type: "number",
            operators: [
                {
                    label: ">",
                    value: "gt",
                },
                {
                    label: "<",
                    value: "lt",
                },
                {
                    label: "<=",
                    value: "lte",
                },
                {
                    label: ">=",
                    value: "gte",
                },
                // {
                //     label: "not equal",
                //     value: "neq",
                // },
            ],
        },
        minOrderValue: {
            field: "value",
            label: "Minimum Order Value",
            type: "number"
        },
        deviceType: {
            field: "type",
            label: "Device Type",
            options: [
                { key: "android", label: "Android" },
                { key: "ios", label: "iOS" },
                { key: "web", label: "Web" }
            ],
            type: "array"
        },
        daysSinceLastOrder: {
            field: "days",
            label: "Days Since Last Order",
            type: "number",
            operators: [
                {
                    label: ">",
                    value: "gt",
                },
                {
                    label: "<",
                    value: "lt",
                },
                {
                    label: "<=",
                    value: "lte",
                },
                {
                    label: ">=",
                    value: "gte",
                },
                // {
                //     label: "not equal",
                //     value: "neq",
                // },
            ],
        }
    };
    const [isEdit, setIsEdit] = useState(false);
    const [error, setError] = useState(null);
    const [searchProduct, setSearchProduct] = useState(null);
    const [productList, setProductList] = useState(products || []);
    useEffect(() => {
        setIsEdit(!readonly);
        //eslint-disable-next-line
    }, [readonly]);
    useEffect(() => {
        console.log({ selectedRule })
        if (selectedRule) {
            const { conditions } = selectedRule;
            if (conditions) {
                // const conditions = [...conditions];
                conditions.forEach((item, i) => {
                    item.id = uuidv4();
                    if (item.type === "group") {
                        item.conditions.forEach((itemGroup, j) => {
                            itemGroup.id = uuidv4();
                        })
                    }
                });
                setRule({ conditions });
            } else {
                setRule(null);
            }
        } else {
            setRule(null);
        }
        //eslint-disable-next-line
    }, [selectedRule]);

    useEffect(() => {
        if (products) {
            setProductList(products);
        }
        //eslint-disable-next-line
    }, [products]);

    useEffect(() => {
        if (isNewCondition) {
            addNewCondition(null);
            typeof onResetNewCondition === "function" && onResetNewCondition();
        }
        //eslint-disable-next-line
    }, [isNewCondition]);

    useEffect(() => {
        if (isValidate === "initiated") {
            onSubmit();
        }
        //eslint-disable-next-line
    }, [isValidate]);

    // useEffect(() => {
    //     if (onResetValidate && isValidate === "initiated" && error === null) {
    //         typeof onResetValidate === "function" && onResetValidate("completed");
    //     }
    //     //eslint-disable-next-line
    // }, [error]);

    useEffect(() => {
        typeof errorMessage === "function" && errorMessage(null);
        typeof onResetValidate === "function" && onResetValidate(null);
        typeof onResetNewCondition === "function" && onResetNewCondition();
        // console.log("onResetValidate")
        //eslint-disable-next-line
    }, [rule]);

    const onSubmit = () => {
        let errorInfo = null;
        let conditions = [];
        setError(null);
        if (rule && rule?.conditions) {
            conditions = JSON.parse(JSON.stringify(rule.conditions));
            if (conditions?.length > 0) {
                conditions.forEach((condition, i) => {
                    delete condition.id;
                    if (condition.type === "product") {
                        for (let criteria of condition.criteria) {
                            if (criteria.value === null && errorInfo === null) {
                                errorInfo = "Please fill product required fields";
                            }
                        }
                    }
                    if (condition.type === "daysSinceLastOrder" || condition.type === "customerOrderCount" || condition.type === "minOrderValue"
                        || condition.type === "price" || condition.type === "salePrice") {
                        for (let criteria of condition.criteria) {
                            if (criteria.value === null && errorInfo === null) {
                                errorInfo = "Please fill all the required fields";

                            }
                        }
                    }
                    if ((condition.type === "title" || condition.type === "subtitle" || condition.type === "stock" || condition.type === "productSku") && errorInfo === null) {
                        for (let criteria of condition.criteria) {
                            if (criteria.value === "" || criteria.value === null) {
                                errorInfo = "Please fill all the required fields";
                            }
                        }
                    }
                    if (condition.type === "deviceType" && errorInfo === null) {
                        for (let criteria of condition.criteria) {
                            if (criteria.value === null) {
                                errorInfo = "Please fill the deviceType required fields";
                            }
                        }
                    }
                    if (condition.type === "group" && errorInfo === null) {
                        if (condition.conditions.length > 1) {
                            condition.conditions.forEach((groupCondition, i) => {
                                delete groupCondition.id;
                                if (groupCondition.type === "product" && errorInfo === null) {
                                    for (let criteria of groupCondition.criteria) {
                                        if (criteria.value === null) {
                                            errorInfo = "Please fill the product required fields";
                                        }
                                    }
                                }
                                if ((groupCondition.type === "title" || groupCondition.type === "subtitle" || groupCondition.type === "stock" || groupCondition.type === "productSku"
                                    || groupCondition.type === "salePrice" || groupCondition.type === "price" || groupCondition.type === "customerOrderCount"
                                    || groupCondition.type === "minOrderValue" || groupCondition.type === "daysSinceLastOrder") && errorInfo === null) {
                                    for (let criteria of groupCondition.criteria) {
                                        if (criteria.value === null) {
                                            errorInfo = "Please fill all the required fields";
                                        }
                                    }
                                }
                                if (groupCondition.type === "deviceType" && errorInfo === null) {
                                    for (let criteria of groupCondition.criteria) {
                                        if (criteria.value === null) {
                                            errorInfo = "Please fill the deviceType required fields";
                                        }
                                    }
                                }
                            })
                        } else {
                            errorInfo = "In Group condition, atleast two conditions are required";
                        }
                    }
                })
            } else {
                errorInfo = "Please add atleast one condition";
            }
        } else {
            errorInfo = "Please add atleast one condition";
        }
        setError(errorInfo);
        typeof errorMessage === "function" && errorMessage(errorInfo);
        if (onSave && typeof onSave === "function" && !errorInfo) {
            onSave({ ...rule, conditions }, "completed");
            setRule(null);
        }
    }

    const onSearchProduct = () => {
        let newList = [];
        if (products) {
            for (let product of products) {
                const title = getL10N(product, "title", null).i10n;
                if (
                    searchProduct === null ||
                    searchProduct.length === 0 ||
                    title.toLowerCase().indexOf(searchProduct.toLowerCase()) > -1
                ) {
                    newList.push(product);
                }
            }
        }
        setProductList(newList);

    };

    useEffect(() => {
        onSearchProduct();
        //eslint-disable-next-line
    }, [searchProduct]);

    const onRemoveCondition = (item: any) => {
        const conditions = [...rule.conditions];
        const { parentIndex, childIndex } = findIndexing(conditions, item.id);
        if (childIndex !== null) {
            conditions[parentIndex].conditions.splice(childIndex, 1);
            if (conditions[parentIndex].conditions) {
                if (conditions[parentIndex].conditions.length === 0) {
                    conditions.splice(parentIndex, 1);
                }
            }
        } else {
            conditions.splice(parentIndex, 1);
        }
        setRule({ ...rule, conditions });
    }
    const onUpdateCondition = (item: any, value: any, key: string, fieldDesc: any = null) => {
        // console.log("onUpdateCondition")
        const conditions = [...rule.conditions];
        const { parentIndex, childIndex } = findIndexing(conditions, item.id);
        if (childIndex !== null) {
            if (fieldDesc?.type === "product") {
                for (let condition of conditions[parentIndex].conditions[childIndex].criteria) {
                    if (condition.field === fieldDesc.field) {
                        condition.value = value;
                    }
                }
            } else if (key === "type") {
                conditions[parentIndex].conditions[childIndex][key] = value;
                if (value === "product") {
                    conditions[parentIndex].conditions[childIndex].criteria = [{
                        field: "id",
                        operator: "eq",
                        value: null,
                    }, {
                        field: "quantity",
                        operator: "gt",
                        value: null,
                    }];
                } else {
                    conditions[parentIndex].conditions[childIndex].criteria = [{
                        field: ConditionTypes[value].field,
                        operator: ConditionTypes[value].operators && ConditionTypes[value].operators.length > 0 && ConditionTypes[value].operators[0].value ? ConditionTypes[value]?.operators[0]?.value : 'eq',
                        value: null
                    }];
                }
            } else if (key === "operator") {
                conditions[parentIndex].conditions[childIndex].criteria[0].operator = value;
            } else if (key === "number" || key === "text" || key === "array") {
                conditions[parentIndex].conditions[childIndex].criteria[0].value = value;
            } else {
                conditions[parentIndex].conditions[childIndex][key] = value;
            }
        } else {
            if (fieldDesc?.type === "product") {
                for (let condition of conditions[parentIndex].criteria) {
                    if (condition.field === fieldDesc.field) {
                        condition.value = value;
                    }
                }
            } else if (key === "type") {
                conditions[parentIndex][key] = value;
                if (value === "product") {
                    conditions[parentIndex].criteria = [{
                        field: "id",
                        operator: "eq",
                        value: null,
                    }, {
                        field: "quantity",
                        operator: "gt",
                        value: null,
                    }];
                } else {
                    conditions[parentIndex].criteria = [{
                        field: ConditionTypes[value].field,
                        operator: ConditionTypes[value].operators && ConditionTypes[value].operators.length > 0 && ConditionTypes[value].operators[0].value ? ConditionTypes[value]?.operators[0]?.value : 'eq',
                        value: null
                    }];
                }
            } else if (key === "operator") {
                conditions[parentIndex].criteria[0].operator = value;
            } else if (key === "number" || key === "array" || key === "text") {
                conditions[parentIndex].criteria[0].value = value;
            } else {
                conditions[parentIndex][key] = value;
            }
        }
        setRule({ ...rule, conditions });
    }

    const createConditionType = (item: any) => {
        const { type } = item;
        return <Select disabled={!isEdit} className="condition-type" value={type}
            onChange={(val) => {
                onUpdateCondition(item, val, "type");
            }}>
            {conditionList.map((c: any) => {
                return <Option value={c.id}>{c.label}</Option>
            })}
        </Select>
    }

    const createTextInput = (selectedVal: any, item: any, field: any) => {
        return <Input className="value" style={{ width: 100, height: 40 }} type="text"
            onChange={(e) => {
                const { value }: any = e.target;
                onUpdateCondition(item, value || "", "text", field);
            }} disabled={!isEdit} value={selectedVal} />
    }

    const createNumberInput = (selectedVal: any, item: any, field: any) => {
        return <Input className="value" style={{ width: 100, height: 40 }} type="number"
            onChange={(e) => {
                const { value }: any = e.target;
                // const reg = /^-?\d*(\.\d*)?$/;
                // if ((!isNaN(value) && reg.test(value)) || value === "" || value !== "-") {
                // console.log(parseInt(value || 1));
                onUpdateCondition(item, parseInt(value), "number", field);
                // }
            }} disabled={!isEdit} value={selectedVal} />
    }

    const createArrayInput = (selectedVal: any, options: any, item: any, field: any) => {
        return <Select className="array-selection" disabled={!isEdit} value={selectedVal} onChange={(val) => {
            onUpdateCondition(item, val, "array", field);
        }}>
            {options.map((c: any) => {
                return <Option value={c.key}>{c.label}</Option>
            })}
        </Select>
    }

    const createOperator = (selectedOp: any, operators: any, item: any) => {
        return <Select disabled={!isEdit} value={selectedOp} className="operators" onChange={(val) => {
            onUpdateCondition(item, val, "operator");
        }}>
            {operators.map((c: any) => {
                return <Option key={c.key} value={c.value}>{c.label}</Option>
            })}
        </Select>
    }

    const createCombinator = (item: any) => {
        return <div className="combinator" style={{ marginTop: item.type === "group" ? 29 : 22, marginLeft: item.type === "group" ? -24 : isEdit ? -28 : -11 }}>
            <Select disabled={!isEdit} value={item.combinator} className="op" onChange={(val) => {
                onUpdateCondition(item, val, "combinator");
            }}>
                {CombinatorOp.map((c: any) => {
                    return <Option key={c.key} value={c.key}>{c.value}</Option>
                })}
            </Select>
        </div>
    }

    const createCondition = (item: any) => {
        const { operators, type, options } = ConditionTypes[item.type];
        // console.log("createCondition", item)
        const { criteria } = item;
        let selectedVal = null;
        let selectedOp = null;
        let compDesc = {
            type: item.type,
        }
        if (criteria.length > 0) {
            selectedOp = criteria[0].operator;
            selectedVal = criteria[0].value;
            compDesc["field"] = criteria[0].field;
        }
        return <div className="condition-operator">
            {createConditionType(item)}
            {operators && createOperator(selectedOp, operators, item)}
            {type === "string" && createTextInput(selectedVal, item, compDesc)}
            {type === "number" && createNumberInput(selectedVal, item, compDesc)}
            {type === "array" && createArrayInput(selectedVal, options, item, compDesc)}
        </div>
    }

    const createProductCondition = (item: any) => {
        return <div className="product">
            {item.criteria.map((criteria: any, cIndex) => {
                return <div className="criteria" key={`criteria_${cIndex}`}>
                    {criteria.field === 'id' && <div className='type'>
                        <label>Product</label>
                        <Select
                            style={{ width: 340 }}
                            showSearch={criteria.value ? false : true}
                            suffixIcon={
                                <CaretDownOutlined />
                            }
                            disabled={!isEdit}
                            className="add-product"
                            placeholder="Select to add product"
                            filterOption={false}
                            // mode="tags" maxTagCount={1}
                            value={criteria.value}
                            onSearch={(value: string) => {
                                setSearchProduct(value);
                            }}
                            onChange={(val) => {
                                onUpdateCondition(item, val, "array", { field: criteria.field, type: item.type });
                            }}
                        >
                            {productList.map((p) => {
                                return (
                                    <Option key={p.id} value={p.id}>
                                        <div
                                            style={{
                                                fontSize: 14,
                                                color: "#121212",
                                                fontWeight: 900,
                                                textTransform: "uppercase",
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis"
                                            }}
                                        >
                                            {getL10N(p, "title", null).i10n}
                                        </div>
                                    </Option>
                                );
                            })}
                        </Select></div>}
                    {criteria.field === 'quantity' && <div className='type'>
                        <label>Quantity</label>
                        {createNumberInput(criteria.value, item, { field: criteria.field, type: item.type })}
                    </div>}
                </div>
            })}
        </div>
    }
    const createConditionTemplate = (item: any, isFinalItem: boolean, isDragging: boolean) => {
        return item.type === "group" ? <div className="condition-group" style={{ marginBottom: (!isFinalItem && item.combinator) ? '60px' : '10px' }}>
            <div className="drag touch-action" style={{ marginRight: 6 }}>
                <img src={DragSvg} alt="drag" style={{ height: 16 }} />
            </div>
            <div style={{ width: '100%' }}>
                {iterateConditions(item.conditions)}
                {!isFinalItem && item.combinator && createCombinator(item)}
                <div className="add-condition-inside" data-no-dnd="true" onClick={() => addNewCondition(item)}>+ Add Condition</div>
            </div>
        </div>
            : <div className="item" style={{ marginBottom: (!isFinalItem && item.combinator) ? '60px' : '10px' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {isEdit && <div className="drag touch-action">
                        <img src={DragSvg} alt="drag" />
                    </div>}
                    <div data-no-dnd="true">
                        {item.type === "product" && createConditionType(item)}
                        {item.type === "product" && createProductCondition(item)}
                        {(item.type === "title" || item.type === "subtitle" || item.type === "stock" || item.type === "productSku"
                            || item.type === "deviceType" || item.type === "minOrderValue" || item.type === "customerOrderCount"
                            || item.type === "price" || item.type === "salePrice" || item.type === "daysSinceLastOrder") && createCondition(item)}
                        {!isFinalItem && item.combinator && createCombinator(item)}
                    </div>
                </div>
                {isEdit && <div className="delete" data-no-dnd="true" onClick={() => onRemoveCondition(item)}>
                    <img src={DeleteSvg} alt="delete" />
                </div>}
            </div>
    }
    const iterateConditions = (conditions: any) => {
        return conditions.map((item: any, index: number) => {
            const isFinalItem = conditions.length - 1 === index
            return <SortableItem
                key={item.id}
                item={item}
                createConditionTemplate={createConditionTemplate}
                isFinalItem={isFinalItem}
            />
        });
    }
    const findIndexing = (conditions: any, id: any) => {
        let childIndex = null;
        let parentIndex = null;
        conditions.forEach((item, i) => {
            if (item.id === id) {
                parentIndex = i;
            }
            if (item.type === "group") {
                item.conditions.forEach((itemGroup, j) => {
                    if (itemGroup.id === id) {
                        parentIndex = i;
                        childIndex = j;
                    }
                })
            }
        });
        return { childIndex, parentIndex };
    }
    const onDefaultCondition = () => {
        const defaultType = customConditions ? customConditions[0].id : "product";
        const defaultCriteria: any = ConditionTypes[defaultType];
        let conditions = {
            id: uuidv4(),
            type: defaultType,
            combinator: "and",
            criteria: []
        };
        if (defaultCriteria?.length > 0) {
            defaultCriteria.forEach((item, i) => {
                conditions.criteria.push({
                    field: item.field,
                    operator: item.operators[0].value,
                    value: null
                });
            });
        } else {
            conditions.criteria.push({
                field: defaultCriteria.field,
                operator: defaultCriteria.operators[0].value,
                value: null
            });
        }
        return conditions;
    }
    const addNewCondition = (item: any = null) => {
        let conditions = [];
        let defaultConditions = null;
        if (rule?.conditions) {
            conditions = [...rule?.conditions];
        }
        defaultConditions = onDefaultCondition();
        if (item && item?.id) {
            const { parentIndex } = findIndexing(conditions, item?.id);
            if (parentIndex !== null) {
                conditions[parentIndex].conditions.push(defaultConditions);
            } else {
                conditions.push(defaultConditions);
            }
        } else {
            conditions.push(defaultConditions);
        }
        if (rule) {
            setRule({ ...rule, conditions });
        } else {
            setRule({ ...{}, conditions });
        }
    }
    const addNewGroup = () => {
        let conditions = [];
        if (rule?.conditions) {
            conditions = [...rule?.conditions];
        }
        let defaultConditions = onDefaultCondition();
        conditions.push({
            id: uuidv4(),
            type: "group",
            combinator: "and",
            conditions: [defaultConditions]
        });
        setRule({ ...rule, conditions });
    }

    // const [activeItem, setActiveItem] = useState(null);
    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor))
    // const handleDragStart = (event: DragStartEvent) => {
    //     const { active } = event;
    //     const { conditions } = rule;
    //     const { parentIndex: activeParentIndex, childIndex: activeChildIndex } = findIndexing(conditions, active.id);
    //     if (activeChildIndex !== null) {
    //         setActiveItem(conditions[activeParentIndex].conditions[activeChildIndex])
    //     } else {
    //         setActiveItem(conditions[activeParentIndex])
    //     }
    // }
    // useEffect(() => {
    //     console.log("activeItem", activeItem)
    // }, [activeItem])
    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event
        // console.log({ active, over })
        const { conditions } = rule;
        if (!over) return
        const { parentIndex: activeParentIndex, childIndex: activeChildIndex } = findIndexing(conditions, active.id);
        const { parentIndex: overParentIndex, childIndex: overChildIndex } = findIndexing(conditions, over.id);
        // console.log({ activeParentIndex, activeChildIndex, overParentIndex, overChildIndex })
        if (activeChildIndex !== null && overChildIndex !== null && activeParentIndex === overParentIndex) {
            const swapItem = conditions[activeParentIndex].conditions[activeChildIndex];
            setRule((prev) => {
                const newConditions = [...prev.conditions];
                newConditions[activeParentIndex].conditions.splice(activeChildIndex, 1);
                newConditions[overParentIndex].conditions.splice(overChildIndex, 0, swapItem);
                return { ...prev, conditions: newConditions };
            })
        }
        if (activeParentIndex !== null && overParentIndex !== null && activeParentIndex !== overParentIndex) {
            const swapItem = conditions[activeParentIndex];
            setRule((prev) => {
                const newConditions = [...prev.conditions];
                newConditions.splice(activeParentIndex, 1);
                newConditions.splice(overParentIndex, 0, swapItem);
                return { ...prev, conditions: newConditions };
            })
        }
        // setActiveItem(undefined)
    }
    // const renderActiveItem = (item) => {
    //     // const itemIds = items.map((item) => item.id)
    //     // alert(itemIds)
    //     return <div className="item glass" style={{ marginBottom: 10, position: 'absolute', width: "100%", height: item.type === "product" ? "160px" : "40px", padding: "10px 0px" }}>
    //         <div style={{ display: 'flex', alignItems: 'center' }}>
    //             {isEdit && <div className="drag touch-action">
    //                 <img src={DragSvg} alt="drag" />
    //             </div>}
    //             <div data-no-dnd="true">
    //                 {item.type === "product" && createConditionType(item)}
    //                 {item.type === "product" && createProductCondition(item)}
    //                 {(item.type === "deviceType" || item.type === "minOrderValue" || item.type === "customerOrderCount") && createCondition(item)}
    //             </div>
    //         </div>
    //         {isEdit && <div className="delete" data-no-dnd="true" onClick={() => onRemoveCondition(item)}>
    //             <img src={DeleteSvg} alt="delete" />
    //         </div>}
    //     </div>
    // }
    const onClose = () => {
        setRule(null);
        setError(null);
        props.onClose();
    }
    // useEffect(() => {
    //     console.log("rule::::::::::::", rule)
    // }, [rule])
    return <div className="rule-builder">
        <div className="wrapper">
            {!hideHeader && <div className="header">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ArrowLeftOutlined className="arrow" onClick={onClose}></ArrowLeftOutlined>
                    <div className="title">{title ? title : "Rule Builder"}</div>
                </div>
                {error && <div className="error">
                    <img src={ErrorSvg} alt="error" />
                    <span>{error}</span>
                </div>}
                <div style={{ display: 'flex' }}>
                    <div className="sp_btn" onClick={onClose} style={{ boxShadow: 'none', width: 120, fontSize: 14, marginRight: 10, border: '1px solid #121212', color: "#000", background: "#fff" }}>Cancel</div>
                    {!isEdit && <div className="sp_btn" onClick={() => { setIsEdit(true) }} style={{ width: 120, fontSize: 14 }}>Edit</div>}
                    {isEdit && <div className="sp_btn" onClick={onSubmit} style={{ width: 164, fontSize: 14 }}>{selectedRule ? "Update Rule" : "Create Rule"}</div>}
                </div>
            </div>}
            <div className="body">
                <div className="conditions" style={{ maxHeight: window.innerHeight - 160 }}>
                    <div className="when">When</div>
                    {rule?.conditions && <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        // onDragStart={handleDragStart}
                        onDragEnd={handleDragEnd}
                    >
                        <SortableContext items={rule.conditions} strategy={verticalListSortingStrategy}>
                            {iterateConditions(rule.conditions)}
                        </SortableContext>
                        {/* <DragOverlay adjustScale style={{ transformOrigin: "0 0 " }}>
                            {activeItem ? <div>{renderActiveItem(activeItem)}</div> : null}
                        </DragOverlay> */}
                    </DndContext>}
                </div>
                {!hideAction && <div className="action">
                    <div className="add-condition" onClick={() => addNewCondition(null)}>Add Condition</div>
                    {!hideGroup && <div className="add-group" onClick={addNewGroup}>Add Group</div>}
                </div>}
            </div>
        </div>
    </div>
};

// Block DnD event propagation if element have "data-no-dnd" attribute
const handler = ({ nativeEvent: event }: MouseEvent | TouchEvent) => {
    let cur = event.target as HTMLElement;

    while (cur) {
        if (cur.dataset && cur.dataset.noDnd) {
            return false;
        }
        cur = cur.parentElement as HTMLElement;
    }

    return true;
};

export class MouseSensor extends LibMouseSensor {
    static activators = [{ eventName: 'onMouseDown', handler }] as typeof LibMouseSensor['activators'];
}

export class TouchSensor extends LibTouchSensor {
    static activators = [{ eventName: 'onTouchStart', handler }] as typeof LibTouchSensor['activators'];
}