import type from "../types/actionTypes";
import config from "../apiConfig";
import { store } from '../store';
import axiosWrapper from "./axiosWrapper";

export const getNavMenus = () => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.getNavigationMenusUrl.replace('{sId}', sId)}`
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Navigation Menus Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
};

export const getNavMenusById = (menuId) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.getNavigationMenuByIdUrl.replace('{sId}', sId).replace('{menuId}', menuId)}`
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Navigation Menu Detail Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
};

export const getNavMenuItems = (menuId) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.getMenuItemsUrl.replace('{sId}', sId).replace('{menuId}', menuId)}`
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Navigation Menu Items Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
};

export const onCreateMenu = (data) => {
    const options = {
        method: 'POST',
        data,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.createMenuUrl.replace('{sId}', sId)}`
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Add New Navigation Menu Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
                return { error: error.response.data }
            }
        }
    };
}

export const onOrderMenuItems = (data, menuId) => {
    const options = {
        method: 'PUT',
        data,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.updateOrderMenuItemsUrl.replace('{sId}', sId).replace('{menuId}', menuId)}`
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Update Order Menu Items Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
                return { error: error.response.data }
            }
        }
    };
}


export const onCreateMenuItem = (data, menuId) => {
    const options = {
        method: 'POST',
        data,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.createMenuItemUrl.replace('{sId}', sId).replace('{menuId}', menuId)}`
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Add New Menu Items Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
                return { error: error.response.data }
            }
        }
    };
}

export const onUpdateMenu = (data, menuId) => {
    const options = {
        method: 'PUT',
        data,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.navigationMenuUrl.replace('{sId}', sId).replace('{menuId}', menuId)}`
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Update Menu Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
                return { error: error.response.data }
            }
        }
    };
}

export const onUpdateMenuItems = (data, menuId, itemId) => {
    const options = {
        method: 'PUT',
        data,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.navigationMenuItemUrl.replace('{sId}', sId).replace('{menuId}', menuId).replace('{itemId}', itemId)}`
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Update Menu Items Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
                return { error: error.response.data }
            }
        }
    };
}

export const deleteMenu = (menuId) => {
    const options = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.navigationMenuUrl.replace('{sId}', sId).replace('{menuId}', menuId)}`
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Delete Navigation Menu Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
                return { error: error.response.data }
            }
        }
    };
}

export const onDeleteMenuItems = (menuId, itemId) => {
    const options = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.navigationMenuItemUrl.replace('{sId}', sId).replace('{menuId}', menuId).replace('{itemId}', itemId)}`
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Delete Navigation Menu Item Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
                return { error: error.response.data }
            }
        }
    };
}
