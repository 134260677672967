import React, { useEffect, useState, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
// import { getL10N, loadOutletBasedProduct } from "../../../helpers/middleware";
import { Row, Col, Select } from 'antd';
import '../../../assets/sass/dsk/order.sass';
// import ExportSvg from "../../../assets/images/export.svg";
// import ArrowSvg from "../../../assets/images/downArrow.svg";
import ExportSvg from "../../../assets/images/export.svg";
import { getPendingOrders, downloadPendingOrders } from "../../../actions/orderActions";
import { pendingOrderFilterOptions as onOrderFilterOptions } from "../../../actions/uiAction";
import { getOutletSlot } from "../../../actions/outletAction";
import { selectedOutlets } from '../../../actions/commonAction';
import dayjs from "dayjs";
import { CONSTANTS } from '../../../constants';
import QueryFilter from '../common/QueryFilter';
import Loading from '../common/Loading';
import PopupConfirm from '../common/PopupConfirm';
import { useNav } from '../../../helpers/customHook';


// const { RangePicker } = DatePicker;
const { Option } = Select;
// const { RangePicker } = DatePicker;
const conditions = [
    { key: "orderNo", value: "Order No" },
    { key: "any", value: "Any" }
];

const ReportTypes = [
    { key: 'orderSummary', value: 'Order Summary' },
    { key: 'orderItems', value: 'Order Items' },
];

const PendingOrders = (props: any) => {
    const navigateTo = useNav();
    let sDate = dayjs().subtract(6, 'd');
    let eDate = dayjs();
    const { outlets } = useSelector((state: any) => state.outlet);
    const [selectedOuId, setSelectedOuId] = useState(null);
    const [orderList, setOrderList] = useState([]);
    const { pendingOrderFilterOptions: orderFilterOptions } = useSelector((state: any) => state.ui);
    // const [selectedDate, setSelectedDate]: any = useState([sDate, eDate]);
    const selectedDate = [sDate, eDate];
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [queries, setQueries] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [popupConfirm, setPopupConfirm] = useState(null);
    const reportType = useRef(ReportTypes[0].key);

    useEffect(() => {
        if (orderFilterOptions) {
            setQueries(orderFilterOptions.query);
            setSelectedOuId(orderFilterOptions.ouId);
            setQueries(orderFilterOptions.query);
            // if (orderFilterOptions.date && orderFilterOptions.date.length > 1) {
            //     setSelectedDate([dayjs(orderFilterOptions.date[0]), dayjs(orderFilterOptions.date[1])])
            // }
            getPendingOrders();
        }
        //eslint-disable-next-line
    }, [orderFilterOptions])

    useEffect(() => {
        if (orderFilterOptions) {
            setQueries(orderFilterOptions.query);
            setSelectedOuId(orderFilterOptions.ouId);
            setQueries(orderFilterOptions.query);
            // if (orderFilterOptions.date && orderFilterOptions.date.length > 1) {
            //     setSelectedDate([dayjs(orderFilterOptions.date[0]), dayjs(orderFilterOptions.date[1])])
            // }
            getPendingOrders();
        }
        //eslint-disable-next-line
    }, [])

    // const onChangeDate = (dayjsDate, dateString) => {
    //     if (dateString.length > 1) {
    //         props.onOrderFilterOptions({ ...orderFilterOptions, date: dateString })
    //     }
    // }

    const getPendingOrders = async () => {
        setIsLoading(true);
        const queryParams = generateQueryParams();
        const resp = await props.getPendingOrders(queryParams);
        if (resp && resp.orders) {
            setOrderList(resp.orders)
        }
        setIsLoading(false);
    }

    const generateQueryParams = () => {
        let queryFilters: any = {};
        if (orderFilterOptions.query) {
            for (let q of orderFilterOptions.query) {
                if (!queryFilters[q.fId]) {
                    queryFilters[q.fId] = []
                }
                queryFilters[q.fId].push(q.fValue);
            }
        }
        let queryParams: any = {};
        queryParams['startDate'] = selectedDate[0].startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss.SSS');
        queryParams['endDate'] = selectedDate[1].endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss.SSS');
        if (orderFilterOptions.ouId && orderFilterOptions.ouId !== 'all') {
            queryParams['ouId'] = orderFilterOptions.ouId;
        }
        if (queryFilters.orderNo && queryFilters.orderNo.length > 0) {
            queryParams['orderNo'] = queryFilters.orderNo[0];
        }
        if (queryFilters.any && queryFilters.any.length > 0) {
            queryParams['query'] = queryFilters.any[0];
        }
        return queryParams;
    }

    const downloadPendingOrders = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setPopupConfirm({
            title: "Select report type",
            renderContent: () => {
                return <Select defaultValue={reportType.current} placeholder="Report type" onChange={(val: string) => { reportType.current = val }}  >
                    {ReportTypes.map((opt) => {
                        return <Option key={opt.key} value={opt.key}>{opt.value}</Option>
                    })}
                </Select>
            },
            yes: {
                label: "Download",
                callback: async () => {
                    const queryParams = generateQueryParams();
                    queryParams['excel'] = true;
                    queryParams['reportType'] = reportType.current;
                    const blob = await props.downloadPendingOrders(queryParams);
                    if (blob) {
                        const link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = `${new Date().toLocaleDateString()}_payment_failure_orders.xlsx`;
                        document.body.append(link);
                        link.click();
                        link.remove();
                        setTimeout(() => URL.revokeObjectURL(link.href), 7000);
                    }
                    setPopupConfirm(null);
                }
            },
            no: {
                label: "Cancel",
                callback: () => { setPopupConfirm(null); }
            }
        });
    }

    return (
        <Row className="order">
            <Col className="list" span={24}>
                {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '0px 16px' }}>
                    <div className="delivery-slots">
                        <div onClick={() => setSelectedSlot('all')} className={selectedSlot === 'all' ? 'slot selected' : 'slot'}>{'All'}</div>
                        {deliverySlots && deliverySlots.map((slot) => {
                            return <div key={slot.id} onClick={() => setSelectedSlot(slot.id)} className={selectedSlot === slot.id ? 'slot selected' : 'slot'}>{slot.name}</div>
                        })}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', marginRight: 16 }}><img src={ExportSvg} style={{ marginRight: 4 }} /><div style={{ marginTop: 2 }}>Procurement</div></div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', marginRight: 10 }}><img src={ExportSvg} style={{ marginRight: 4 }} /> <div style={{ marginTop: 2 }}>Export</div></div>
                    </div>
                </div> */}
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: '#f5f5f5', padding: '0px 16px' }}>
                    <div className="action-bar">
                        {/* <div className="title">
                        You've {orders && orders.length} orders listed
                    </div> */}
                        <QueryFilter onSelectedFilters={(val) => props.onOrderFilterOptions({ ...orderFilterOptions, query: val })} defaultValue={queries} properties={conditions} queries={queries}></QueryFilter>
                        {/* <div className="date-range" style={{ display: "none" }}>
                            <RangePicker defaultValue={selectedDate} value={selectedDate} onChange={(dates, dateStrings) => onChangeDate(dates, dateStrings)} />
                        </div> */}
                    </div>
                    <div style={{ width: 300, display: 'flex', alignItems: 'center', }}>
                        <Select
                            className="select"
                            size="middle"
                            value={selectedOuId}
                            defaultValue={selectedOuId}
                            onChange={(value) => { props.onOrderFilterOptions({ ...orderFilterOptions, ouId: value }) }}
                        >
                            <Option key="all_outlets" style={{ paddingLeft: '16px' }} value="all">
                                <label htmlFor={"all_outlets"} style={{ paddingLeft: "16px" }}>{"All"}</label>
                            </Option>
                            {outlets && outlets.map((item) => {
                                return <Option key={item.id} style={{ paddingLeft: '16px' }} value={item.id}>
                                    <label htmlFor={item.id} style={{ paddingLeft: "16px" }}>{item.name}</label>
                                </Option>
                            })}
                        </Select>
                    </div>
                    <div style={{ display: 'none', alignItems: 'center', marginLeft: 4 }}>
                        <div style={{ cursor: 'pointer', margin: "0px 10px" }}><img alt='Export' src={ExportSvg} onClick={downloadPendingOrders} style={{ marginRight: 4 }} title={"Export"} /> </div>
                    </div>
                </div>
            </Col>

            <Col span={24}>
                <div className='orders'>
                    <Row className='header'>
                        <Col span={4}>
                            Order ID
                        </Col>
                        <Col span={4}>
                            Customer
                        </Col>
                        {/* <Col span={2}>
                            No of order items
                        </Col> */}
                        <Col span={5}>
                            Delivery Area
                        </Col>
                        <Col span={2}>
                            Payment Mode
                        </Col>
                        <Col span={3}>
                            Total Amount
                        </Col> <Col span={3}>
                            Order Date
                        </Col>
                        <Col span={3}>
                            Order Status
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div style={{ height: 1, background: '#D8DEE6', margin: "0px 15px" }}></div>
                        </Col>
                    </Row>
                    <div className='details'>
                        {/* <Col span={24}> */}
                        {(!orderList || orderList.length === 0) && <div className='no-data-found'>No orders found</div>}
                        {orderList.map((order) => {
                            return <Row onClick={() => navigateTo(`details/${order.id}`)} key={order.id} style={{
                                background: selectedOrder && selectedOrder.id === order.id ? '#CBCBCB33' : '#FFF', borderRadius: 4
                            }}>
                                <Col span={24}>
                                    <Row style={{ cursor: 'pointer', height: 40, display: 'flex', alignItems: 'center' }} onClick={() => { !selectedOrder || selectedOrder.id !== order.id ? setSelectedOrder(order) : setSelectedOrder(null) }} >
                                        <Col span={4}>
                                            <div style={{ display: 'flex' }}>
                                                {/* <img
                                                    alt='Order'
                                                    style={{ marginTop: -4, marginLeft: -24, cursor: 'pointer', transition: 'transform 1s', transform: `rotate(${selectedOrder && selectedOrder.id === order.id ? '0' : '-90'}deg)` }}
                                                    src={ArrowSvg} /> */}
                                                <div>#{order.orderNo}</div>
                                            </div>
                                        </Col>
                                        {/* style={{ paddingLeft: 14 }} */}
                                        <Col span={4}>
                                            {order.customer.fullName}
                                        </Col>
                                        {/* style={{ paddingLeft: 6 }} */}
                                        {/* <Col span={2}>
                                            {order.items.length}
                                        </Col> */}
                                        <Col span={5}>
                                            {order.pickup ? <div style={{ color: "#ff4800" }}>Outlet Pickup</div> : <div>
                                                {order.shippingAddress?.area}
                                            </div>}
                                        </Col>
                                        <Col span={2}>
                                            {order.payment && order.payment.mode.toUpperCase()}
                                        </Col>
                                        <Col span={3}>
                                            ₹{order?.billing?.toPay?.toLocaleString('en-IN')}
                                        </Col> <Col span={3}>
                                            {dayjs(order.createdAt).format('ddd')}, {dayjs(order.createdAt).format('MMM')} {dayjs(order.createdAt).format('DD')},{dayjs(order.createdAt).format('YYYY')}, {dayjs(order.createdAt).format('hh:mm A')}
                                        </Col>
                                        <Col span={3}>
                                            <div style={{ color: CONSTANTS.OrderStatus.colors[order.status] }}>
                                                {CONSTANTS.OrderStatus.labels[order.status] || order.status}
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        })}
                        {/* </Col> */}
                    </div>
                </div>
                {orderList.length > 0 && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 800, fontSize: 16, marginTop: 8 }}>Total Orders {orderList.length}</div>}
            </Col>
            {popupConfirm && <PopupConfirm config={popupConfirm}></PopupConfirm>}
            {isLoading && <Loading background="#00000070"></Loading>}
        </Row >
    )
}



function mapStateToProps(state: any) {
    // console.log("Categories mapStateToProps: ", state);
    return {
        order: state.order,
        ui: state.ui
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        // selectedOrderDates,
        getPendingOrders,
        getOutletSlot,
        selectedOutlets,
        downloadPendingOrders,
        onOrderFilterOptions
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PendingOrders);