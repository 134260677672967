import type from "../types/actionTypes";
import config from "../apiConfig";
import { store } from '../store';
import { encodeQueryData } from "../helpers/util";
import axiosWrapper from "./axiosWrapper";

export const handleLeftDrawerVisibility = data => {
    console.log("checking in the  action drawer visibility");
    return {
        type: type.HANDLE_LEFT_DRAWER_VISIBILITY,
        data
    };
};

export const selectedOutlets = data => {
    return {
        type: type.SELECTED_OUTLETS,
        data
    };
};

export const signoutModal = data => {
    console.log("data in signout model-->", data);
    return {
        type: type.HANDLE_SIGNOUT_MODAL,
        data
    };
};

export const searchFilter = data => {
    console.log("data in SEARCH FILTER-->", data);
    return {
        type: type.SEARCH_FILTER,
        data
    };
};

export const signout = () => {
    // localStorage.setItem("userInfo", "");
    // localStorage.setItem("persist:primary", "");
    return {
        type: type.SIGNOUT
    };
};

export const multiOrderStatusDetails = data => {
    return {
        type: type.MULTI_ORDER_STATUS_DETAILS,
        data
    };
};

export const clearSaveOutlet = data => {
    return {
        type: type.SAVE_OUTLET_SUCCESS,
        data: null
    };
};


export const clearOrderMultiStatusDetails = data => {
    return {
        type: type.CHANGE_MULTI_ORDER_STATUS_SUCCESS,
        data: null
    };
};

export const clearDomainUnavailable = data => {
    return {
        type: type.GET_DOMAIN_AVAILABLE_ERROR,
        data: ""
    };
};

export const clearCustomerOrders = () => {
    return {
        type: type.CLEAR_CUSTOMER_ORDERS,
        data: ''
    }
}

export const clearCustomerList = () => {
    return {
        type: type.GET_CUSTOMERS_SUCCESS,
        data: { customers: [], isFreshList: true }
    };
};

export const clearCustomerOrderList = () => {
    return {
        type: type.GET_CUSTOMER_ORDERS_SUCCESS,
        data: { customerOrders: [], isFreshList: true }
    };
};

export const clearDomainAvailable = data => {
    return {
        type: type.GET_DOMAIN_AVAILABLE_SUCCESS,
        data: ""
    };
};

export const clearError = () => {
    return {
        type: type.ERROR,
        data: null
    };
};


export const onGetAppBuilds = (platform) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.appBuildsUrl.replace('{sId}', sId).replace('{platform}', platform)}`
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                if (!error.response.data.code === "SC10000") {
                    dispatch({
                        type: type.ERROR,
                        data: {
                            title: "Get App Packages Failed",
                            message: error.response.data.message,
                            code: error.response.data.code
                        }
                    });
                }
                return null
            } else {
                return error;
            }
        }
    };
}

export const getProcurementDetail = (queryParams) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }

    };
    let queryString = encodeQueryData(queryParams);
    const sId = store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.incompleteOrdersUrl.replace('{sId}', sId)}${queryString}`
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Procurement Detail Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
};

export const downloadProcurementDetail = (queryParams) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        },
        responseType: "blob"
    };
    queryParams = queryParams || {};
    queryParams['excel'] = true;
    queryParams['reportType'] = 'procurement';
    const sId = store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let queryString = encodeQueryData(queryParams);
            let url = `${config.baseUrl}${config.incompleteOrdersUrl.replace('{sId}', sId)}${queryString}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Download Procurement Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
}

export const onRemoveDomain = (data) => {
    const options = {
        method: 'PUT',
        data,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.removeDomainUrl.replace('{sId}', sId)}`
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Remove Domain Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
                return null
            } else {
                return error;
            }
        }
    };
}

export const onSetupDomain = (data) => {
    const options = {
        method: 'PUT',
        data,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.addDomainUrl.replace('{sId}', sId)}`
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Setup New Domain Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
                return null
            } else {
                return error;
            }
        }
    };
}

export const onGetDomains = () => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.getDomainsUrl.replace('{sId}', sId)}`
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get App Packages Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
                return null
            } else {
                return error;
            }
        }
    };
}

export const onGetAppSettings = (platform) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.appSettingsUrl.replace('{sId}', sId).replace('{platform}', platform)}`
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                if (!error.response.data.code === "SC10000") {
                    dispatch({
                        type: type.ERROR,
                        data: {
                            title: "Get App settings Failed",
                            message: error.response.data.message,
                            code: error.response.data.code
                        }
                    });
                }
                return null
            } else {
                return error;
            }
        }
    };
}

export const onCreateNewBuild = (platform) => {
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.appBuildsUrl.replace('{sId}', sId).replace('{platform}', platform)}`
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Create App Packages Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
                return null
            } else {
                return error;
            }
        }
    };
}

export const onUploadAppSettings = (data) => {
    const options = {
        method: 'PUT',
        data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.uploadAppSettingsUrl.replace('{sId}', sId)}`
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Create App Packages Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
                return null
            } else {
                return error;
            }
        }
    };
}


export const onCreateAppSettings = (data, platform) => {
    const options = {
        method: 'POST',
        data,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.appSettingsUrl.replace('{sId}', sId).replace('{platform}', platform)}`
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Create App Packages Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
                return null
            } else {
                return error;
            }
        }
    };
}

export const getMediaFiles = () => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.mediaUrl.replace('{sId}', sId)}`
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Media Files Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
};

export const onDeleteMedia = (data) => {
    const options = {
        method: 'PUT',
        data,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.deleteMediaUrl.replace('{sId}', sId)}`
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                return {
                    title: "Delete Media Failed",
                    message: error.response.data.message
                }
                // dispatch({
                //     type: type.ERROR,
                //     data: {
                //         title: "Delete Media Failed",
                //         message: error.response.data.message
                //     }
                // });
            } else {
                return error;
            }
        }
    };
}

export const onUploadMedia = (data) => {
    const options = {
        method: 'PUT',
        data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.uploadMediaUrl.replace('{sId}', sId)}`
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Update Site Status Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
            return { error: true }
        }
    };
}

export const getAddOnGroups = (productId) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.addOnGroupsUrl.replace('{sId}', sId).replace('{productId}', productId)}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get AddOn Groups Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
                return null
            } else {
                return error;
            }
        }
    };
}

export const getAdditionalCharges = () => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.chargesUrl.replace('{sId}', sId)}`
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Additional Charges Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
                return null
            } else {
                return error;
            }
        }
    };
}


export const onNewAdditionalCharge = (data) => {
    const options = {
        method: 'POST',
        data,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.chargesUrl.replace('{sId}', sId)}`
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Add Charge Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
}

export const onUpdateAdditionalCharge = (cId, data) => {
    const options = {
        method: 'PUT',
        data,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.saveChargesUrl.replace('{sId}', sId).replace('{cId}', cId)}`
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Update Charge Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
}

export const onDeleteAdditionalCharge = (cId, data) => {
    const options = {
        method: 'DELETE',
        data,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.saveChargesUrl.replace('{sId}', sId).replace('{cId}', cId)}`
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Delete Charge Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
}

export const updateChannelSettings = (channelId, data) => {
    const options = {
        method: 'PUT',
        data,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.channelSettingsUrl.replace('{sId}', sId).replace('{channel}', channelId)}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Update Channel Settings Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
}

export const updateBotSettings = (data) => {
    const options = {
        method: 'PUT',
        data,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.botSettingsUrl.replace('{sId}', sId)}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Update Bot Settings Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
}



export const getChannelSettings = (channelId) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.channelSettingsUrl.replace('{sId}', sId).replace('{channel}', channelId)}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Channel Settings Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
}

export const getBotSettings = () => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.botSettingsUrl.replace('{sId}', sId)}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Bot Settings Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
}


export const onLexiGenerateText = (data, useFor) => {
    const options = {
        method: 'POST',
        data,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.lexiUrl.replace('{sId}', sId).replace('{useFor}', useFor)}`
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Lexi Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
                return null
            } else {
                return error;
            }
        }
    };
}