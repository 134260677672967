import * as React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { withTranslation } from 'react-i18next';
import { toggleLeftMenu, selectedStore, resetOrders } from '../../../actions/uiAction';
import { selectedOutlets, signout } from '../../../actions/commonAction';
import { getStores } from "../../../actions/setupStoreActions";
import { getStoreDetails } from "../../../actions/brandAction";
import '../../../assets/sass/dsk/header.sass';
import DownArrow from '../../../assets/images/downArrow.svg';
import BellSvg from '../../../assets/images/left-panel-icons/bell.svg';
import OwnerSvg from '../../../assets/images/left-panel-icons/store.svg';
import StaffSvg from '../../../assets/images/left-panel-icons/user.svg';
import { LinkOutlined } from "@ant-design/icons";
import useClickOutside from "./useClickOutside";
import CustomerMenu from "../customer/Menu";
import SettingMenu from "../settings/Breadcrumbs";
import { useEffect, useState } from "react";
import { useNav } from "../../../helpers/customHook";
import SubMenu from "./SubMenu";
import { useLocation } from "react-router-dom";

const SubscriptionAllowed = ["active", "trial", "pendingCancellation"];
const Header = (props: any) => {
    const navigateTo = useNav();
    const location = useLocation();
    const dispatch = useDispatch();
    const { selectedStore } = useSelector((state: any) => state.ui);
    const { oauth, info } = useSelector((state: any) => state.auth);
    const { selectedStoreDetails } = useSelector((state: any) => state.common);
    const { siteStatus, businessHours } = selectedStoreDetails || {};
    const [stores, setStores] = useState([]);
    const [openStores, setOpenStores] = useState(false);
    const [storeStatus, setStoreStatus] = useState(null);
    const closeStores = React.useCallback(() => setOpenStores(false), []);
    const popoverStore = React.useRef();
    const { currentMainMenu } = props;
    const [subTabMenu, setSubTabMenu] = useState(null);
    useEffect(() => {
        const { pathname } = location;
        setSubTabMenu(pathname.split("/")[pathname.split("/").length - 1]);
        //eslint-disable-next-line
    }, [location]);
    const MenuConfig = {
        themes: {
            title: "Theme",
            root: "themes",
            menus: [
                { label: "Discover", value: "discover" },
                { label: "Installed", value: "installed" }
            ]
        },
        themeconfigurator: {
            title: "Theme Configurator",
            root: "themeconfigurator",
        },
        orders: {
            title: "Orders",
            root: "orders",
            menus: [
                { label: "Current Orders", value: "current" },
                { label: "All Orders", value: "all" },
                { label: "Payment Failures", value: "paymentfailures" },
                { label: "Pending Orders", value: "pendingorders" }
            ]
        },
        products: {
            title: "Products",
            root: "products",
            menus: [
                { label: "Listed Products", value: "listed" },
                { label: "Product Catalog", value: "catalog" }
            ]
        },
        blogs: {
            title: "Blogs",
            root: "blogs",
            menus: [
                { label: "Post", value: "post" },
                { label: "Blog", value: "blog" }
            ]
        },
        billing: {
            title: "Billing",
            root: "billing",
            menus: [
                { label: "Plans", value: "plans" },
                { label: "Billing Information", value: "billinginformation" },
                // { label: "Purchases", value: "purchases" },
                { label: "Invoices", value: "invoices" }
            ]
        },
        pages: {
            title: "Pages",
        },
        promotions: {
            title: "Promotions",
            root: "promotions",
            menus: [
                { label: "Listed Promotions", value: "listed" }
            ]
        },
        categories: {
            title: "Categories",
        },
        collections: {
            title: "Collections",
        },
        notifications: {
            title: "Notifications",
        },
        settings: {
            title: "Settings",
            root: "settings",
            onRenderMenu: () => <SettingMenu {...props}></SettingMenu>
        },
        media: {
            title: "Media",
        },
        bot: {
            title: "Bot",
        },
        helpCenter: {
            title: "Help Center",
        },
        customers: {
            title: "Customers",
            root: "customers",
            onRenderMenu: () => <CustomerMenu {...props}></CustomerMenu>
        },
        navigations: {
            title: "Navigations",
        },
    }
    useClickOutside(popoverStore, closeStores);
    useEffect(() => {
        getStores();
        //eslint-disable-next-line
    }, []);
    const getStores = async () => {
        if (oauth || info) {
            const resp = await props.getStores();
            setStores(resp);
        }
    }
    const onSelectedStore = async (store) => {
        await props.selectedStore(store);
        await props.resetOrders();
        setOpenStores(false);
        navigateTo(`/${store.name}/home`)
    }
    const getStoreDetails = async (sId) => {
        await props.getStoreDetails(sId);
    }
    useEffect(() => {
        if (businessHours && businessHours.closed) {
            setStoreStatus("Closed");
        } else if (siteStatus) {
            setStoreStatus(siteStatus);
        }
    }, [businessHours, siteStatus]);

    useEffect(() => {
        if (selectedStore) {
            getStoreDetails(selectedStore.id)
        }
        //eslint-disable-next-line
    }, [selectedStore])
    // const { t } = props;
    const getSiteStatusColorCode = (code) => {
        let colorCode = "#000";
        if (code) {
            if (code?.toLowerCase() === "closed") {
                colorCode = "#FF0A26";
            } else if (code?.toLowerCase() === "maintenance") {
                colorCode = "#ff950a";
            } else if (code?.toLowerCase() === "live") {
                colorCode = "#21C082"
            }
        }
        return colorCode;
    }
    const generateWebSiteUrl = (name) => {
        return window.location.origin.replace('shopletzy', 'myshopletzy').replace('app', name) + '/';
    }
    const logout = () => {
        localStorage.setItem("userInfo", "");
        dispatch(signout());
        navigateTo("/signin");
    }
    return (
        <div className="header-section">
            <div className="tab-menu">
                {MenuConfig[currentMainMenu] && MenuConfig[currentMainMenu].onRenderMenu ?
                    MenuConfig[currentMainMenu].onRenderMenu() :
                    <SubMenu {...MenuConfig[currentMainMenu]} tab={subTabMenu}></SubMenu>}
            </div>
            {selectedStore && <div className="store" data-tut="reactour__change_outlets">
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <a style={{ cursor: 'pointer' }} rel="noreferrer" target={"_blank"} href={`${generateWebSiteUrl(selectedStore.name)}?previewMode=true`}>
                            <span>Your shop site link</span>
                            <LinkOutlined style={{ marginLeft: 10, marginRight: -6 }} />
                        </a>
                    </div>
                    <div className="status" style={{ cursor: 'pointer' }} onClick={() => navigateTo('settings/store/general/sitestatus')}>
                        <div className="text" style={{ color: getSiteStatusColorCode(storeStatus) }}>{storeStatus}</div>
                        <div className="color_code" style={{ background: getSiteStatusColorCode(storeStatus), boxShadow: `0px 0px 6px ${getSiteStatusColorCode(storeStatus)}` }}></div>
                    </div>
                </div>
                <div style={{ background: '#b5bfccbd', height: 24, margin: "0px 20px", width: 1 }}></div>
                <img src={BellSvg} alt="Bell" style={{ cursor: 'pointer', margin: 0 }}></img>
                <div style={{ background: '#b5bfccbd', height: 24, margin: "0px 20px", width: 1 }}></div>
                <div className="info" style={{ cursor: 'pointer', alignItems: 'flex-end' }} onClick={() => setOpenStores(!openStores)}>
                    <div style={{ fontSize: 14, fontWeight: 500, textTransform: 'capitalize', marginBottom: 4 }}>{info?.fullName}</div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img style={{ width: 12 }} src={selectedStore.userType === "owner" ? OwnerSvg : StaffSvg} alt="user type" />
                        <div style={{ fontWeight: 700, marginLeft: 6, textTransform: 'capitalize', fontSize: 14 }}>{selectedStore && selectedStore.name}</div>
                        <img src={DownArrow} alt="Down Arrow" style={{ marginLeft: 15, width: 16 }}></img>
                    </div>
                </div>
                {/* {stores && stores.length > 1 && <img alt="Down Arrow" style={{ width: 24, cursor: 'pointer', marginTop: -20 }} src={DownArrow} onClick={() => setOpenStores(!openStores)}></img>} */}
                {openStores && <div className="store_list" ref={popoverStore}>
                    {stores && stores.length > 1 && <>
                        <div className="recent">Recent Stores</div>
                        {stores.map((store, i) => {
                            return store.id !== selectedStore.id && i < (store.id !== selectedStore.id ? 3 : 2) && SubscriptionAllowed.includes(store?.subscriptionStatus) &&
                                <div key={store.id} onClick={() => onSelectedStore(store)} className="item-label">
                                    <div>{store.name}</div>
                                    <img src={DownArrow} alt="Down Arrow" style={{ width: 16, rotate: '267deg' }}></img>
                                </div>
                        })}
                        <div className="switch-store" onClick={(event) => { navigateTo('/selectstore') }}>Switch Store</div>
                        <div className="divider"></div>
                    </>}
                    {/* <div className="item-label">Get help</div> */}
                    <div className="item-label" style={{ marginTop: 0 }} onClick={logout}>Logout</div>
                </div>}
            </div>}
            {/* {oauth ? <div className="welcome">
                {oauth.authProvider === "google" && oauth.profileObj && oauth.profileObj.imageUrl && <img alt="DP" src={oauth.profileObj.imageUrl} />}
            </div> : info && <div style={{
                width: 40,
                height: 40,
                border: "2px solid #cacaca",
                borderRadius: 30,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 14
            }}>
                <div style={{
                    width: '100%',
                    borderRadius: 30,
                    height: '100%',
                    background: '#121212',
                    display: 'flex',
                    fontSize: 18,
                    fontWeight: 'bold',
                    paddingTop: 4,
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#cacaca'
                }}>
                    {info?.fullName?.charAt(0)}
                </div>
            </div>} */}
            {/* <div className="locale">
                <Locale />
            </div> */}
        </div>
    );
    // }
}

export default connect(null, { selectedOutlets, toggleLeftMenu, getStores, getStoreDetails, selectedStore, resetOrders })(withTranslation()(Header));