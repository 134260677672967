import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import SignIn from "./components/dsk/auth/Signin";
import SetUpStore from "./components/dsk/setupstore";
import UserType from "./components/dsk/setupstore/UserType";
import Products from "./components/dsk/product";
import Dashboard from "./components/dsk/dashboard";
import Home from "./components/dsk/home";
import Collections from "./components/dsk/collection";
import Navigations from "./components/dsk/navigation";
import Orders from "./components/dsk/order";
import OrderView from "./components/dsk/order/View";
import Promotions from "./components/dsk/promotion";
import Channels from "./components/dsk/settings/channels";
import Support from "./components/dsk/settings/support";
import Account from "./components/dsk/settings/account";
import Settings from "./components/dsk/settings";
import MyStore from "./components/dsk/settings/myStore";
import Error from "./components/dsk/common/Error";
import Main from "./components/dsk/common/Main";
import 'react-markdown-editor-lite/lib/index.css';
import SendOTP from "./components/dsk/setupstore/SendOTP";
import VerifyOTP from "./components/dsk/setupstore/VerifyOTP";
import SelectStore from "./components/dsk/setupstore/SelectStore";
import CustomerOrders from "./components/dsk/customer/Orders";
import OutletDetail from "./components/dsk/settings/myStore/outlet/Detail";
import "./assets/sass/dsk/common.sass";
import { useDispatch, useSelector } from "react-redux";
import CurrentOrders from "./components/dsk/order/CurrentOrders";
import AllOrders from "./components/dsk/order/AllOrders";
import PaymentFailures from "./components/dsk/order/PaymentFailures";
import PendingOrders from "./components/dsk/order/PendingOrders";
import Categories from "./components/dsk/category";
import ListedCategory from "./components/dsk/category/ListedCategory";
import Customers from "./components/dsk/customer";
import ListedCustomer from "./components/dsk/customer/ListedCustomer";
import ProductCatalog from "./components/dsk/product/ProductCatalog";
import ListedProduct from "./components/dsk/product/ListedProducts";
import General from "./components/dsk/settings/myStore/general";
import SiteConfiguration from "./components/dsk/settings/myStore/siteConfiguration";
import ThemeConfiguration from "./components/dsk/settings/themeConfig";
import User from "./components/dsk/settings/myStore/user";
import Checkout from "./components/dsk/settings/myStore/checkout";
import BusinessHours from "./components/dsk/settings/myStore/businessHours";
import Holidays from "./components/dsk/settings/myStore/holidays";
import DeliverySlots from "./components/dsk/settings/myStore/deliverySlots";
import ComingSoon from "./components/dsk/settings/ComingSoon";
import Overview from "./components/dsk/settings/myStore/siteConfiguration/overview";
import SiteStatus from "./components/dsk/settings/myStore/general/siteStatus";
import About from "./components/dsk/settings/myStore/general/about";
import Pages from "./components/dsk/settings/myStore/general/pages";
import Media from "./components/dsk/media";
import SocialLinks from "./components/dsk/settings/myStore/general/socialLinks";
import ListedOutlets from "./components/dsk/settings/myStore/outlet/ListedOutlets";
import Web from "./components/dsk/settings/channels/web";
import Mobile from "./components/dsk/settings/channels/mobile";
import BrandingWeb from "./components/dsk/settings/channels/web/branding";
import BrandingMobile from "./components/dsk/settings/channels/mobile/branding";
import CodeInjectionWeb from "./components/dsk/settings/channels/web/codeInjection";
import CodeInjectionMobile from "./components/dsk/settings/channels/mobile/codeInjection";
import CustomDomain from "./components/dsk/settings/channels/web/customDomain";
import GeneralWeb from "./components/dsk/settings/channels/web/branding/general";
import BrandingHomeWeb from "./components/dsk/settings/channels/web/branding/home";
import LandingWeb from "./components/dsk/settings/channels/web/branding/landing";
import GeneralMobile from "./components/dsk/settings/channels/mobile/branding/general";
import BrandingHomeMobile from "./components/dsk/settings/channels/mobile/branding/home";
import LandingMobile from "./components/dsk/settings/channels/mobile/branding/landing";
import PromotionListed from "./components/dsk/promotion/View";
import Faq from "./components/dsk/settings/support/faq";
import Bank from "./components/dsk/settings/account/bank";
import Android from "./components/dsk/settings/channels/mobile/android";
import CreateStore from "./components/dsk/setupstore/CreateStore";
import Marketing from "./components/dsk/settings/marketing";
import GoogleAds from "./components/dsk/settings/marketing/googleAds";
import Whatsapp from "./components/dsk/settings/channels/whatsapp";
import Instagram from "./components/dsk/settings/channels/instagram";
import Facebook from "./components/dsk/settings/channels/facebook";
import Bot from "./components/dsk/bot";
import ChoosePlan from "./components/dsk/common/ChoosePlan";
import AdditionalCharges from "./components/dsk/settings/myStore/additionalCharges";
import Blogs from "./components/dsk/blog";
import Blog from "./components/dsk/blog/Blog";
import HelpCenter from "./components/dsk/helpCenter";
import Post from "./components/dsk/blog/Post";
import CreatePost from "./components/dsk/blog/CreatePost";
import CreatePage from "./components/dsk/page/CreatePage";
import PageListView from "./components/dsk/page/ListView";
import PageRedirectListView from "./components/dsk/settings/myStore/pageRedirect/ListView";
import PageRedirect from "./components/dsk/settings/myStore/pageRedirect";
import PageRedirectSave from "./components/dsk/settings/myStore/pageRedirect/Save";
import Page from "./components/dsk/settings/myStore/page";
import LoginOTP from "./components/dsk/auth/LoginOTP";
import LoginVerifyOTP from "./components/dsk/auth/VerifyOTP";
import EmailSignup from "./components/dsk/auth/EmailSignup";
import AppDev from "./components/dsk/appdev";
import { resetOrders } from './actions/uiAction';
import { getStores } from "./actions/setupStoreActions";
import Brand from "./components/dsk/settings/myStore/general/brand";
import Notifications from "./components/dsk/notifications";
import NotificationListed from "./components/dsk/notifications/View";
import Builds from "./components/dsk/settings/channels/mobile/android/builds";
import AppConfig from "./components/dsk/settings/channels/mobile/android/appConfig";
import Theme from "./components/dsk/theme";
import ThemeDiscover from "./components/dsk/theme/discover";
import ThemeInstalled from "./components/dsk/theme/installed";
import ThemeConfigurator from "./components/dsk/theme/installed/Configurator";
import Integrations from "./components/dsk/settings/integrations";
import GoogleAnalytics from "./components/dsk/settings/integrations/analytics/google";
import MicrosoftClarity from "./components/dsk/settings/integrations/analytics/microsoftClarity";
import Hotjar from "./components/dsk/settings/integrations/analytics/hotjar";
import Mixpanel from "./components/dsk/settings/integrations/analytics/mixpanel";
import Amplitude from "./components/dsk/settings/integrations/analytics/amplitude";
import FacebookPixel from "./components/dsk/settings/integrations/analytics/facebookPixel";
import Analytics from "./components/dsk/settings/integrations/analytics";
import ListedCollection from "./components/dsk/collection/ListedCollection";
import ListedNavigation from "./components/dsk/navigation/ListedNavigation";
import Billing from "./components/dsk/billing"
import Plans from "./components/dsk/billing/Plans";
import Invoice from "./components/dsk/billing/Invoice";
import Purchases from "./components/dsk/billing/Purchases";
import BillingInformation from "./components/dsk/billing/BillingInformation";
import CheckoutPlan from "./components/dsk/common/CheckoutPlan";

const App = (props: any) => {
  const dispatch = useDispatch();
  const { info, oauth, isLoggedIn, otpToken } = useSelector((state: any) => state.auth);
  const { error: errorInfo } = useSelector((state: any) => state.common);
  const { selectedStore, setupStoreDetails } = useSelector((state: any) => state.ui);
  const location = useLocation();
  const [, store] = location.pathname.split("/");
  const SubscriptionAllowed = ["active", "trial", "pendingCancellation", "suspended", "cancelled"];
  // const SubscriptionNotAllowed = ["cancelled", "none", "suspended", "ended"];
  const [appState, setAppState] = useState(null);
  // const [error, setError] = useState(null);
  // const [theme, setTheme] = useState({
  //   primaryColor: "#000000",
  //   secondaryColor: "#e97a43",
  //   secondaryLighterColor: "#ffe5d9",
  //   buttonBGColor: "#242540",
  //   buttonFGColor: "#fff",
  // });
  const setStores = async () => {
    if (store) {
      await getStores(store)(dispatch);
      await dispatch(resetOrders());
    }
  }
  useEffect(() => {
    if (info && isLoggedIn) {
      setAppState("LoggedIn")
      // if (['storeaddress', 'storemap', 'sendOTP', 'verifyOTP/:mobileNo'].map(o => '/' + o).includes(location.pathname)) {
      //   console.log("::::::::::::::::::::::::::::::::::::::::::::")
      // } else {
      //   setAppState("SendOTP")
      // }
    }
  }, [info, isLoggedIn])


  useEffect(() => {
    if ((!setupStoreDetails && !selectedStore) && (oauth || info)) {
      setStores();
    }
    //eslint-disable-next-line
  }, [store]);
  // props.dispatch({
  //   type: type.LOCALE,
  //   data: {
  //     key: "en",
  //     value: "English"
  //   }
  // });
  // props.i18n.changeLanguage("en");
  // console.log({ selectedStore, setupStoreDetails, appState });
  return <>
    {/* <ThemeSwitcher theme={this.state.theme}> */}
    <div className="shopletzy-app">
      {/* <OnBoarding /> */}
      <Routes>
        <Route path="/signin" element={
          info && info.mobileNo ? <SelectStore /> : <SignIn  {...props} />
        } />
        <Route path="/loginotp" element={
          info && info.mobileNo ? <SelectStore /> : <LoginOTP  {...props} />
        } />
        <Route path="/verifyotp/:contactId" element={
          otpToken ? <LoginVerifyOTP /> : <LoginOTP  {...props} />
        } />
        <Route path="/emailsignup" element={
          info ? <SelectStore /> : <EmailSignup  {...props} />
        } />
        {appState === "LoggedIn" && <Route path="/setupstore" element={<SetUpStore />} >
          <Route path="sendOTP" element={<SendOTP />} />
          <Route path="verifyOTP/:mobileNo" element={<VerifyOTP />} />
          <Route path="usertype" element={<UserType />} />
          <Route path="createstore" element={<CreateStore />} />
          {/* <Route path="storeaddress" element={<StoreAddress />} /> */}
          {/* <Route path="storemap" element={<StoreMap />} /> */}
          {/* <Route path="staff" element={<Staff />} /> */}
        </Route>}
        {appState === "LoggedIn" && <Route path="/selectstore" element={<SelectStore />} />}
        {/* {appState === "LoggedIn" && selectedStore?.subscriptionStatus === "cancelled" && <Route path="/:storeName/cancelled" element={<StoreSuspended />} />} */}
        {/* {appState === "LoggedIn" && selectedStore?.subscriptionStatus === "suspended" && <Route path="/:storeName/suspended" element={<StoreSuspended />} />} */}
        {appState === "LoggedIn" && !SubscriptionAllowed.includes(setupStoreDetails?.storeName || selectedStore?.name) && <Route path="/:storeName/chooseplan" element={<ChoosePlan />} />}
        {appState === "LoggedIn" && !SubscriptionAllowed.includes(setupStoreDetails?.storeName || selectedStore?.name) && <Route path="/:storeName/plan/:planName" element={<CheckoutPlan />} />}
        {/* {appState === "LoggedIn" && !SubscriptionAllowed.includes(selectedStore?.subscriptionStatus) && <Route path="/:storeName/plan/:planName" element={<PlanSubscription />} />} */}
        {appState === "LoggedIn" && SubscriptionAllowed.includes(selectedStore?.subscriptionStatus) && <Route path="/:storeName" element={<Main />}>
          {/* <Route path="choosePlan" element={<PlanSubscription />} /> */}
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="home" element={<Home />} />
          <Route path="products" element={<Products />} >
            <Route index element={<ProductCatalog></ProductCatalog>} />
            <Route path="catalog" element={<ProductCatalog></ProductCatalog>} />
            <Route path="listed" element={<ListedProduct {...props}></ListedProduct>} />
          </Route>
          <Route path="customers" element={<Customers />} >
            <Route index element={<ListedCustomer />} />
            <Route path=":id/orders" element={<CustomerOrders />} />
          </Route>
          <Route path="collections" element={<Collections />} >
            <Route path="listed" element={<ListedCollection {...props} />} />
          </Route>
          <Route path="navigations" element={<Navigations />} >
            <Route path="listed" element={<ListedNavigation {...props} />} />
          </Route>
          <Route path="categories" element={<Categories />} >
            <Route path="listed" element={<ListedCategory {...props} />} />
          </Route>
          <Route path="orders" element={<Orders />} >
            <Route index element={<CurrentOrders {...props} />} />
            <Route path="current" element={<CurrentOrders {...props} />} />
            <Route path="all" element={<AllOrders {...props} />} />
            <Route path="paymentfailures" element={<PaymentFailures {...props} />} />
            <Route path="pendingorders" element={<PendingOrders {...props} />} />
            <Route path="current/details/:id" element={<OrderView />} />
            <Route path="paymentfailures/details/:id" element={<OrderView />} />
            <Route path="pendingorders/details/:id" element={<OrderView />} />
            <Route path="all/details/:id" element={<OrderView />} />
          </Route>
          <Route path="settings/account/:options" element={<Account />} />
          <Route path="media" element={<Media />} />
          <Route path="notifications" element={<Notifications />} >
            <Route index element={<NotificationListed />} />
          </Route>
          <Route path="billing" element={<Billing />} >
            <Route index element={<Plans {...props}></Plans>} />
            <Route path="plans" element={<Plans {...props}></Plans>} />
            <Route path="invoices" element={<Invoice {...props}></Invoice>} />
            <Route path="purchases" element={<Purchases {...props}></Purchases>} />
            <Route path="billingInformation" element={<BillingInformation {...props}></BillingInformation>} />
          </Route>
          <Route path="blogs" element={<Blogs />} >
            <Route index element={<Blog {...props}></Blog>} />
            <Route path="blog" element={<Blog {...props} />} />
            <Route path="post" element={<Post {...props} />} />
            <Route path="post/create" element={<CreatePost {...props} />} />
            <Route path="post/edit/:id" element={<CreatePost {...props} />} />
          </Route>
          <Route path="helpCenter" element={<HelpCenter />} />
          <Route path="bot" element={<Bot />} />
          <Route path="pages" element={<Page />} >
            <Route index element={<PageListView {...props} />} />
            <Route path="pages" element={<PageListView {...props} />} />
            <Route path="create" element={<CreatePage {...props} />} />
            <Route path="edit/:id" element={<CreatePage {...props} />} />
          </Route>
          <Route path="themes" element={<Theme  {...props} />} >
            <Route path="discover" element={<ThemeDiscover />} />
            <Route path="installed" element={<ThemeInstalled />} />
          </Route>
          <Route path="themeconfigurator/:themeName" element={<ThemeConfigurator />} />
          <Route path="settings/store" element={<MyStore />} >
            <Route path={`general`} element={<General />} >
              <Route path={`about`} element={<About />} />
              <Route path={`sitestatus`} element={<SiteStatus />} />
              <Route path={`brand`} element={<Brand />} />
              <Route path={`pages`} element={<Pages />} />
              <Route path={`sociallinks`} element={<SocialLinks />} />
            </Route>
            <Route path={`siteconfiguration`} element={<SiteConfiguration />} >
              <Route path={`overview`} element={<Overview />} />
            </Route>
            <Route path="outlet" element={<ListedOutlets />} />
            <Route path="outlet/:id/detail" element={<OutletDetail />} />
            <Route path="user" element={<User />} />
            <Route path="checkout" element={<Checkout />} />
            <Route path="businessHours" element={<BusinessHours />} />
            <Route path="holidays" element={<Holidays />} />
            <Route path="themeconfiguration" element={<ThemeConfiguration />} />
            <Route path="pageredirects" element={<PageRedirect />} >
              <Route index element={<PageRedirectListView {...props} />} />
              <Route path="pageredirects" element={<PageRedirectListView {...props} />} />
              <Route path="create" element={<PageRedirectSave {...props} />} />
            </Route>
            <Route path="deliverySlots" element={<DeliverySlots />} />
            <Route path="additionalcharges" element={<AdditionalCharges />} />
            <Route path="*" element={<ComingSoon />} />
          </Route>
          <Route path="settings/channels" element={<Channels />} >
            <Route path="web" element={<Web />} >
              <Route path={`branding`} element={<BrandingWeb />} >
                <Route index element={<GeneralWeb />} />
                <Route path={`general`} element={<GeneralWeb />} />
                <Route path={`home`} element={<BrandingHomeWeb />} />
                <Route path={`landing`} element={<LandingWeb />} />
              </Route>
              <Route path={`codeinjection`} element={<CodeInjectionWeb />} />
              <Route path={`customdomain`} element={<CustomDomain />} />
            </Route>
            <Route path="mobile" element={<Mobile />} >
              <Route path={`branding`} element={<BrandingMobile />} >
                <Route index element={<GeneralMobile />} />
                <Route path={`general`} element={<GeneralMobile />} />
                <Route path={`home`} element={<BrandingHomeMobile />} />
                <Route path={`landing`} element={<LandingMobile />} />
              </Route>
              <Route path={`codeinjection`} element={<CodeInjectionMobile />} />
              <Route path={`android`} element={<Android />} >
                <Route index element={<AppConfig></AppConfig>} />
                <Route path={`appconfiguration`} element={<AppConfig></AppConfig>} />
                <Route path={`builds`} element={<Builds></Builds>} />
              </Route>
              <Route path={`ios`} element={<ComingSoon style={{ height: '60vh' }} title={"iOS"} />} />
            </Route>
            <Route path="whatsapp" element={<Whatsapp />} />
            <Route path="instagram" element={<Instagram />} />
            <Route path="facebook" element={<Facebook />} />
          </Route>
          <Route path="settings/support" element={<Support />} >
            <Route path="faq" element={<Faq />} />
          </Route>
          <Route path="settings/marketing" element={<Marketing />} >
            <Route path="googleads" element={<GoogleAds />} />
          </Route>
          <Route path="settings/integrations" element={<Integrations />} >
            <Route path="analytics" element={<Analytics />} >
              <Route path="google" element={<GoogleAnalytics />} />
              <Route path="microsoftClarity" element={<MicrosoftClarity />} />
              <Route path="hotjar" element={<Hotjar />} />
              <Route path="mixpanel" element={<Mixpanel />} />
              <Route path="amplitude" element={<Amplitude />} />
              <Route path="facebookpixel" element={<FacebookPixel />} />
            </Route>
          </Route>
          <Route path="settings/integrations" element={<Integrations />} >
            <Route path="analytics" element={<Analytics />} >
              <Route path="google" element={<GoogleAnalytics />} />
              <Route path="microsoftClarity" element={<MicrosoftClarity />} />
              <Route path="hotjar" element={<Hotjar />} />
              <Route path="mixpanel" element={<Mixpanel />} />
              <Route path="amplitude" element={<Amplitude />} />
              <Route path="facebookpixel" element={<FacebookPixel />} />
            </Route>
          </Route>
          <Route path="settings/account" element={<Account />} >
            <Route path="bankdetails" element={<Bank />} />
            {/* <Route path="plansbillings" element={<Billing />} /> */}
            <Route path="plandetail" element={<ChoosePlan />} />
          </Route>
          <Route path="settings" element={<Settings />} />
          <Route path="appdev" element={<AppDev />} />
          <Route path="promotions" element={<Promotions />} >
            <Route path="listed" element={<PromotionListed />} />
          </Route>
        </Route>}
        <Route path="/" element={info ? <SelectStore /> : <SignIn  {...props} />} />
      </Routes>
    </div>
    {errorInfo && <Error errorInfo={errorInfo}></Error>}
    {/* {error && <PopupConfirm config={popupConfirm}></PopupConfirm>} */}
    {/* </ThemeSwitcher> */}
  </  >
  // }
}

export default App;