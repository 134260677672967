const Types = {
    LOCALE: "LOCALE",
    AUTH_SUCCESS: "AUTH_SUCCESS",
    AUTH_ERROR: "AUTH_ERROR",
    OTP_TOKEN_SUCCESS: "OTP_TOKEN_SUCCESS",
    // GET_ADDRESS_SUCCESS: "GET_ADDRESS_SUCCESS",
    // GET_ADDRESS_ERROR: "GET_ADDRESS_ERROR",
    // SEARCH_ADDRESS_SUCCESS: "SEARCH_ADDRESS_SUCCESS",
    // SEARCH_ADDRESS_ERROR: "SEARCH_ADDRESS_ERROR",
    VERIFY_OTP_SUCCESS: "VERIFY_OTP_SUCCESS",
    VERIFY_OTP_ERROR: "VERIFY_OTP_ERROR",
    USER_TYPE: "USER_TYPE",
    SETUP_STORE_DETAILS: "SETUP_STORE_DETAILS",
    STORES: "STORES",
    SELECTED_STORE: "SELECTED_STORE",
    SELECTED_STORE_DETAILS: "SELECTED_STORE_DETAILS",
    CREATE_MERCHANT_ERROR: "CREATE_MERCHANT_ERROR",
    SEND_OTP_SUCCESS: "SEND_OTP_SUCCESS",
    SEND_OTP_ERROR: "SEND_OTP_ERROR",
    SIGNOUT: "SIGNOUT",
    SELECTED_MAIN_MENU: "SELECTED_MAIN_MENU",
    // SELECTED_MENU: "SELECTED_MENU",
    SELECTED_TAB_MENU: "SELECTED_TAB_MENU",
    SEARCH_FILTER: "SEARCH_FILTER",
    OAUTH_LOGIN: "OAUTH_LOGIN",
    GET_DOMAIN_AVAILABLE_SUCCESS: "GET_DOMAIN_AVAILABLE_SUCCESS",
    GET_DOMAIN_AVAILABLE_ERROR: "GET_DOMAIN_AVAILABLE_ERROR",
    INCOMPLETE_ORDER_SUCCESS: "INCOMPLETE_ORDER_SUCCESS",
    COMPLETED_ORDER_SUCCESS: "COMPLETED_ORDER_SUCCESS",
    GET_INCOMPLETE_ORDERS_SUCCESS: "GET_INCOMPLETE_ORDERS_SUCCESS",
    GET_ALL_ORDERS_SUCCESS: "GET_ALL_ORDERS_SUCCESS",
    GET_PAYMENT_FAILURE_ORDERS_SUCCESS: "GET_PAYMENT_FAILURE_ORDERS_SUCCESS",
    GET_ORDERS_ERROR: "GET_ORDERS_ERROR",
    GET_DELIVERY_EXECS_SUCCESS: "GET_DELIVERY_EXECS_SUCCESS",
    GET_DELIVERY_EXECS_ERROR: "GET_DELIVERY_EXECS_ERROR",
    ASSIGN_ORDER_ERROR: "ASSIGN_ORDER_ERROR",
    SHOW_MULTI_UPDATE: "SHOW_MULTI_UPDATE",
    MULTI_ORDER_STATUS_DETAILS: "MULTI_ORDER_STATUS_DETAILS",
    CHANGE_ALL_ORDER_STATUS_ERROR: "CHANGE_ALL_ORDER_STATUS_ERROR",
    CHANGE_MULTI_ORDER_STATUS_SUCCESS: "CHANGE_MULTI_ORDER_STATUS_SUCCESS",
    SELECTED_ORDER_DATES: "SELECTED_ORDER_DATES",
    ORDER_FILTER_TOGGLE: "ORDER_FILTER_TOGGLE",
    CURRENT_ORDER_FILTER_OPTIONS: "CURRENT_ORDER_FILTER_OPTIONS",
    PAYMENT_FAILURE_ORDER_FILTER_OPTIONS: "PAYMENT_FAILURE_ORDER_FILTER_OPTIONS",
    PENDING_ORDER_FILTER_OPTIONS: "PENDING_ORDER_FILTER_OPTIONS",
    ALL_ORDER_FILTER_OPTIONS: "ALL_ORDER_FILTER_OPTIONS",
    GET_PRODUCTS_ERROR: "GET_PRODUCTS_ERROR",
    GET_PRODUCTS_SUCCESS: "GET_PRODUCTS_SUCCESS",
    SAVE_PRODUCTS_ERROR: "SAVE_PRODUCTS_ERROR",
    SAVE_PRODUCTS_SUCCESS: "SAVE_PRODUCTS_SUCCESS",
    GET_OUTLETS_ERROR: "GET_OUTLETS_ERROR",
    GET_OUTLETS_SUCCESS: "GET_OUTLETS_SUCCESS",
    SAVE_OUTLET_ERROR: "SAVE_OUTLET_ERROR",
    SAVE_OUTLET_SUCCESS: "SAVE_OUTLET_SUCCESS",
    GET_EMPLOYEES_ERROR: "GET_EMPLOYEES_ERROR",
    GET_EMPLOYEES_SUCCESS: "GET_EMPLOYEES_SUCCESS",
    GET_INDUSTRY_TYPES_ERROR: "GET_INDUSTRY_TYPES_ERROR",
    GET_INDUSTRY_TYPES_SUCCESS: "GET_INDUSTRY_TYPES_SUCCESS",
    GET_CATEGORIES_ERROR: "GET_CATEGORIES_ERROR",
    GET_CATEGORIES_SUCCESS: "GET_CATEGORIES_SUCCESS",
    GET_CUSTOMERS_ERROR: "GET_CUSTOMERS_ERROR",
    GET_CUSTOMERS_SUCCESS: "GET_CUSTOMERS_SUCCESS",
    ORDER_STATUS_UPDATED: "ORDER_STATUS_UPDATED",
    SEARCH_CUSTOMERS_SUCCESS: "SEARCH_CUSTOMERS_SUCCESS",
    ERROR: "ERROR",
    GET_ORDERS_BY_CUSTOMER_SUCCESS: "GET_ORDERS_BY_CUSTOMER_SUCCESS",
    GET_ORDERS_BY_CUSTOMER_ERROR: "GET_ORDERS_BY_CUSTOMER_ERROR",
    GET_ORDER_DETAILS_SUCCESS: "GET_ORDER_DETAILS_SUCCESS",
    GET_ORDER_DETAILS_ERROR: "GET_ORDER_DETAILS_ERROR",
    SELECTED_OUTLETS: "SELECTED_OUTLETS",
    LEFT_MENU_TOGGLE: "LEFT_MENU_TOGGLE",
    GET_CREDENTIALS_SUCCESS: "GET_CREDENTIALS_SUCCESS",
    SET_CREDENTIALS_SUCCESS: "SET_CREDENTIALS_SUCCESS",
    SELECTED_SETTINGS_MENU: "SELECTED_SETTINGS_MENU",
    HIDDEN_NAV_BAR_BOTTOM: "HIDDEN_NAV_BAR_BOTTOM",
    GET_CUSTOMER_ORDERS_SUCCESS: "GET_CUSTOMER_ORDERS_SUCCESS",
    CLEAR_CUSTOMER_ORDERS: "CLEAR_CUSTOMER_ORDERS",
    RESET_ORDERS: "RESET_ORDERS"
};

export default Types;
