import type from "../types/actionTypes";
import config from "../apiConfig";
import { store } from '../store';
import axiosWrapper from "./axiosWrapper";


export const getCategories = () => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.categoriesUrl.replace('{sId}', sId)}`
            let resp = await axiosWrapper(url, options, { dispatch });
            dispatch({
                type: type.GET_CATEGORIES_SUCCESS,
                data: resp.data
            });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Categories Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
};

export const getMediaCategories = () => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.mediaForUseUrl.replace('{forUse}', 'category').replace('{sId}', sId)}`
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Media Categories Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
};


export const addCategories = (data) => {
    const options = {
        method: 'POST',
        data,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.categoriesUrl.replace('{sId}', sId)}`
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Add New Category Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
                return { error: error.response.data }
            }
        }
    };
}

export const deleteCategory = (categoryId) => {
    const options = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.deleteCategoryUrl.replace('{sId}', sId).replace('{categoryId}', categoryId)}`
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Add New Category Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
                return { error: error.response.data }
            }
        }
    };
}

export const updateCategories = (data, id) => {
    const options = {
        method: 'PUT',
        data,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = store.getState().ui.selectedStore.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.categoriesUrl.replace('{sId}', sId)}/${id}`
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Update Category Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
                return { error: error.response.data }
            }
        }
    };
}


export function uploadImage(cId, file) {
    let bodyFormData = new FormData();
    bodyFormData.append('image', file, 'image.jpeg');
    const options = {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${store.getState().auth.info.token}`,
            "Content-Type": "multipart/form-data"
        },
        data: bodyFormData
    };
    const sId = store.getState().ui.selectedStore.id;
    let url = `${config.baseUrl}${config.uploadCategoryImageUrl.replace('{categoryId}', cId).replace('{sId}', sId)}`;
    return async (dispatch) => {
        try {
            let respUploadedDetails = await axiosWrapper(url, options, { dispatch });
            return respUploadedDetails;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Product Upload Files Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
                return { error: error.response.data }
            }
        }
    };
}

export function deleteImage(cId) {
    const options = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth.info.token}`
        }
    };
    const sId = store.getState().ui.selectedStore.id;
    let url = `${config.baseUrl}${config.deleteCategoryImageUrl.replace('{categoryId}', cId).replace('{sId}', sId)}`;
    return async (dispatch) => {
        try {
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Update Category Image Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
}
