import React, { useEffect, useState, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
// import { getL10N, loadOutletBasedProduct } from "../../../helpers/middleware";
import { Row, Col, DatePicker, Select } from 'antd';
import type { TimeRangePickerProps } from 'antd';
import '../../../assets/sass/dsk/order.sass';
// import ExportSvg from "../../../assets/images/export.svg";
// import ArrowSvg from "../../../assets/images/downArrow.svg";
import ExportSvg from "../../../assets/images/export.svg";
import DownloadAllInvoiceSvg from "../../../assets/images/bulkDownload.svg";
import DownloadInvoiceSvg from "../../../assets/images/download-invoice.svg";
import { downloadOrderInvoice, getAllOrders, downloadAllOrder, downloadAllOrderInvoices } from "../../../actions/orderActions";
import { allOrderFilterOptions as onOrderFilterOptions } from "../../../actions/uiAction";
import { getOutletSlot } from "../../../actions/outletAction";
import { selectedOutlets } from '../../../actions/commonAction';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { CONSTANTS } from '../../../constants';
import QueryFilter from '../common/QueryFilter';
import Loading from '../common/Loading';
import PopupConfirm from '../common/PopupConfirm';
import { useNav } from '../../../helpers/customHook';

const { RangePicker } = DatePicker;
const RangePresets: TimeRangePickerProps['presets'] = [
    { label: 'Tomorrow', value: [dayjs().add(1, 'd'), dayjs().add(1, 'd')] },
    { label: 'Today', value: [dayjs(), dayjs()] },
    { label: 'Yesterday', value: [dayjs().subtract(1, 'd'), dayjs().subtract(1, 'd')] },
    { label: 'Last 7 Days', value: [dayjs().subtract(6, 'd'), dayjs()] },
];
dayjs.extend(utc);
const { Option } = Select;
// const { RangePicker } = DatePicker;
const conditions = [
    { key: "orderNo", value: "Order No" },
    { key: "any", value: "Any" },
    // { key: "phoneNo", value: "Phone No" },
    // { key: "invoice", value: "Invoice" },
    // { key: "orderstatus", value: "Order Status" },
    // { key: "date", value: "Date" },
    // { key: "totalamount", value: "Total Amount" },
    // { key: "item", value: "Item" },
];
const OrderStatusCode = [
    { key: 'all', value: 'All' },
    { key: 'exceptCancelled', value: 'Except Cancelled' },
    { key: 'awaitingFulfilment', value: 'Awaiting Fulfilment' },
    { key: 'shipped', value: 'Shipped' },
    { key: 'cancelled', value: 'Cancelled' },
    { key: 'awaitingPickup', value: 'Awaiting Pickup' },
    { key: 'completed', value: 'Completed' }
];

const ReportTypes = [
    { key: 'orderSummary', value: 'Order Summary' },
    { key: 'orderItems', value: 'Order Items' },
];

const DateFilterTypes = [
    { key: 'creationDate', value: 'Creation Date' },
    { key: 'deliverySlot', value: 'Delivery Slot' },
]

const AllOrders = (props: any) => {
    // let sDate = dayjs();
    // let eDate = dayjs();
    // const WindowHeight = window.innerHeight;
    // const WindowWidth = window.innerWidth;
    const navigateTo = useNav();
    let sDate = dayjs().subtract(6, 'd');
    let eDate = dayjs();
    const { outlets } = useSelector((state: any) => state.outlet);
    const [selectedOuId, setSelectedOuId] = useState(null);
    const [selectedOrderStatus, setSelectedOrderStatus] = useState(OrderStatusCode[1].key);
    const [orderList, setOrderList] = useState([]);
    const { allOrderFilterOptions: orderFilterOptions } = useSelector((state: any) => state.ui);
    const [selectedDate, setSelectedDate]: any = useState([sDate, eDate]);
    const [dateFilterType, setDateFilterType] = useState(DateFilterTypes[1].key);
    const [selectedOrder, setSelectedOrder] = useState(null);
    // const [selectedSlot, setSelectedSlot] = useState('all');
    // const [deliverySlots, setDeliverySlots] = useState(null);
    const [queries, setQueries] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [popupConfirm, setPopupConfirm] = useState(null);
    const reportType = useRef(ReportTypes[0].key);
    // const [orders, setOrders] = useState([]);

    // useEffect(() => {
    //     if (selectedOuId) {
    //         // setDeliverySlots(null);
    //         getAllOrders();
    //     }
    //     //eslint-disable-next-line
    // }, [selectedOuId]);

    // useEffect(() => {
    //     if (outlets && outlets.length > 0) {
    //         setSelectedOuId(outlets[0].id);
    //     } else {
    //         setSelectedOuId(null)
    //         setOrderList([])
    //     }
    // }, [outlets]);

    useEffect(() => {
        if (orderFilterOptions) {
            setQueries(orderFilterOptions.query);
            setSelectedOuId(orderFilterOptions.ouId);
            setSelectedOrderStatus(orderFilterOptions.status);
            setQueries(orderFilterOptions.query);
            setDateFilterType(orderFilterOptions.dateFilterType || dateFilterType);
            if (orderFilterOptions.date && orderFilterOptions.date.length > 1) {
                setSelectedDate([dayjs(orderFilterOptions.date[0]), dayjs(orderFilterOptions.date[1])])
            }
            getAllOrders();
        }
        //eslint-disable-next-line
    }, [orderFilterOptions])

    useEffect(() => {
        if (orderFilterOptions) {
            setQueries(orderFilterOptions.query);
            setSelectedOuId(orderFilterOptions.ouId);
            setSelectedOrderStatus(orderFilterOptions.status);
            setQueries(orderFilterOptions.query);
            if (orderFilterOptions.date && orderFilterOptions.date.length > 1) {
                setSelectedDate([dayjs(orderFilterOptions.date[0]), dayjs(orderFilterOptions.date[1])])
            }
            getAllOrders();
        }
        //eslint-disable-next-line
    }, [])

    // useEffect(() => {
    //     filterOrders();
    //     //eslint-disable-next-line
    // }, [queries, selectedOrderStatus])

    // const filterOrders = () => {
    //     let filterOrderList = []
    //     let queryFilters: any = {};
    //     if (queries) {
    //         for (let q of queries) {
    //             if (!queryFilters[q.fId]) {
    //                 queryFilters[q.fId] = []
    //             }
    //             queryFilters[q.fId].push(q.fValue);
    //         }
    //     }
    //     for (let o of orderList) {
    //         let isOrderNo = false;
    //         let isOutlet = false;
    //         let isPhoneNo = false;
    //         let orderStatus = false;
    //         if (selectedOrderStatus === OrderStatusCode[0].key || o.status === selectedOrderStatus) {
    //             orderStatus = true;
    //         }
    //         if (queryFilters.orderNo && queryFilters.orderNo.length > 0) {
    //             if (queryFilters.orderNo.indexOf(o.orderNo) > -1) {
    //                 isOrderNo = true;
    //             } else {
    //                 isOrderNo = false;
    //             }
    //         } else {
    //             isOrderNo = true;
    //         }
    //         if (selectedOuId) {
    //             if (selectedOuId === o.ouId) {
    //                 isOutlet = true
    //             }
    //         } else {
    //             isOutlet = true
    //         }
    //         if (queryFilters.phoneNo && queryFilters.phoneNo.length > 0) {
    //             if (queryFilters.phoneNo.indexOf(o.shippingAddress.phoneNo) > -1) {
    //                 isPhoneNo = true;
    //             } else {
    //                 isPhoneNo = false;
    //             }
    //         } else {
    //             isPhoneNo = true;
    //         }
    //         if (isPhoneNo && isOrderNo && isOutlet && orderStatus) {
    //             filterOrderList.push(o);
    //         }
    //     }
    //     filterOrderList.sort(function (a, b) {
    //         let dateB: any = new Date(b.createdAt);
    //         let dateA: any = new Date(a.createdAt);
    //         return dateB - dateA;
    //     });
    //     setOrders(filterOrderList);
    // }

    // useEffect(() => {
    //     if (orderList && orderList.length > 0) {
    //         filterOrders();
    //     } else {
    //         setOrders([]);
    //     }
    //     //eslint-disable-next-line
    // }, [orderList]);

    const onChangeDate = (dayjsDate, dateString) => {
        if (dateString.length > 1) {
            // props.selectedOrderDates(dateString);
            // setSelectedDate([dayjs(dateString[0]), dayjs(dateString[1])])
            props.onOrderFilterOptions({ ...orderFilterOptions, date: dateString })
        }
    }

    const getAllOrders = async () => {
        // if (selectedOuId) {
        // await props.getOutletSlot(selectedOuId);
        // if (respDeliverySlots) {
        //     setDeliverySlots(respDeliverySlots);
        // }
        setIsLoading(true);
        const queryParams = generateQueryParams();
        const resp = await props.getAllOrders(queryParams);
        if (resp && resp.orders) {
            setOrderList(resp.orders)
        }
        setIsLoading(false);
        // }
    }

    const generateQueryParams = () => {
        let queryFilters: any = {};
        if (orderFilterOptions.query) {
            for (let q of orderFilterOptions.query) {
                if (!queryFilters[q.fId]) {
                    queryFilters[q.fId] = []
                }
                queryFilters[q.fId].push(q.fValue);
            }
        }
        let queryParams: any = {};
        if (orderFilterOptions.date && orderFilterOptions.date.length > 0) {
            queryParams['startDate'] = dayjs(orderFilterOptions.date[0]).startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss.SSS');
            queryParams['endDate'] = dayjs(orderFilterOptions.date[1]).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss.SSS');
        } else {
            queryParams['startDate'] = selectedDate[0].startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss.SSS');
            queryParams['endDate'] = selectedDate[1].endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss.SSS');
        }
        queryParams['dateFilterType'] = orderFilterOptions.dateFilterType || dateFilterType
        if (orderFilterOptions.ouId && orderFilterOptions.ouId !== 'all') {
            queryParams['ouId'] = orderFilterOptions.ouId;
        }
        if (queryFilters.orderNo && queryFilters.orderNo.length > 0) {
            queryParams['orderNo'] = queryFilters.orderNo[0];
        }
        if (queryFilters.any && queryFilters.any.length > 0) {
            queryParams['query'] = queryFilters.any[0];
        }
        if (orderFilterOptions.status && orderFilterOptions.status !== 'all') {
            if (orderFilterOptions.status === "exceptCancelled") {
                queryParams['notStatus'] = 'cancelled'
            } else {
                queryParams['status'] = orderFilterOptions.status;
            }
        }
        return queryParams;
    }

    // useEffect(() => {
    //     getAllOrders();
    //     //eslint-disable-next-line
    // }, [selectedDate, selectedOrderStatus, queries, selectedOuId]);

    const downloadInvoice = async (e, orderId) => {
        e.preventDefault();
        e.stopPropagation();
        setIsLoading(true);
        const blob = await props.downloadOrderInvoice(orderId);
        if (blob) {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${orderId}_invoice.pdf`;
            document.body.append(link);
            link.click();
            link.remove();
            setTimeout(() => URL.revokeObjectURL(link.href), 7000);
        }
        setIsLoading(false);
    }

    const downloadAllOrder = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setPopupConfirm({
            title: "Select report type",
            renderContent: () => {
                return <Select defaultValue={reportType.current} placeholder="Report type" onChange={(val: string) => { reportType.current = val }}  >
                    {ReportTypes.map((opt) => {
                        return <Option key={opt.key} value={opt.key}>{opt.value}</Option>
                    })}
                </Select>
            },
            yes: {
                label: "Download",
                callback: async () => {
                    const queryParams = generateQueryParams();
                    queryParams['excel'] = true;
                    queryParams['reportType'] = reportType.current;
                    const blob = await props.downloadAllOrder(queryParams);
                    if (blob) {
                        const link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = `all_${reportType.current}_${new Date().toLocaleDateString()}.xlsx`;
                        document.body.append(link);
                        link.click();
                        link.remove();
                        setTimeout(() => URL.revokeObjectURL(link.href), 7000);
                    }
                    setPopupConfirm(null);
                }
            },
            no: {
                label: "Cancel",
                callback: () => { setPopupConfirm(null); }
            }
        });

    }

    const getDownloadOrderInvoices = async (e) => {
        setIsLoading(true);
        let queryParams = generateQueryParams();
        e.preventDefault();
        e.stopPropagation();
        queryParams['downloadInvoice'] = true;
        const blob = await props.downloadAllOrderInvoices(queryParams);
        if (blob) {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${new Date().getTime()}_all_order_invoice.pdf`;
            document.body.append(link);
            link.click();
            link.remove();
            setTimeout(() => URL.revokeObjectURL(link.href), 7000);
        }
        setIsLoading(false);
    }

    return (
        <Row className="order">
            <Col className="list" span={24}>
                {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '0px 16px' }}>
                    <div className="delivery-slots">
                        <div onClick={() => setSelectedSlot('all')} className={selectedSlot === 'all' ? 'slot selected' : 'slot'}>{'All'}</div>
                        {deliverySlots && deliverySlots.map((slot) => {
                            return <div key={slot.id} onClick={() => setSelectedSlot(slot.id)} className={selectedSlot === slot.id ? 'slot selected' : 'slot'}>{slot.name}</div>
                        })}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', marginRight: 16 }}><img src={ExportSvg} style={{ marginRight: 4 }} /><div style={{ marginTop: 2 }}>Procurement</div></div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', marginRight: 10 }}><img src={ExportSvg} style={{ marginRight: 4 }} /> <div style={{ marginTop: 2 }}>Export</div></div>
                    </div>
                </div> */}
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: '#f5f5f5', padding: '0px 16px' }}>
                    <div className="action-bar">
                        {/* <div className="title">
                        You've {orders && orders.length} orders listed
                    </div> */}
                        <QueryFilter onSelectedFilters={(val) => props.onOrderFilterOptions({ ...orderFilterOptions, query: val })} defaultValue={queries} properties={conditions} queries={queries}></QueryFilter>
                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: 4 }}>
                            <Select
                                className="select"
                                size="middle"
                                value={dateFilterType}
                                defaultValue={dateFilterType}
                                onChange={(value) => { props.onOrderFilterOptions({ ...orderFilterOptions, dateFilterType: value }) }}
                            >
                                {DateFilterTypes?.map((item: any) => {
                                    return <Option key={item.key} style={{ paddingLeft: '16px' }} value={item.key}>
                                        <label htmlFor={item.key}>{item.value}</label>
                                    </Option>
                                })}
                            </Select>
                        </div>
                        <div className="date-range" style={{ marginLeft: 0 }} >
                            <RangePicker presets={RangePresets} defaultValue={selectedDate} value={selectedDate} onChange={(dates, dateStrings) => onChangeDate(dates, dateStrings)} />
                        </div>
                    </div>
                    <div style={{ width: 300, display: 'flex', alignItems: 'center', }}>
                        <Select
                            className="select"
                            size="middle"
                            value={selectedOuId}
                            defaultValue={selectedOuId}
                            onChange={(value) => { props.onOrderFilterOptions({ ...orderFilterOptions, ouId: value }) }}
                        >
                            <Option key="all_outlets" style={{ paddingLeft: '16px' }} value="all">
                                <label htmlFor={"all_outlets"} style={{ paddingLeft: "16px" }}>{"All"}</label>
                            </Option>
                            {outlets && outlets.map((item) => {
                                return <Option key={item.id} style={{ paddingLeft: '16px' }} value={item.id}>
                                    <label htmlFor={item.id} style={{ paddingLeft: "16px" }}>{item.name}</label>
                                </Option>
                            })}
                        </Select>
                    </div>
                    <div style={{ width: 200, display: 'flex', alignItems: 'center', marginLeft: 4 }}>
                        <Select
                            className={`select status`}
                            size="middle"
                            value={selectedOrderStatus}
                            defaultValue={selectedOrderStatus}
                            onChange={(value) => { props.onOrderFilterOptions({ ...orderFilterOptions, status: value }) }}
                        >
                            {/* <Option key={"all"} style={{ paddingLeft: '16px' }} value={"all"}>
                                <label className={`${selectedOrderStatus}`} htmlFor={"all"} style={{ paddingLeft: "16px" }}>All</label>
                            </Option> */}
                            {OrderStatusCode && OrderStatusCode.map((item: any) => {
                                return <Option key={item.key} style={{ paddingLeft: '16px' }} value={item.key}>
                                    <label className={`${selectedOrderStatus}`} htmlFor={item.key} style={{ paddingLeft: "16px" }}>{item.value}</label>
                                </Option>
                            })}
                        </Select>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: 4 }}>
                        <div style={{ cursor: 'pointer', margin: "0px 10px" }}><img alt='Export' src={ExportSvg} onClick={downloadAllOrder} style={{ marginRight: 4 }} title={"Export"} /> </div>
                        <div style={{ cursor: 'pointer' }}><img alt='All Invoice' src={DownloadAllInvoiceSvg} onClick={getDownloadOrderInvoices} style={{ marginRight: 4, width: 20 }} title={"All Invoice"} /> </div>
                    </div>
                </div>
            </Col>

            <Col span={24}>
                <div className='orders'>
                    <Row className='header'>
                        <Col span={3}>
                            Order ID
                        </Col>
                        <Col span={3}>
                            Customer
                        </Col>
                        {/* <Col span={2}>
                            No of order items
                        </Col> */}
                        <Col span={3}>
                            Delivery Area
                        </Col>
                        <Col span={3}>
                            Total Amount
                        </Col>
                        <Col span={4}>
                            Delivery Slot
                        </Col>
                        <Col span={4}>
                            Order Date
                        </Col>
                        <Col span={3}>
                            Order Status
                        </Col>
                        <Col span={1} style={{ textAlign: 'center' }}>
                            Action
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div style={{ height: 1, background: '#D8DEE6', margin: "0px 15px" }}></div>
                        </Col>
                    </Row>
                    <div className='details'>
                        {/* <Col span={24}> */}
                        {(!orderList || orderList.length === 0) && <div className='no-data-found'>No orders found</div>}
                        {orderList.map((order) => {
                            return <Row onClick={() => navigateTo(`details/${order.id}`)} key={order.id} style={{
                                background: selectedOrder && selectedOrder.id === order.id ? '#CBCBCB33' : '#FFF', borderRadius: 4
                            }}>
                                <Col span={24}>
                                    <Row style={{ cursor: 'pointer', height: 50, display: 'flex', alignItems: 'center' }} onClick={() => { !selectedOrder || selectedOrder.id !== order.id ? setSelectedOrder(order) : setSelectedOrder(null) }} >
                                        <Col span={3}>
                                            <div style={{ display: 'flex' }}>
                                                {/* <img
                                                    alt='Order'
                                                    style={{ marginTop: -4, marginLeft: -24, cursor: 'pointer', transition: 'transform 1s', transform: `rotate(${selectedOrder && selectedOrder.id === order.id ? '0' : '-90'}deg)` }}
                                                    src={ArrowSvg} /> */}
                                                <div>#{order.orderNo}</div>
                                            </div>
                                        </Col>
                                        {/* style={{ paddingLeft: 14 }} */}
                                        <Col span={3}>
                                            {order.customer.fullName}
                                        </Col>
                                        {/* style={{ paddingLeft: 6 }} */}
                                        {/* <Col span={2}>
                                            {order.items.length}
                                        </Col> */}
                                        <Col span={3}>
                                            {order.pickup ? <div style={{ color: "#ff4800" }}>Outlet Pickup</div> : <div>
                                                {order.shippingAddress?.area}
                                            </div>}
                                        </Col>
                                        <Col span={3}>
                                            <div style={{ display: 'flex' }}>
                                                <div>₹{order?.billing?.toPay?.toLocaleString('en-IN')} </div> <div style={{ fontWeight: 500, marginLeft: 4 }}>[{order.payment && order.payment.mode.toUpperCase()}]</div>
                                            </div>
                                        </Col>
                                        <Col span={4}>
                                            {order.deliverySlot?.date && <div>
                                                {dayjs(order.deliverySlot?.date).format('MMM')} {dayjs(order.deliverySlot?.date).format('DD')},{dayjs(order.deliverySlot?.date).format('YYYY')}
                                            </div>}
                                            <div title={order.deliverySlot?.name} style={{ color: "#404040", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", paddingRight: 10 }}>
                                                {order.deliverySlot?.name}
                                            </div>
                                        </Col>
                                        <Col span={4}>
                                            {dayjs(order.createdAt).format('ddd')}, {dayjs(order.createdAt).format('MMM')} {dayjs(order.createdAt).format('DD')},{dayjs(order.createdAt).format('YYYY')}, {dayjs(order.createdAt).format('hh:mm A')}
                                        </Col>
                                        <Col span={3}>
                                            <div style={{ color: CONSTANTS.OrderStatus.colors[order.status] }}>
                                                {CONSTANTS.OrderStatus.labels[order.status] || order.status}
                                            </div>
                                        </Col>
                                        <Col span={1} style={{ textAlign: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                            <img src={DownloadInvoiceSvg} title={'Download Invoice'} alt={'Download Invoice'} style={{ cursor: 'pointer', width: 16 }} onClick={(e) => downloadInvoice(e, order.id)} />
                                        </Col>
                                    </Row>
                                    {/* {selectedOrder && selectedOrder.id === order.id && <Row style={{
                                        background: '#FFFFFF',
                                        borderRadius: 4,
                                        margin: '0px 13px 13px 13px',
                                        padding: 14
                                    }}>
                                        <Col span={6} style={{ padding: '0px 0px 0px 20px' }}>
                                            <div style={{ color: '#1C3A66', fontWeight: 'bold', marginBottom: 10 }}>Customer Details</div>
                                            <Row style={{ marginBottom: 10 }}>
                                                <Col span={7} style={{ color: '#5E6774', fontSize: 14 }}>
                                                    Name:
                                                </Col>
                                                <Col span={17}>
                                                    {order.customer.fullName}
                                                </Col>
                                            </Row>
                                            <Row style={{ marginBottom: 10 }}>
                                                <Col span={7} style={{ color: '#5E6774', fontSize: 14 }}>
                                                    Email ID:
                                                </Col>
                                                <Col span={17}>
                                                </Col>
                                            </Row>
                                            <Row style={{ marginBottom: 10 }}>
                                                <Col span={7} style={{ color: '#5E6774', fontSize: 14 }}>
                                                    Phone Number:
                                                </Col>
                                                <Col span={17}>
                                                    {order.shippingAddress.phoneNo}
                                                </Col>
                                            </Row>
                                            <Row style={{ marginBottom: 10 }}>
                                                <Col span={7} style={{ color: '#5E6774', fontSize: 14 }}>
                                                    Pincode:
                                                </Col>
                                                <Col span={17}>
                                                    {order.shippingAddress.zipcode}
                                                </Col>
                                            </Row>
                                            <Row style={{ marginBottom: 10 }}>
                                                <Col span={7} style={{ color: '#5E6774', fontSize: 14 }}>
                                                    City:
                                                </Col>
                                                <Col span={17}>
                                                    {order.shippingAddress.city}
                                                </Col>
                                            </Row>
                                            <Row style={{ marginBottom: 10 }}>
                                                <Col span={7} style={{ color: '#5E6774', fontSize: 14 }}>
                                                    State:
                                                </Col>
                                                <Col span={17}>
                                                    {order.shippingAddress.state}
                                                </Col>
                                            </Row>
                                            <Row style={{ marginBottom: 10 }}>
                                                <Col span={7} style={{ color: '#5E6774', fontSize: 14 }}>
                                                    Address:
                                                </Col>
                                                <Col span={17}>
                                                    <div>{order.shippingAddress.houseName}, {order.shippingAddress.addressLine}, {order.shippingAddress.area},</div>
                                                    <div>{order.shippingAddress.city} - {order.shippingAddress.zipcode}</div>
                                                    <div>{order.shippingAddress.state}</div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={18} style={{ padding: '0px 0px 0px 20px', borderLeft: '1px solid #B5BFCC' }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 14 }}>
                                                <div style={{ color: '#1C3A66', fontWeight: 'bold' }}>Order Items</div>
                                            </div>
                                            <Row style={{ marginBottom: 10, borderBottom: '1px solid #E7EEF7', height: 40, alignItems: 'center', display: 'flex' }}>
                                                <Col span={16} style={{ color: '#5E6774', fontSize: 14 }}>
                                                    Products
                                                </Col>
                                                <Col span={4}>
                                                    Quantity
                                                </Col>
                                                <Col span={4}>
                                                    Price
                                                </Col>
                                            </Row>
                                            <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <Col span={24} style={{ borderBottom: '1px solid #E7EEF7' }}>
                                                    {order?.items?.map((i) => {
                                                        return <Row style={{ marginBottom: 12 }} key={i.id}>
                                                            <Col span={16}>{i.title}</Col>
                                                            <Col span={4}><div>{i.quantity} X {i.price.toLocaleString('en-IN')}</div></Col>
                                                            <Col span={4}><div>₹{(i.quantity * i.price).toLocaleString('en-IN')}</div></Col>
                                                        </Row>
                                                    })}
                                                </Col>
                                                <Col span={24} >
                                                    <Row style={{ marginBottom: 12, fontSize: 15, color: '#188FFC' }}>
                                                        <Col span={16}></Col>
                                                        <Col span={4}>Grand Total</Col>
                                                        <Col span={4}>₹{order.billing.toPay.toLocaleString('en-IN')}</Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>} */}
                                </Col>
                            </Row>
                        })}
                        {/* </Col> */}
                    </div>
                </div>
                {orderList.length > 0 && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 800, fontSize: 16, marginTop: 8 }}>Total Orders {orderList.length}</div>}
            </Col>
            {popupConfirm && <PopupConfirm config={popupConfirm}></PopupConfirm>}
            {isLoading && <Loading background="#00000070"></Loading>}
        </Row >
    )
}



function mapStateToProps(state: any) {
    // console.log("Categories mapStateToProps: ", state);
    return {
        order: state.order,
        ui: state.ui
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        // selectedOrderDates,
        getAllOrders,
        getOutletSlot,
        downloadAllOrderInvoices,
        downloadAllOrder,
        selectedOutlets,
        onOrderFilterOptions,
        downloadOrderInvoice
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AllOrders);