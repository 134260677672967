import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
// import { EllipsisOutlined } from "@ant-design/icons";
import { getProducts, removeProductFromOutlet } from '../../../actions/productActions';
import { selectedMainMenu } from '../../../actions/uiAction';
import { getL10N, loadOutletBasedProduct } from "../../../helpers/middleware";
import { Row, Col, Select, Input, Badge } from 'antd';
import config from '../../../apiConfig';
// import View from './View';
// import Save from './Save';
// import Delete from './Delete';
import '../../../assets/sass/dsk/product.sass';
import SearchIcn from "../../../assets/images/search.svg";
import deleteIcn from '../../../assets/images/delete.png';
import NoImageAvailableIcn from "../../../assets/images/No-image-available.png";
// import editIcn from '../../../assets/images/edit.png';
import ProductToOutlet from './ProductToOutlet';
import PopupConfirm from '../common/PopupConfirm';
import { useNav } from '../../../helpers/customHook';
import Loading from '../common/Loading';
import VideoSvg from "../../../assets/images/media-icons/video.svg";
import AudioSvg from "../../../assets/images/media-icons/audio.svg";
import Unknown from "../../../assets/images/media-icons/unknown.svg";
import { getOutlets } from '../../../actions/outletAction';

const { Option } = Select;
// const menus = [{
//     icon: editIcn,
//     key: "edit",
//     value: "Edit"
// }, {
//     icon: deleteIcn,
//     key: "remove",
//     value: "Remove"
// }];

const ListedProducts = (props: any) => {
    const navigateTo = useNav();
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const outlets = useSelector((state: any) => state.outlet.outlets);
    const { selectedStore } = useSelector((state: any) => state.ui);
    // let selectedLang = getLocale();
    let windowHeight = window.innerHeight;
    const [productList, setProductList] = useState(null);
    const [products, setProducts] = useState([]);
    // const [selectMenu, setSelectMenu] = useState("");
    const [selectedOutletId, setSelectedOutletId] = useState(null);
    const [productSearch, setProductSearch] = useState("");
    const [categorySearch, setCategorySearch] = useState("");
    const [priceSearch, setPriceSearch] = useState("");
    const [salePriceSearch, setSalePriceSearch] = useState("");
    const [quantitySearch, setQuantitySearch] = useState("");
    const [stockSearch, setStockSearch] = useState("all");
    const [isVisible, setIsVisible] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [popupConfirm, setPopupConfirm] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            if (selectedStore) {
                await getOutlets()(dispatch);
                await loadProducts();
            }
        }
        fetchData();
        //eslint-disable-next-line
    }, [selectedStore]);

    useEffect(() => {
        if (productList == null) {
            onFilterProducts();
        }
        //eslint-disable-next-line
    }, [productList]);

    useEffect(() => {
        onFilterProducts();
        //eslint-disable-next-line
    }, [selectedOutletId]);

    useEffect(() => {
        if (props.selectedOutlet !== selectedOutletId) {
            setSelectedOutletId(props.selectedOutlet);
        }
        //eslint-disable-next-line
    }, [props.selectedOutlet]);

    useEffect(() => {
        if (outlets && outlets.length > 0) {
            setSelectedOutletId(outlets[0].id);
            onFilterProducts();
        }
        //eslint-disable-next-line
    }, [outlets]);

    useEffect(() => {
        onFilterProducts();
        //eslint-disable-next-line
    }, [productSearch, priceSearch, quantitySearch, categorySearch, stockSearch, productList, salePriceSearch])

    const loadProducts = async () => {
        setLoading(true);
        let resp = await props.getProducts();
        setLoading(false);
        setProductList(resp);
    }

    const onAction = (product, action) => {
        setSelectedProduct(product);
        if (action === "view" || action === "new") {
            setIsVisible(true);
        }
    }

    const loadCategories = (record) => {
        let categories = [];
        if (record.categories && record.categories.length > 0) {
            for (let i in record.categories) {
                let searchRegExp = /\//g;
                let replaceWith = ' / ';
                categories.push(<Badge key={record.categories[i]} count={record.categories[i].replace("/", "").replace(searchRegExp, replaceWith).replace(/\b\w/g, l => l.toUpperCase())} />);
            }
        }
        return categories;
    }

    const loadStockDetails = (record) => {
        let details = {
            key: null,
            val: null
        }
        if (loadOutletBasedProduct(record, "isAvailable", [selectedOutletId])) {
            details["key"] = "instock";
            details["val"] = "In Stock";
        }
        if (loadOutletBasedProduct(record, "outOfStock", [selectedOutletId])) {
            details["key"] = "outOfStock";
            details["val"] = "Out of Stock";
        }
        return details && details.key && <div className={details.key}>{details.val}</div>;
    }

    const isStockStatus = (record) => {
        let status = true;
        if (!loadOutletBasedProduct(record, "isAvailable", [selectedOutletId])) {
            status = false;
        }
        if (loadOutletBasedProduct(record, "outOfStock", [selectedOutletId])) {
            status = false;
        }
        return status;
    }

    const onFilterProducts = () => {
        if (productList) {
            let filterProducts = [];
            let isCategory = false;
            let isProduct = false;
            let isPrice = false;
            let isSalePrice = false;
            let isQuantity = false;
            let isOutlet = false;
            let isStock = false;
            for (let p of productList) {
                isCategory = false;
                isProduct = false;
                isOutlet = false;
                isPrice = false;
                isSalePrice = false;
                isQuantity = false;
                isStock = false;
                if (productSearch) {
                    let title = getL10N(p, "title", null).i10n;
                    if ((title && title.toLowerCase().indexOf(productSearch.toLowerCase()) > -1) || p.sku?.includes(productSearch)) {
                        isProduct = true
                    }
                } else {
                    isProduct = true;
                }
                if (categorySearch) {
                    let categoryNames = p.categories.join('|').replace("/", "");
                    if (categoryNames.toLowerCase().indexOf(categorySearch.toLowerCase()) > -1) {
                        isCategory = true
                    }
                } else {
                    isCategory = true;
                }

                if (p.outlets && p.outlets.length > 0 && selectedOutletId) {
                    for (let i in p.outlets) {
                        if (selectedOutletId === p.outlets[i].ouId) {
                            isOutlet = true;
                            if (priceSearch && priceSearch.toString().length > 0 && p.outlets[i].price.toString().match(priceSearch.toString())) {
                                isPrice = true;
                            } else if (!priceSearch || priceSearch.toString().length === 0) {
                                isPrice = true;
                            }
                            if (salePriceSearch && salePriceSearch.toString().length > 0 && p.outlets[i].salePrice && p.outlets[i].salePrice.toString().match(salePriceSearch.toString())) {
                                isSalePrice = true;
                            } else if (!salePriceSearch || salePriceSearch.toString().length === 0) {
                                isSalePrice = true;
                            }
                            if (quantitySearch && quantitySearch.toString().length > 0 && p.outlets[i].stock.toString().match(quantitySearch.toString())) {
                                isQuantity = true;
                            } else if (!quantitySearch || quantitySearch.toString().length === 0) {
                                isQuantity = true;
                            }
                            if ((stockSearch && stockSearch.toString().length > 0 && stockSearch !== "all" && p.outlets[i].outOfStock === false && stockSearch === "instock") || (p.outlets[i].outOfStock === true && stockSearch === "outofstock")) {
                                isStock = true;
                            } else if (!stockSearch || stockSearch.toString().length === 0 || stockSearch === "all") {
                                isStock = true;
                            }
                        }
                    }
                }
                if (isOutlet && isProduct && isPrice && isQuantity && isCategory && isStock && isSalePrice) {
                    filterProducts.push(p);
                }
            }
            setProducts(filterProducts);
        }
    }
    // const renderMenu = (data) => {
    //     const content = [];
    //     for (let i in menus) {
    //         content.push(
    //             <div key={menus[i].key}>
    //                 <div style={{ display: "flex", color: selectMenu == menus[i].key ? "#37a2fd" : "#000000", fontWeight: selectMenu == menus[i].key ? 800 : 100 }}>
    //                     <div>
    //                         <img alt='Menu' src={menus[i].icon} style={{
    //                             padding: "7px",
    //                             width: "22px"
    //                         }} />
    //                     </div>
    //                     <div>
    //                         <p onClick={() => onMenuChange(menus[i], data)}>{menus[i].value}</p>
    //                     </div>
    //                 </div>
    //                 <div style={{ textAlign: "center", padding: "0px 8px" }}>
    //                     <div style={{ height: "1px", background: "#CAD1DB", width: "100%" }}></div>
    //                 </div>
    //             </div>
    //         );
    //     }
    //     return content;
    // }
    // const onMenuChange = (menu, data) => {
    //     setSelectMenu(menu.key);
    //     onAction(data, menu.key)
    // }
    const removeProduct = (p) => {
        const selectedOutletDetail = outlets.filter((o) => o.id === selectedOutletId);
        const title = `Product remove from outlet`;
        setPopupConfirm({
            title,
            renderContent: () => { return <div><span style={{ color: "#000" }}>Do you like to remove <span style={{ fontWeight: "bold" }}>{p.title}</span> from {selectedOutletDetail[0].name} outlet.</span></div > },
            yes: {
                label: "Remove Product",
                callback: async () => {
                    const resp = await props.removeProductFromOutlet(p.id, selectedOutletId);
                    if (resp && resp.isUpdated) {
                        loadProducts();
                    }
                    setPopupConfirm(null);
                }
            },
            no: {
                label: "May be next time",
                callback: () => { setPopupConfirm(null); }
            }
        });
    }

    function getMediaType(input) {
        if (typeof input === 'string') {
            if (input.startsWith('data:')) {
                const mime = input.split(';')[0].split(':')[1];
                switch (mime) {
                    case 'image/jpg':
                    case 'image/jpeg':
                    case 'image/png':
                    case 'image/webp':
                    case 'image/svg':
                    case 'image/ico':
                    case 'image/gif':
                        return 'image';
                    case 'audio/mpeg':
                    case 'audio/mp3':
                    case 'audio/wav':
                    case 'audio/ogg':
                    case 'audio/midi':
                        return 'audio';
                    case 'video/mpeg':
                    case 'video/mp4':
                    case 'video/mov':
                    case 'video/webm':
                        return 'video';
                    default:
                        return 'unknown';
                }
            } else {
                const extension = input.split('.').pop().toLowerCase();
                switch (extension) {
                    case 'jpg':
                    case 'jpeg':
                    case 'png':
                    case 'webp':
                    case 'svg':
                    case 'ico':
                    case 'gif':
                        return 'image';
                    case 'mp3':
                    case 'wav':
                    case 'ogg':
                    case 'midi':
                        return 'audio';
                    case 'mp4':
                    case 'mov':
                    case 'mpeg':
                    case 'webm':
                        return 'video';
                    default:
                        return 'unknown';
                }
            }
        } else {
            return 'unknown';
        }
    }

    return (
        <Row className="product">
            {/* <Col span={24}>
                    <div className="head">Products</div>
                </Col> */}
            {isLoading && <Loading background="#00000070"></Loading>}
            {(outlets && outlets.length > 0) && <Col className="list" style={{ height: windowHeight - 76 }}>
                <div className="action-bar">
                    <div className="title">
                        You've got {products && products.length} products listed
                    </div>
                    <div className="actions">
                        <Select
                            className="select"
                            // maxTagCount={2}
                            // mode="tags"
                            size="middle"
                            value={selectedOutletId}
                            defaultValue={selectedOutletId}
                            onChange={(value) => { setSelectedOutletId(value) }}
                        // style={{ maxHeight: "60px", overflowY: "auto", borderColor: 'rgb(199 200 214)', borderRadius: "1px", width: '100%' }}
                        >
                            {outlets && outlets.map((item) => {
                                return <Option key={item.id} style={{ paddingLeft: '16px' }} value={item.id}>
                                    <label htmlFor={item.id} style={{ paddingLeft: "16px" }}>{item.name}</label>
                                </Option>
                            })}
                        </Select>
                        <button style={{ width: 300 }} className="sp_btn btn" onClick={() => onAction(null, "new")} >+ Add Product To Outlet</button>
                    </div>
                </div>
                <div className="content">
                    <Row className="header">
                        <Col span={8}>
                            Product Name
                        </Col>
                        <Col span={5}>
                            Category
                        </Col>
                        <Col span={2}>
                            Price
                        </Col>
                        <Col span={2}>
                            Sale Price
                        </Col>
                        <Col span={3}>
                            Quantity
                        </Col>
                        <Col span={2}>
                            Inventory
                        </Col>
                        <Col style={{ textAlign: "center" }} span={2}>
                            Actions
                        </Col>
                    </Row>
                    <Row className="filters">
                        <Col span={8}>
                            <Input className="filter" name="productSearch" onChange={(event) => { setProductSearch(event.target.value) }} placeholder="Product Name" suffix={<img alt='Search Icon' src={SearchIcn} />} />
                        </Col>
                        <Col span={5}>
                            <Input className="filter" placeholder="Category" name="categorySearch" onChange={(event) => setCategorySearch(event.target.value)} suffix={<img alt='Search Icon' src={SearchIcn} />} />
                        </Col>
                        <Col span={2}>
                            <Input className="filter" placeholder="Price" value={priceSearch} name="priceSearch" onChange={(e) => setPriceSearch(e.target.value)} suffix={<img alt='Search Icon' src={SearchIcn} />} />
                        </Col>
                        <Col span={2}>
                            <Input className="filter" placeholder="Sale Price" value={salePriceSearch} name="salePriceSearch" onChange={(e) => setSalePriceSearch(e.target.value)} suffix={<img alt='Search Icon' src={SearchIcn} />} />
                        </Col>
                        <Col span={3}>
                            {/* value={this.state && stockSearch} */}
                            <Input className="filter" placeholder="Quantity" name="quantitySearch" onChange={(event) => setQuantitySearch(event.target.value)} suffix={<img alt='Search Icon' src={SearchIcn} />} />
                        </Col>
                        <Col span={2}>
                            <Select defaultValue={stockSearch} onChange={(val) => setStockSearch(val)}><Option value="all">All</Option><Option value="instock">In Stock</Option><Option value="outofstock">Out Of Stock</Option></Select>
                        </Col>
                        <Col span={2}>
                        </Col>
                    </Row>
                    <Row className="contains" style={{ height: windowHeight - 234 }}>
                        <Col span={24}>
                            {products && products.map((record, index) =>
                                <Row className="item" onClick={() => onAction(record, "view")} key={record.id}
                                // style={{ border: this.state.products.length - 1 <= index ? "border: 1px solid #E7EEF7" : "1px solid #E7EEF7" }}
                                >
                                    <Col span={8}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{ float: "left" }}>
                                                {/* <span className={loadOutletBasedProduct(record, "isAvailable") ? "active" : "inactive"}></span> */}
                                                {(!record.images || record.images.length === 0) && <img alt='Not Available' src={NoImageAvailableIcn} style={{
                                                    marginRight: "10px",
                                                    width: "50px",
                                                    borderRadius: "3px"
                                                }} />}

                                                {record.images && record.images.length > 0 && (
                                                    <div style={{ display: 'flex' }}>
                                                        {record.images && record.images.length > 0 && (
                                                            <div style={{ display: 'flex' }}>
                                                                {/* Render placeholder images based on media type of the first image */}
                                                                {getMediaType(record.images && record.images[0]) === 'video' && (
                                                                    <img src={VideoSvg} alt="Video_Placeholder" className={isStockStatus(record) ? "" : "grayscale"} style={{
                                                                        width: "50px",
                                                                        height: "50px",
                                                                        borderRadius: "4px",
                                                                        objectFit: "fill",
                                                                        overflow: "hidden",
                                                                        marginRight: "10px"
                                                                    }} />
                                                                )}
                                                                {getMediaType(record.images && record.images[0]) === 'audio' && (
                                                                    <img src={AudioSvg} alt="Audio_Placeholder" className={isStockStatus(record) ? "" : "grayscale"} style={{
                                                                        width: "50px",
                                                                        height: "50px",
                                                                        borderRadius: "4px",
                                                                        objectFit: "fill",
                                                                        overflow: "hidden",
                                                                        marginRight: "10px"
                                                                    }} />
                                                                )}
                                                                {getMediaType(record.images && record.images[0]) === 'unknown' && (
                                                                    <img src={Unknown} alt="Unknown_Placeholder" className={isStockStatus(record) ? "" : "grayscale"} style={{
                                                                        width: "50px",
                                                                        height: "50px",
                                                                        borderRadius: "4px",
                                                                        objectFit: "fill",
                                                                        overflow: "hidden",
                                                                        marginRight: "10px"
                                                                    }} />
                                                                )}
                                                                {/* Render actual first image */}
                                                                {getMediaType(record.images && record.images[0]) === 'image' && (<img
                                                                    alt='Product Icon'
                                                                    style={{
                                                                        width: "50px",
                                                                        height: "50px",
                                                                        borderRadius: "4px",
                                                                        objectFit: "fill",
                                                                        overflow: "hidden",
                                                                        marginRight: "10px"
                                                                    }}
                                                                    className={isStockStatus(record) ? "" : "grayscale"}
                                                                    src={`${config.baseImageUrl}${record.images[0]}`}
                                                                />)}
                                                            </div>
                                                        )}

                                                    </div>
                                                )}
                                            </div>
                                            <div className='row-ellipsis'>
                                                <div
                                                    className='col-ellipsis'
                                                    style={{
                                                        fontSize: 14,
                                                        marginBottom: 4
                                                    }}>{getL10N(record, "title", null).i10n}</div>
                                                <div style={{
                                                    color: "#606060",
                                                    fontSize: 12
                                                }}>{getL10N(record, "subtitle", null).i10n}</div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={5}>
                                        <div className="category">{loadCategories(record)}</div>
                                    </Col>
                                    <Col span={2} style={{ textAlign: "center" }}>
                                        <div style={{ textAlign: 'right', paddingRight: 2 }}>₹{loadOutletBasedProduct(record, "price", [selectedOutletId])}</div>
                                    </Col>
                                    <Col span={2} style={{ textAlign: "center" }}>
                                        <div style={{ textAlign: 'right', paddingRight: 2 }}>{loadOutletBasedProduct(record, "salePrice", [selectedOutletId]) ? `₹${loadOutletBasedProduct(record, "salePrice", [selectedOutletId])}` : ""}</div>
                                    </Col>
                                    <Col span={3} style={{ textAlign: "center" }}>
                                        <div style={{ textAlign: 'right', paddingRight: 2 }}>{loadOutletBasedProduct(record, "stock", [selectedOutletId])}</div>
                                    </Col>
                                    <Col span={2} >
                                        <div>{loadStockDetails(record)}</div>
                                    </Col>
                                    <Col span={2} >
                                        <div style={{ textAlign: 'center' }}><img alt='Delete Icon' onClick={(e) => { e.stopPropagation(); removeProduct(record) }} style={{ cursor: 'pointer' }} src={deleteIcn} /></div>
                                    </Col>
                                    {/* <Col span={2}>
                                        <div style={{ textAlign: "center", fontSize: "14px" }}> */}
                                    {/* <span style={{ margin: "0px 10px", cursor: "pointer" }} onClick={() => this.onAction(record, "edit")}>
                    <img src={editIcn} />
                </span> */}
                                    {/* <span style={{ margin: "0px 10px", cursor: "pointer" }} onClick={() => this.onAction(record, "view")}>
                                                    <img src={viewIcn} />
                                                </span> */}
                                    {/* <span style={{ margin: "0px 10px", cursor: "pointer" }} >
                                                <Popover placement="bottomRight" content={renderMenu(record)} trigger="click">
                                                    <EllipsisOutlined />
                                                </Popover>
                                            </span> */}
                                    {/* <span style={{ margin: "0px 10px", cursor: "pointer" }} onClick={() => this.onAction(record, "delete")}>
                    <img src={deleteIcn} />
                </span> */}
                                    {/* </div>
                                    </Col> */}
                                </Row>
                            )}
                        </Col>
                    </Row>
                </div>
            </Col>}
            {(!outlets || outlets.length === 0) && <Col span={24}> <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", height: windowHeight - 200, flexDirection: 'column' }}>
                <div style={{ fontWeight: 900, fontSize: 18, color: '#000000', marginBottom: 20 }}>Welcome to Shopletzy Add Products to Outlet</div>
                {/* <div style={{ fontSize: 16, color: '#C1C1C1', marginBottom: 47 }}>This wizard will walk you through the steps to create your first FAQ.</div> */}
                <div style={{ fontWeight: 900, fontSize: 16, color: '#000000', marginBottom: 33 }}>You have no Outlet created yet...</div>
                <div style={{ display: 'flex' }}>
                    <button onClick={() => { props.selectedMainMenu('outlets'); navigateTo(`/${selectedStore.name}/settings/store/outlet`); }} className="sp_btn" style={{ width: 140 }} >+ Create Outlet</button>
                </div>
            </div></Col>}
            {popupConfirm && <PopupConfirm config={popupConfirm}></PopupConfirm>}
            <ProductToOutlet onClose={() => { setIsVisible(false); loadProducts(); }} selectedOutlet={selectedOutletId} defaultValue={selectedProduct} isVisible={isVisible} />
        </Row >
    )
    // }
    // }

}

// const mapStateToProps = (state: any) => {
//     // console.log("Product mapStateToProps: ", state);
//     return {
//         outlet: state.outlet,
//         product: state.product,
//         auth: state.auth,
//         ui: state.ui
//     }
// }

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getProducts,
        removeProductFromOutlet,
        selectedMainMenu
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(ListedProducts)
